import axios from "axios";
import { BACKEND_BASEURL } from "../../../db/baseUrl";
export const API_BASES = `${BACKEND_BASEURL}api/bases/`;

const createBase = async (baseData) => {
  console.log(baseData);
  const response = await axios.post(API_BASES + "createBase", baseData);
  return response.data;
};

const getBases = async () => {
  const response = await axios.get(API_BASES + "getBases");
  return response.data;
};

const updateBase = async (baseData) => {
  const response = await axios.patch(API_BASES + "updateBase", baseData);
  return response.data;
};
const deleteBase = async (id) => {
  const response = await axios.delete(API_BASES + id);
  return response.data.message;
};
const BaseServices = {
  createBase,
  getBases,
  updateBase,
  deleteBase,
};
export default BaseServices;
