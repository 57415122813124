import axios from "axios";
import { BACKEND_BASEURL } from "../../../db/baseUrl";
export const API_ORDERS = `${BACKEND_BASEURL}api/orders/`;

const createOrder = async (orderData) => {
  console.log(orderData);
  const response = await axios.post(API_ORDERS + "createOrder", orderData);
  return response.data;
};

const getOrders = async () => {
  const response = await axios.get(API_ORDERS + "getOrders");
  return response.data;
};

const getPendingOrders = async () => {
  const response = await axios.get(API_ORDERS + "getPendingOrders");
  return response.data;
};
const updateOrderStatus = async ({ status, id }) => {
  const response = await axios.put(`${API_ORDERS}/updateOrderStatus/${id}`, {
    status,
  });
  return response.message;
};

const deleteOrder = async (id) => {
  const response = await axios.delete(`${API_ORDERS}/deleteOrder/${id}`);
  return response.message;
};

const getOrdersByDateTimeRange = async (orderData) => {
  const response = await axios.post(
    API_ORDERS + "getOrdersByDateRange",
    orderData
  );
  return response.data;
};

const OrderServices = {
  createOrder,
  getOrders,
  getOrdersByDateTimeRange,
  getPendingOrders,
  updateOrderStatus,
  deleteOrder,
};
export default OrderServices;
