import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Sidebar = ({ sidebarSections }) => {
  const location = useLocation();

  const isActive = (path) => location.pathname === path;
  return (
    <div>
      {sidebarSections.map((section, i) => (
        <NavLink to={section.path} key={i}>
          <div
            key={section}
            className={`p-4 cursor-pointer text-[1rem] font-inter-600 flex items-center hover:bg-[#E6E6E6]   ${
              isActive(section.path)
                ? "bg-[#E6E6E6] text-[#0D868C] "
                : "bg-white text-[#A9A9A9] "
            }`}
          >
            {section.icon && <span className="mr-2">{section.icon}</span>}
            {isActive(section.path)
              ? section.image2 && (
                  <img src={section.image2} className="w-4 mr-2" alt="" />
                )
              : section.image1 && (
                  <img src={section.image1} className="w-4 mr-2" alt="" />
                )}
            <span>{section.name}</span>
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default Sidebar;
