import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import CategoryServices from "./CategoryServies";
import { toast } from "react-toastify";

const initialState = {
  category: null,
  categories: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// createCategory thunkApi
export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (categoryData, thunkAPI) => {
    try {
      return await CategoryServices.createCategory(categoryData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCategories = createAsyncThunk(
  "category/getCategories",
  async (_, thunkAPI) => {
    try {
      return await CategoryServices.getCategories();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async (categoryData, thunkAPI) => {
    try {
      return await CategoryServices.updateCategory(categoryData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create category
      .addCase(createCategory.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload;
        console.log(action.payload);
        toast.success("Create new Category successfully");
      })

      .addCase(createCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.category = null;
        toast.error(action.payload);
      })

      .addCase(getCategories.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCategories.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categories = action.payload.data;
        // console.log(action.payload);
        // toast.success("Create new Category successfully");
      })

      .addCase(getCategories.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.categories = null;
        toast.error(action.payload);
      })

      .addCase(updateCategory.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.category = action.payload.data;
        console.log(action.payload);
        // toast.success("Create new Category successfully");
      })

      .addCase(updateCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.category = null;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = categorySlice.actions;
export const selectCategory = (state) => state.category.category;
export const selectCategories = (state) => state.category.categories;
export const selectSuccess = (state) => state.category.isSuccess;

export default categorySlice.reducer;
