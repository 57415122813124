import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import OrderServices from "./OrderServices";

const initialState = {
  order: null,
  orders: [],
  orderByDate: [],
  pendingOrders: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// createOrder thunkApi
export const createOrder = createAsyncThunk(
  "order/createOrder",
  async (orderData, thunkAPI) => {
    try {
      return await OrderServices.createOrder(orderData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrders = createAsyncThunk(
  "order/getOrders",
  async (_, thunkAPI) => {
    try {
      return await OrderServices.getOrders();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getPendingOrders = createAsyncThunk(
  "order/getPendingOrders",
  async (_, thunkAPI) => {
    try {
      return await OrderServices.getPendingOrders();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateOrderStatus = createAsyncThunk(
  "order/updateOrderStatus",
  async ({ status, id }, thunkAPI) => {
    try {
      return await OrderServices.updateOrderStatus({ status, id });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteOrder = createAsyncThunk(
  "order/deleteOrder",
  async (id, thunkAPI) => {
    try {
      return await OrderServices.deleteOrder(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrdersByDateTimeRange = createAsyncThunk(
  "order/getOrdersByDateTimeRange",
  async (orderData, thunkAPI) => {
    try {
      const response = await OrderServices.getOrdersByDateTimeRange(orderData);
      return response;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    RESETORDER(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.orderByDate = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create order
      .addCase(createOrder.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.order = action.payload;
        // state.pendingOrders.push(action.payload);
        console.log(action.payload);
        state.isSuccess = true;
        toast.success("Create new order successfully");
      })

      .addCase(createOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.order = null;
        toast.error(action.payload);
      })

      .addCase(getOrders.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orders = action.payload.data;
      })

      .addCase(getOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.orders = null;
        toast.error(action.payload);
      })

      .addCase(getPendingOrders.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getPendingOrders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.pendingOrders = action.payload.data;
      })

      .addCase(getPendingOrders.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.pendingOrders = null;
        toast.error(action.payload);
      })

      .addCase(updateOrderStatus.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateOrderStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload);
      })

      .addCase(updateOrderStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(deleteOrder.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteOrder.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload);
      })

      .addCase(deleteOrder.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      })

      .addCase(getOrdersByDateTimeRange.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getOrdersByDateTimeRange.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.orderByDate = action.payload;
      })

      .addCase(getOrdersByDateTimeRange.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.orderByDate = null;
        toast.error(action.payload);
      });
  },
});

export const { RESETORDER } = orderSlice.actions;
export const selectOrder = (state) => state.order.order;
export const selectOrders = (state) => state.order.orders;
export const selectOrderByDate = (state) => state.order?.orderByDate;
export const selectPendingOrders = (state) => state.order?.pendingOrders;
export const selectOrderSuccess = (state) => state.order.isSuccess;

export default orderSlice.reducer;
