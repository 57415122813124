import React, { useEffect } from "react";
// import { FaPlus } from "react-icons/fa";
import Navbar from "../../component/Navbar/Navbar";
import Sidebar from "../../component/sidebar/Sidebar";
import { sidebarSections } from "../Items/Items";
import CategoryLogo from "../../Asset/Category logo.png";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";
import CreateCateyory from "../../component/CreateCategory/CreateCategory";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  selectCategories,
} from "../../redux/features/category/CategorySlice";

import EditCategory from "../../component/EditCategory/EditCategory";

const Category = () => {
  useRedirectLogoutUser("/login");
  const sidebarSection = sidebarSections;
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);
  // console.log(categories);
  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      {/* Navbar */}

      <Navbar page="Categories" />

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="w-[30%] md:block hidden bg-white shadow-md">
          <Sidebar sidebarSections={sidebarSection} />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex justify-center  p-8 overflow-y-auto">
          {categories ? (
            <div className="mx-auto">
              <div className="flex  justify-start  gap-4 w-full flex-wrap">
                {categories.map((category, i) => (
                  <div
                    key={i}
                    className="w-[9rem] h-[9rem] bg-slate-300 rounded-3xl overflow-hidden flex items-center justify-center relative"
                  >
                    {category.image ? (
                      <img
                        src={category.image}
                        alt={`Category ${i + 1}`}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-gray-500">No Image</span>
                    )}
                    {/* <span className="absolute text-white bg-[#20BEC6CC] rounded-lg p-[4px] top-3 right-3">
                      <BiPencil className="size-4" />
                    </span> */}
                    <EditCategory id={category._id} />
                    <p className="absolute bg-[#20BEC6CC] w-full text-center text-[14px] bottom-8 font-semibold text-white py-1 capitalize">
                      {category.name}
                    </p>
                  </div>
                ))}
              </div>
              <CreateCateyory />
            </div>
          ) : (
            <div className="flex items-center">
              <div className="w-[30rem] lg:w-[38rem]  relative  lg:h-[30rem] h-[23rem] bg-[#A9A9A9] rounded-[2.4rem] text-white flex flex-col justify-center items-center ">
                <img
                  src={CategoryLogo}
                  className="size-16 md:size-32 mb-2"
                  alt="Logo"
                />
                <p className="text-[1rem] md:text-[1.5rem] font-inter-700 text-center">
                  You have no categories yet
                </p>
                <p className="text-[.8rem] md:text-[1rem] font-inter-400 text-center">
                  To add a category, press the (+) button
                </p>
                <CreateCateyory />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Category;
