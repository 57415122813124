import React, { useEffect, useRef, useState } from "react";
import Counter from "./Counter";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  createOrder,
  RESETORDER,
  selectOrder,
  selectOrderSuccess,
} from "../../redux/features/order/OrderSlice";
import { useNavigate } from "react-router-dom";
import OrderDiscount from "./orderDiscount";
import dayjs from "dayjs";

const Order = ({ order, setOrder, option, setOption, deliveryData }) => {
  // billing details
  const [billigDetails, setBillingDetails] = useState({
    totalAmountDiscount: 0,
    charged: 0,
    returnAmount: 0,
    AdditionalNote: "",
    deliverAt: "",
  });

  // order
  const handleQuantityChange = (index, newQuantity) => {
    const updatedOrderItems = order.map((item, i) =>
      i === index ? { ...item, quantity: newQuantity } : item
    );
    setOrder(updatedOrderItems);
  };

  const handleDelete = (index) => {
    const updatedOrderItems = order.filter((item, i) => i !== index);
    setOrder(updatedOrderItems);
  };
  const handleCancel = () => {
    setOrder([]);
  };

  const total = order
    .reduce((acc, item) => {
      const itemPrice =
        item.discount > 0 ? Number(item.discountPrice) : Number(item.price);

      let toppingTotal = 0;
      if (item.dealItems?.length > 0) {
        toppingTotal = item.dealItems.reduce((dealAcc, dealItem) => {
          const toppingPrices = Array.isArray(dealItem.toppingPrice)
            ? dealItem.toppingPrice
            : [];
          const dealItemToppingTotal = toppingPrices.reduce(
            (priceAcc, price) => priceAcc + Number(price),
            0
          );

          return dealAcc + dealItemToppingTotal * item.quantity;
        }, 0);
      } else {
        toppingTotal =
          item.toppingPrice?.length > 0
            ? item.toppingPrice.reduce(
                (priceAcc, price) => priceAcc + Number(price),
                0
              ) * item.quantity
            : 0;
      }
      const add_OnTotal = item.add_ons.reduce(
        (priceAcc, addOn) => priceAcc + Number(addOn.price),
        0
      );

      return acc + (itemPrice + add_OnTotal) * item.quantity + toppingTotal;
    }, 0)
    .toFixed(2);

  const handleOptionChange = (option) => {
    setOption(option);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectOrderSuccess);
  const currentOrder = useSelector(selectOrder);

  const orderData = {
    orderType: "",
    orderNo: 0,
    amountType: "",
    products: [],
    customerName: "",
    customerNumber: "",
    customerAddress: "",
    deliveryCharges: 0,
    totalAmountDiscount: 0,
    totalAmountAfterDiscount: 0,
    charged: 0,
    returnAmount: 0,
    AdditionalNote: "",
    deliverAt: "",
    totalAmount: 0,
  };

  const handleOrder = async () => {
    if (order.length > 0) {
      const now = new Date();
      const orderNo = Number(
        `${now.getDate() % 10}${now.getHours() % 10}${
          now.getMinutes() % 10
        }${now.getSeconds().toString().padStart(2, "0")}${now
          .getMilliseconds()
          .toString()
          .padStart(3, "0")
          .slice(0, 2)}`
      );
      orderData.orderType = deliveryData.OrderType;
      orderData.orderNo = orderNo;
      orderData.amountType = option;
      orderData.products = order;
      orderData.customerName = deliveryData?.customerName || "";
      orderData.customerNumber = deliveryData?.customerNumber || "";
      orderData.customerAddress = deliveryData?.customerAddress || "";
      orderData.deliveryCharges = deliveryData?.DeliveryCharges || 0;
      orderData.totalAmountDiscount =
        Number(billigDetails?.totalAmountDiscount) || 0;
      if (Number(billigDetails?.totalAmountDiscount) > 0) {
        orderData.totalAmountAfterDiscount =
          Number(total) -
          Number(billigDetails.totalAmountDiscount) +
          Number(deliveryData?.DeliveryCharges || 0);
      }
      orderData.charged = Number(billigDetails?.charged);
      orderData.returnAmount = billigDetails?.returnAmount || 0;
      orderData.AdditionalNote = billigDetails?.AdditionalNote || "";
      if (deliveryData.OrderType === "Delivery" && billigDetails.deliverAt) {
        orderData.deliverAt = `${
          deliveryData.customerName ? deliveryData.customerName : "they"
        } said deliver at ${billigDetails.deliverAt} please`;
      }
      if (deliveryData.OrderType === "Delivery") {
        orderData.totalAmount =
          Number(total) + Number(deliveryData.DeliveryCharges);
        console.log(orderData.totalAmount);
      } else {
        orderData.totalAmount = Number(total);
      }

      // Dispatch the order data
      await dispatch(createOrder(orderData));

      console.log(orderData);
    } else {
      toast.error("Min 1 product to order");
    }
  };

  const companyName = process.env.REACT_APP_COMPANY_NAME;
  // const managerName = process.env.REACT_APP_MANAGER_NAME;
  const companyAddress = process.env.REACT_APP_COMPANY_ADDRESS;
  const companyPhone = process.env.REACT_APP_COMPANY_PHONE;
  const companyEmail = process.env.REACT_APP_COMPANY_EMAIL;

  const printRef = useRef();

  const handlePrint = () => {
    const printContent = printRef.current;
    const originalContent = document.body.innerHTML;

    document.body.innerHTML = printContent.innerHTML;
    window.print();
    document.body.innerHTML = originalContent;
    window.location.reload();
  };

  useEffect(() => {
    if (!deliveryData.OrderType) {
      navigate("/orderType");
    }
  }, [deliveryData, navigate]);

  useEffect(() => {
    if (isSuccess) {
      handlePrint();
      handleCancel();
      navigate("/orderType");
      dispatch(RESETORDER());
    }
  }, [isSuccess, navigate]);

  return (
    <div className="flex flex-col w-full justify-between ">
      <div className=" overflow-y-auto scrollbar-hide w-full ">
        <div className="lg:mx-8 mx-4 border-b-2 text-[.8rem] mt-1 text-[#31393a] capitalize">
          {deliveryData?.OrderType && (
            <p>Order Type: {deliveryData.OrderType}</p>
          )}
          {deliveryData?.customerName && (
            <p>Name: {deliveryData.customerName}</p>
          )}
          {deliveryData?.customerNumber && (
            <p>Phone: {deliveryData.customerNumber}</p>
          )}
          {deliveryData?.customerAddress && (
            <p>Address: {deliveryData.customerAddress}</p>
          )}
          {deliveryData?.DeliveryCharges !== undefined &&
            deliveryData.DeliveryCharges !== 0 && (
              <p>Delivery Charges: €{deliveryData.DeliveryCharges}</p>
            )}

          <select
            id="order-type"
            name="orderType"
            value={option}
            onChange={(e) => handleOptionChange(e.target.value)}
            className="mt-1 w-[97%]  py-2 outline-none text-[#0D868C] "
          >
            <option value="cash">Cash</option>
            <option value="card">Card</option>
          </select>
        </div>

        <div className="lg:mx-8 mx-4">
          {order.length > 0 ? (
            <ul className="mt-2">
              {order.map((product, index) => (
                <li
                  key={index}
                  className={`flex items-center w-full lg:gap-6 gap-4 p-2 ${
                    index !== 0 ? "border-t border-[#DEDEDE]" : ""
                  }`}
                >
                  <div className="flex flex-col flex-1 ">
                    <h2 className="text-[#0D868C] text-[.8rem] lg:text-[1rem] font-semibold capitalize">
                      {product.name}{" "}
                      {product.size && (
                        <span className="text-[.6rem]">({product.size})</span>
                      )}
                    </h2>
                    <h2>
                      {product.dealItems &&
                        product.dealItems?.map((item, i) => (
                          <>
                            <h2 className="text-[#47b9be] text-[.7rem] font-semibold capitalize">
                              {item.itemName}
                            </h2>
                            <div className="text-[8px] lg:text-[10px] font-semibold text-[#A9A9A9] flex flex-col">
                              {item.base && <span>Base : {item.base} </span>}
                              {item.sauce && <span>Sauce : {item.sauce}</span>}
                              {item.cheese && (
                                <span>Cheese : {item.cheese}</span>
                              )}
                              {item.toppings?.length > 0 && (
                                <span>
                                  Toppings :{" "}
                                  {item.toppings?.map((t, i) => (
                                    <span key={i}>
                                      {t}{" "}
                                      {i < item.toppings?.length - 1
                                        ? ", "
                                        : ""}{" "}
                                    </span>
                                  ))}
                                </span>
                              )}
                            </div>
                          </>
                        ))}
                    </h2>
                    <h2 className="text-[#47b9be] text-[.7rem] font-semibold capitalize">
                      {product.add_ons.length > 0 &&
                        product.add_ons?.map((item, i) => (
                          <div>{item.name}</div>
                        ))}
                    </h2>
                    {/* <p
                      title={product.description}
                      className="text-[8px] lg:text-[10px] font-semibold text-[#A9A9A9]"
                    >
                      {truncateString(product.description)}
                    </p> */}
                    <div className="text-[8px] lg:text-[10px] font-semibold text-[#A9A9A9] flex flex-col">
                      {product.base && <span>Base : {product.base} </span>}
                      {product.sauce && <span>Sauce : {product.sauce}</span>}
                      {product.cheese && <span>Cheese : {product.cheese}</span>}
                      {product.toppings.length > 0 && (
                        <span>
                          Toppings :{" "}
                          {product.toppings.map((item, i) => (
                            <span key={i}>
                              {item}{" "}
                              {i < product.toppings.length - 1 ? ", " : ""}{" "}
                            </span>
                          ))}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 items-end gap-2">
                    <Counter
                      quantity={product.quantity}
                      onQuantityChange={(newQuantity) =>
                        handleQuantityChange(index, newQuantity)
                      }
                    />
                    <p className="text-[#1E1E1E] text-[.8rem] lg:text-[1rem]">
                      {product.discount > 0 ? (
                        <span>
                          €
                          {/* {(
                            Number(product.discountPrice) * product.quantity +
                            (product.toppingPrice.length > 0
                              ? product.toppingPrice.reduce(
                                  (acc, price) => acc + Number(price),
                                  0
                                ) * product.quantity
                              : 0)
                          ).toFixed(2)} */}
                          {(
                            Number(product.discountPrice) * product.quantity +
                            (product.add_ons.length > 0
                              ? product.add_ons.reduce(
                                  (priceAcc, addOn) =>
                                    priceAcc +
                                    Number(addOn.price) * product.quantity,
                                  0
                                )
                              : 0) +
                            (product.dealItems?.length > 0
                              ? product.dealItems.reduce(
                                  (dealAcc, dealItem) => {
                                    const toppingPrice = Array.isArray(
                                      dealItem.toppingPrice
                                    )
                                      ? dealItem.toppingPrice.reduce(
                                          (priceAcc, price) =>
                                            priceAcc + Number(price),
                                          0
                                        )
                                      : 0;
                                    return (
                                      dealAcc + toppingPrice * product.quantity
                                    );
                                  },
                                  0
                                )
                              : Array.isArray(product.toppingPrice) &&
                                product.toppingPrice.length > 0
                              ? product.toppingPrice.reduce(
                                  (acc, price) => acc + Number(price),
                                  0
                                ) * product.quantity
                              : 0)
                          ).toFixed(2)}
                        </span>
                      ) : (
                        <span>
                          €
                          {/* {(
                            Number(product.price) * product.quantity +
                            (product.toppingPrice.length > 0
                              ? product.toppingPrice.reduce(
                                  (acc, price) => acc + Number(price),
                                  0
                                ) * product.quantity
                              : 0)
                          ).toFixed(2)} */}
                          {(
                            Number(product.price) * product.quantity +
                            (product.add_ons.length > 0
                              ? product.add_ons.reduce(
                                  (priceAcc, addOn) =>
                                    priceAcc +
                                    Number(addOn.price) * product.quantity,
                                  0
                                )
                              : 0) +
                            (product.dealItems?.length > 0
                              ? product.dealItems.reduce(
                                  (dealAcc, dealItem) => {
                                    // Calculate the topping price for each deal item
                                    const toppingPrice = Array.isArray(
                                      dealItem.toppingPrice
                                    )
                                      ? dealItem.toppingPrice.reduce(
                                          (priceAcc, price) =>
                                            priceAcc + Number(price),
                                          0
                                        )
                                      : 0;
                                    return (
                                      dealAcc + toppingPrice * product.quantity
                                    );
                                  },
                                  0
                                )
                              : Array.isArray(product.toppingPrice) &&
                                product.toppingPrice.length > 0
                              ? product.toppingPrice.reduce(
                                  (acc, price) => acc + Number(price),
                                  0
                                ) * product.quantity
                              : 0)
                          ).toFixed(2)}
                        </span>
                      )}
                    </p>
                  </div>
                  <div>
                    <button
                      className="text-red-500"
                      onClick={() => handleDelete(index)}
                    >
                      <MdDelete className="lg:size-6 size-4" />
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="capitalize mt-6 text-[#A9A9A9] lg:text-[1.5rem] text-wrap">
              no item in the order
            </p>
          )}
        </div>
      </div>
      <div>
        <div className="flex justify-between lg:mx-8 mx-4 items-center px-2 py-3 border-y-2 border-[#DEDEDE] my-4 relative">
          <span className="text-[.8rem] lg:text-[1rem] font-bold text-[#0D868C]">
            Total
          </span>
          <span className="text-[#1E1E1E] lg:text-[1rem] text-[.8rem]">
            €{(Number(total) + (deliveryData?.DeliveryCharges || 0)).toFixed(2)}
          </span>
          <div className="flex justify-start items-center absolute top-[-100%] left-0">
            <OrderDiscount
              setBillingDetails={setBillingDetails}
              billigDetails={billigDetails}
              totalAmount={(
                Number(total) + (deliveryData?.DeliveryCharges || 0)
              ).toFixed(2)}
              amountType={option}
            />
          </div>
        </div>
        <div className="flex justify-center items-center mb-5 lg:gap-10 gap-5">
          <button
            onClick={handleCancel}
            className="text-white bg-[#0D868C] text-[.8rem] lg:text-[1rem] font-bold w-[6rem] lg:w-[9rem] p-3 rounded-full uppercase"
          >
            cancel
          </button>
          <button
            onClick={handleOrder}
            className="text-white bg-[#0D868C] text-[.8rem] lg:text-[1rem] font-bold w-[6rem] lg:w-[9rem] p-3 rounded-full uppercase"
          >
            Order
          </button>
        </div>
      </div>
      <div className="hidden invisible">
        <div ref={printRef} className="flex justify-center items-center">
          <div className=" m-1  bg-white  flex flex-col gap-2">
            <div className="text-center">
              {companyName && (
                <p className="text-[1.3rem] text-[#1E1E1E] font-bold">
                  {companyName}
                </p>
              )}
            </div>
            {/* Top Section */}
            <div className="w-full text-center">
              <h1 className="lg:text-[2.75rem] text-[2rem] font-bold text-[#1E1E1E]">
                €{" "}
                {currentOrder?.totalAmountDiscount > 0
                  ? currentOrder?.totalAmountAfterDiscount.toFixed(2)
                  : currentOrder?.totalAmount.toFixed(2)}
              </h1>
              <h2 className="text-[#0D868C] lg:text-[1rem] text-[.8rem] font-bold capitalize">
                Total paid
              </h2>
            </div>
            {/* order no */}

            <div className="w-full text-center">
              <h1 className="text-[1rem] font-bold text-[#1E1E1E]">
                <p>Invoice #{currentOrder?.orderNo}</p>
              </h1>
            </div>
            {/* customer section */}
            <div className="flex justify-between items-center pt-2 lg:px-4 px-3">
              <p className="text-[#1E1E1E] lg:text-[12px] text-[10px] font-normal flex flex-col">
                {currentOrder?.customerName && (
                  <span className="capitalize">
                    Name: {currentOrder?.customerName}
                  </span>
                )}
                {currentOrder?.customerNumber && (
                  <span className="capitalize">
                    ph#: {currentOrder?.customerNumber}
                  </span>
                )}
                {currentOrder?.customerAddress && (
                  <span className="capitalize">
                    Address: {currentOrder?.customerAddress}
                  </span>
                )}
                {currentOrder?.AdditionalNote && (
                  <span className="capitalize">
                    Notes: {currentOrder?.AdditionalNote}
                  </span>
                )}
                {currentOrder?.deliverAt && (
                  <span className="capitalize">
                    deliver at: {currentOrder?.deliverAt}
                  </span>
                )}
              </p>
            </div>
            {/* Lower Top Section */}
            <div className="flex justify-between items-center border-y border-[#0D868C] lg:p-4 p-3">
              <p className="text-[#1E1E1E] lg:text-[12px] text-[10px] font-normal">
                Cashier:{" "}
                <span className="capitalize">{currentOrder?.username}</span>
              </p>
              <p className="text-[#1E1E1E] lg:text-[14px] text-[12px] font-normal">
                {currentOrder?.orderType}
              </p>
            </div>
            {/* Middle Section */}
            <div>
              {currentOrder?.products.map((item, index) => (
                <div
                  key={item._id}
                  className={`flex items-start  w-full lg:gap-6 gap-4 p-2 ${
                    index !== 0 ? "border-t-2 border-[#000000]" : ""
                  }`}
                >
                  <div className="flex flex-col flex-1">
                    <h2 className="text-[#1E1E1E] text-[.8rem] lg:text-[1rem] font-semibold capitalize">
                      {item.name}{" "}
                      {item.size && (
                        <span className="text-[.6rem]">({item.size})</span>
                      )}
                    </h2>
                    <h2>
                      {item.dealItems &&
                        item.dealItems?.map((item, i) => (
                          <div
                            className={`mx-2 ${
                              i !== 0 ? "border-t border-[#000000]" : ""
                            }`}
                          >
                            <h2 className="text-[#1E1E1E] text-[.7rem] font-semibold capitalize">
                              {item.itemName}
                            </h2>
                            <div className="text-[12px] font-semibold text-[#1E1E1E] flex flex-col">
                              {item.base && <span>Base : {item.base} </span>}
                              {item.sauce && <span>Sauce : {item.sauce}</span>}
                              {item.cheese && (
                                <span>Cheese : {item.cheese}</span>
                              )}
                              {item.toppings?.length > 0 && (
                                <span>
                                  Toppings :{" "}
                                  {item.toppings?.map((t, i) => (
                                    <span key={i}>
                                      {t}{" "}
                                      {i < item.toppings?.length - 1
                                        ? ", "
                                        : ""}{" "}
                                    </span>
                                  ))}
                                </span>
                              )}
                            </div>
                          </div>
                        ))}
                    </h2>
                    <h2 className="text-[#1E1E1E] text-[.7rem] font-semibold capitalize">
                      {item.add_ons.length > 0 &&
                        item.add_ons?.map((add_onsItem, i) => (
                          <div
                            className={`mx-2 ${
                              i !== 0 ? "border-t border-[#000000]" : ""
                            }`}
                          >
                            {add_onsItem.name}
                          </div>
                        ))}
                    </h2>
                    <div className="text-[12px] font-semibold text-[#1E1E1E] flex flex-col">
                      {item.base && <span>Base: {item.base} </span>}
                      {item.sauce && <span>Sauce: {item.sauce}</span>}
                      {item.cheese && <span>Cheese: {item.cheese}</span>}
                      {item.toppings?.length > 0 && (
                        <span>
                          Toppings:{" "}
                          {item.toppings.map((topping, i) => (
                            <span key={i}>
                              {topping}
                              {i < item.toppings.length - 1 ? ", " : ""}
                            </span>
                          ))}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between w-[25%] text-[#1E1E1E] text-[.8rem] lg:text-[1rem]">
                    <p className="w-[50%]">X{item.quantity}</p>
                    <p>
                      {item.discount > 0 ? (
                        <span>
                          €
                          {(
                            Number(item.discountPrice) * item.quantity +
                            (item.add_ons.length > 0
                              ? item.add_ons.reduce(
                                  (priceAcc, addOn) =>
                                    priceAcc +
                                    Number(addOn.price) * item.quantity,
                                  0
                                )
                              : 0) +
                            (item.dealItems?.length > 0
                              ? item.dealItems.reduce((dealAcc, dealItem) => {
                                  const toppingPrice = Array.isArray(
                                    dealItem.toppingPrice
                                  )
                                    ? dealItem.toppingPrice.reduce(
                                        (priceAcc, price) =>
                                          priceAcc + Number(price),
                                        0
                                      )
                                    : 0;
                                  return dealAcc + toppingPrice * item.quantity;
                                }, 0)
                              : Array.isArray(item.toppingPrice) &&
                                item.toppingPrice.length > 0
                              ? item.toppingPrice.reduce(
                                  (acc, price) => acc + Number(price),
                                  0
                                ) * item.quantity
                              : 0)
                          ).toFixed(2)}
                        </span>
                      ) : (
                        <span>
                          €
                          {(
                            Number(item.price) * item.quantity +
                            (item.add_ons.length > 0
                              ? item.add_ons.reduce(
                                  (priceAcc, addOn) =>
                                    priceAcc +
                                    Number(addOn.price) * item.quantity,
                                  0
                                )
                              : 0) +
                            (item.dealItems?.length > 0
                              ? item.dealItems.reduce((dealAcc, dealItem) => {
                                  // Calculate the topping price for each deal item
                                  const toppingPrice = Array.isArray(
                                    dealItem.toppingPrice
                                  )
                                    ? dealItem.toppingPrice.reduce(
                                        (priceAcc, price) =>
                                          priceAcc + Number(price),
                                        0
                                      )
                                    : 0;
                                  return dealAcc + toppingPrice * item.quantity;
                                }, 0)
                              : Array.isArray(item.toppingPrice) &&
                                item.toppingPrice.length > 0
                              ? item.toppingPrice.reduce(
                                  (acc, price) => acc + Number(price),
                                  0
                                ) * item.quantity
                              : 0)
                          ).toFixed(2)}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            {/* Upper Lower Section */}
            <div>
              <p className="flex flex-col justify-between items-center lg:p-4 p-3 lg:text-[1rem] text-[.8rem] border-y-2 border-[#DEDEDE]">
                {currentOrder?.orderType.toLowerCase() === "delivery" && (
                  <div className="flex justify-between items-center w-full text-[.8rem]">
                    <span className="text-[#1E1E1E] font-bold">
                      Delivery Charges
                    </span>
                    <span className="text-[#1E1E1E]">
                      {currentOrder?.deliveryCharges > 0
                        ? ` € ${currentOrder?.deliveryCharges.toFixed(2)}`
                        : "Free"}
                    </span>
                  </div>
                )}
                {currentOrder?.totalAmountDiscount > 0 ? (
                  <div className="flex justify-between items-center w-full">
                    <span className="text-[#1E1E1E] font-bold">Total</span>
                    <span className="text-[#1E1E1E] flex gap-3 items-center ">
                      <span className=" line-through text-[#1E1E1E]">
                        €{currentOrder?.totalAmount.toFixed(2)}
                      </span>
                      €{currentOrder?.totalAmountAfterDiscount.toFixed(2)}
                    </span>
                  </div>
                ) : (
                  <div className="flex justify-between items-center w-full">
                    <span className="text-[#1E1E1E] font-bold">Total</span>
                    <span className="text-[#1E1E1E]">
                      €{currentOrder?.totalAmount.toFixed(2)}
                    </span>
                  </div>
                )}
                {currentOrder?.charged > 0 && (
                  <div className="flex flex-col w-full text-[.8rem]">
                    <div className="flex justify-between items-center w-full">
                      <span className="text-[#1E1E1E] font-bold capitalize">
                        {currentOrder?.amountType}
                      </span>
                      <span className="text-[#1E1E1E]">
                        €{currentOrder?.charged.toFixed(2)}
                      </span>
                    </div>
                    {currentOrder?.totalAmountDiscount > 0 ? (
                      <div className="flex justify-between items-center w-full">
                        <span className="text-[#1E1E1E] font-bold">Paid</span>
                        <span className="text-[#1E1E1E] flex gap-3 items-center ">
                          €{currentOrder?.totalAmountAfterDiscount.toFixed(2)}
                        </span>
                      </div>
                    ) : (
                      <div className="flex justify-between items-center w-full">
                        <span className="text-[#1E1E1E] font-bold">Paid</span>
                        <span className="text-[#1E1E1E]">
                          €{currentOrder?.totalAmount.toFixed(2)}
                        </span>
                      </div>
                    )}
                    {currentOrder?.amountType.toLowerCase() === "cash" && (
                      <div className="flex justify-between items-center w-full">
                        <span className="text-[#1E1E1E] font-bold">Change</span>
                        <span className="text-[#1E1E1E]">
                          €{currentOrder?.returnAmount.toFixed(2)}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </p>
            </div>
            {/* shop section */}
            <div className="text-center text-[.7rem]">
              {companyName && (
                <p className=" text-[#070707] font-bold">
                  Thank You for Visiting {companyName}
                </p>
              )}
              {companyEmail && <p>{companyEmail}</p>}

              {companyAddress && <p>{companyAddress}</p>}
              {companyPhone && <p>{companyPhone}</p>}
              {/* {managerName && <p>Manager: {managerName}</p>} */}
            </div>
            {/* Lower Section */}
            <div className="flex justify-start items-center text-[#1E1E1E]  text-[14px] lg:px-4 px-3 pb-5">
              <p>
                {dayjs(currentOrder?.createdAt).format("D /M /YYYY . h:mm A")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Order;
