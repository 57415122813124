import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPendingOrders,
  selectPendingOrders,
} from "../../redux/features/order/OrderSlice";
import { IoEyeSharp } from "react-icons/io5";
import { FiX } from "react-icons/fi";
import dayjs from "dayjs";

const PreviewOrder = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handlePreviewOpen = () => {
    setIsOpen(true);
  };

  const handlePreviewClose = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();
  const pendingOrders = useSelector(selectPendingOrders);

  const order = useMemo(
    () => pendingOrders?.find((order) => order._id === id),
    [pendingOrders, id]
  );

  useEffect(() => {
    dispatch(getPendingOrders());
  }, [dispatch]);

  if (!order) {
    return null;
  }

  return (
    <div>
      <button
        onClick={handlePreviewOpen}
        className="capitalize lg:px-3 px-2 rounded-xl font-bold text-white py-2 bg-slate-300"
      >
        <IoEyeSharp className="lg:size-6 size-[1.1rem]" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
          <div className="bg-white w-full max-w-lg  p-6 rounded-xl shadow-xl shadow-[white]/50 md:mx-0 mx-3 relative">
            <div className=" capitalize font-bold text-[1.5rem]">
              order #{order.orderNo}
            </div>
            <button
              className="absolute top-7 right-7 "
              onClick={handlePreviewClose}
            >
              <FiX className="size-6 transform scale-150" />
            </button>

            {/* Order details */}
            <div className="lg:py-6 py-4 px-4 mx-auto mt-6 rounded-xl flex flex-col gap-4 lg:h-[25rem] h-[20rem] overflow-y-auto scrollbar-hide">
              {/* Top Section */}
              <div className="w-full text-center">
                <h1 className="lg:text-[2.75rem] text-[2rem] font-bold text-[#1E1E1E]">
                  €{" "}
                  {order.totalAmountDiscount > 0
                    ? order.totalAmountAfterDiscount.toFixed(2)
                    : order.totalAmount.toFixed(2)}
                </h1>
                <h2 className="text-[#0D868C] lg:text-[1rem] text-[.8rem] font-bold capitalize">
                  Total paid
                </h2>
              </div>

              {/* customer section */}
              <div className="flex justify-between items-center pt-2 lg:px-4 px-3">
                <p className="text-[#1E1E1E] lg:text-[12px] text-[10px] font-normal flex flex-col">
                  {order.customerName && (
                    <span className="capitalize">
                      Name: {order.customerName}
                    </span>
                  )}
                  {order.customerNumber && (
                    <span className="capitalize">
                      ph#: {order.customerNumber}
                    </span>
                  )}
                  {order.customerAddress && (
                    <span className="capitalize">
                      Address: {order.customerAddress}
                    </span>
                  )}
                  {order.AdditionalNote && (
                    <span className="capitalize">
                      Notes: {order.AdditionalNote}
                    </span>
                  )}
                  {order.deliverAt && (
                    <span className="capitalize">
                      deliver at: {order.deliverAt}
                    </span>
                  )}
                </p>
              </div>
              {/* Lower Top Section */}
              <div className="flex justify-between items-center border-y border-[#0D868C] lg:p-4 p-3">
                <p className="text-[#1E1E1E] lg:text-[12px] text-[10px] font-normal">
                  Employee: <span className="capitalize">{order.username}</span>
                </p>
                <p className="text-[#1E1E1E] lg:text-[14px] text-[12px] font-normal">
                  {order.orderType}
                </p>
              </div>

              {/* Middle Section */}
              <div>
                {order.products?.map((item, index) => (
                  <div
                    key={item._id}
                    className={`flex items-center w-full lg:gap-6 gap-4 p-2 ${
                      index !== 0 ? "border-t border-[#DEDEDE]" : ""
                    }`}
                  >
                    <div className="flex flex-col flex-1">
                      <h2 className="text-[#0D868C] text-[.8rem] lg:text-[1rem] font-semibold capitalize">
                        {item.name}{" "}
                        {item.size && (
                          <span className="text-[.6rem]">({item.size})</span>
                        )}
                      </h2>
                      <h2>
                        {item.dealItems &&
                          item.dealItems?.map((item, i) => (
                            <>
                              <h2 className="text-[#47b9be] text-[.7rem] font-semibold capitalize">
                                {item.itemName}
                              </h2>
                              <div className="text-[8px] lg:text-[10px] font-semibold text-[#A9A9A9]">
                                {item.base && <span>Base : {item.base} </span>}
                                {item.sauce && (
                                  <span>,Sauce : {item.sauce}</span>
                                )}
                                {item.cheese && (
                                  <span>,Cheese : {item.cheese}</span>
                                )}
                                {item.toppings?.length > 0 && (
                                  <span>
                                    ,Toppings :{" "}
                                    {item.toppings?.map((t, i) => (
                                      <span key={i}>
                                        {t}{" "}
                                        {i < item.toppings?.length - 1
                                          ? ", "
                                          : ""}{" "}
                                      </span>
                                    ))}
                                  </span>
                                )}
                              </div>
                            </>
                          ))}
                      </h2>
                      <h2 className="text-[#47b9be] text-[.7rem] font-semibold capitalize">
                        {item.add_ons.length > 0 &&
                          item.add_ons?.map((add_onsItem, i) => (
                            <div>{add_onsItem.name}</div>
                          ))}
                      </h2>
                      <div className="text-[8px] lg:text-[10px] font-semibold text-[#A9A9A9]">
                        {item.base && <span>Base: {item.base} </span>}
                        {item.sauce && <span>, Sauce: {item.sauce}</span>}
                        {item.cheese && <span>, Cheese: {item.cheese}</span>}
                        {item.toppings?.length > 0 && (
                          <span>
                            Toppings:{" "}
                            {item.toppings.map((topping, i) => (
                              <span key={i}>
                                {topping}
                                {i < item.toppings.length - 1 ? ", " : ""}
                              </span>
                            ))}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center justify-between w-[25%] text-[#A9A9A9] text-[.8rem] lg:text-[1rem]">
                      <p className="w-[50%]">X{item.quantity}</p>
                      <p>
                        {item.discount > 0 ? (
                          <span>
                            €
                            {(
                              Number(item.discountPrice) * item.quantity +
                              (item.add_ons.length > 0
                                ? item.add_ons.reduce(
                                    (priceAcc, addOn) =>
                                      priceAcc +
                                      Number(addOn.price) * item.quantity,
                                    0
                                  )
                                : 0) +
                              (item.dealItems?.length > 0
                                ? item.dealItems.reduce((dealAcc, dealItem) => {
                                    const toppingPrice = Array.isArray(
                                      dealItem.toppingPrice
                                    )
                                      ? dealItem.toppingPrice.reduce(
                                          (priceAcc, price) =>
                                            priceAcc + Number(price),
                                          0
                                        )
                                      : 0;
                                    return (
                                      dealAcc + toppingPrice * item.quantity
                                    );
                                  }, 0)
                                : Array.isArray(item.toppingPrice) &&
                                  item.toppingPrice.length > 0
                                ? item.toppingPrice.reduce(
                                    (acc, price) => acc + Number(price),
                                    0
                                  ) * item.quantity
                                : 0)
                            ).toFixed(2)}
                          </span>
                        ) : (
                          <span>
                            €
                            {(
                              Number(item.price) * item.quantity +
                              (item.add_ons.length > 0
                                ? item.add_ons.reduce(
                                    (priceAcc, addOn) =>
                                      priceAcc +
                                      Number(addOn.price) * item.quantity,
                                    0
                                  )
                                : 0) +
                              (item.dealItems?.length > 0
                                ? item.dealItems.reduce((dealAcc, dealItem) => {
                                    const toppingPrice = Array.isArray(
                                      dealItem.toppingPrice
                                    )
                                      ? dealItem.toppingPrice.reduce(
                                          (priceAcc, price) =>
                                            priceAcc + Number(price),
                                          0
                                        )
                                      : 0;
                                    return (
                                      dealAcc + toppingPrice * item.quantity
                                    );
                                  }, 0)
                                : Array.isArray(item.toppingPrice) &&
                                  item.toppingPrice.length > 0
                                ? item.toppingPrice.reduce(
                                    (acc, price) => acc + Number(price),
                                    0
                                  ) * item.quantity
                                : 0)
                            ).toFixed(2)}
                          </span>
                        )}
                      </p>
                    </div>
                  </div>
                ))}
              </div>

              {/* Upper Lower Section */}
              <div>
                <p className="flex flex-col justify-between items-center lg:p-4 p-3 lg:text-[1rem] text-[.8rem] border-y-2 border-[#DEDEDE]">
                  {order.deliveryCharges > 0 && (
                    <div className="flex justify-between items-center w-full text-[.8rem]">
                      <span className="text-[#0D868C] font-bold">
                        Delivery Charges
                      </span>
                      <span className="text-[#868585]">
                        €{order.deliveryCharges.toFixed(2)}
                      </span>
                    </div>
                  )}
                  {order.totalAmountDiscount > 0 ? (
                    <div className="flex justify-between items-center w-full">
                      <span className="text-[#0D868C] font-bold">Total</span>
                      <span className="text-[#868585] flex gap-3 items-center ">
                        <span className=" line-through text-[#c2c0c0]">
                          €{order.totalAmount.toFixed(2)}
                        </span>
                        €{order.totalAmountAfterDiscount.toFixed(2)}
                      </span>
                    </div>
                  ) : (
                    <div className="flex justify-between items-center w-full">
                      <span className="text-[#0D868C] font-bold">Total</span>
                      <span className="text-[#868585]">
                        €{order.totalAmount.toFixed(2)}
                      </span>
                    </div>
                  )}
                  {order.charged > 0 && (
                    <div className="flex flex-col w-full text-[.8rem]">
                      <div className="flex justify-between items-center w-full">
                        <span className="text-[#0D868C] font-bold capitalize">
                          {order.amountType}
                        </span>
                        <span className="text-[#868585]">
                          €{order.charged.toFixed(2)}
                        </span>
                      </div>
                      {order.totalAmountDiscount > 0 ? (
                        <div className="flex justify-between items-center w-full">
                          <span className="text-[#0D868C] font-bold">Paid</span>
                          <span className="text-[#868585] flex gap-3 items-center ">
                            €{order.totalAmountAfterDiscount.toFixed(2)}
                          </span>
                        </div>
                      ) : (
                        <div className="flex justify-between items-center w-full">
                          <span className="text-[#0D868C] font-bold">Paid</span>
                          <span className="text-[#868585]">
                            €{order.totalAmount.toFixed(2)}
                          </span>
                        </div>
                      )}
                      {order.amountType.toLowerCase() === "cash" && (
                        <div className="flex justify-between items-center w-full">
                          <span className="text-[#0D868C] font-bold">
                            Change
                          </span>
                          <span className="text-[#868585]">
                            €{order.returnAmount.toFixed(2)}
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </p>
              </div>

              {/* Lower Section */}
              <div className="flex justify-between items-center text-[#A9A9A9] lg:text-[12px] text-[10px] lg:px-4 px-3 py-2">
                <p>{dayjs(order.createdAt).format("D /M /YYYY . h:mm A")}</p>
                <p>#{order.orderNo}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PreviewOrder;
