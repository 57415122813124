import { configureStore } from "@reduxjs/toolkit";
import AuthSlice from "../features/auth/AuthSlice";
import CategorySlice from "../features/category/CategorySlice";
import ItemSlice from "../features/item/ItemSlice";
import BaseSlice from "../features/base/BaseSlice";
import SauceSlice from "../features/sauce/SauceSlice";
import ToppingSlice from "../features/topping/ToppingSlice";
import CheeseSlice from "../features/cheese/CheeseSlice";
import OrderSlice from "../features/order/OrderSlice";
import FilterSlice from "../features/filter/FilterSlice";

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    category: CategorySlice,
    item: ItemSlice,
    base: BaseSlice,
    sauce: SauceSlice,
    topping: ToppingSlice,
    cheese: CheeseSlice,
    order: OrderSlice,
    filter: FilterSlice,
  },
});
