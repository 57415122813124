import axios from "axios";
import { BACKEND_BASEURL } from "../../../db/baseUrl";
export const API_ITEMS = `${BACKEND_BASEURL}api/items/`;

const createItem = async (itemData) => {
  console.log(itemData);
  const response = await axios.post(API_ITEMS + "createItem", itemData);
  return response.data;
};

const getItems = async () => {
  const response = await axios.get(API_ITEMS + "getItems");
  return response.data;
};

const updateItem = async ({ id, updateItemData }) => {
  const response = await axios.patch(
    `${API_ITEMS}updateItem/${id}`,
    updateItemData
  );
  return response.data;
};

const deleteItem = async (id) => {
  const response = await axios.delete(API_ITEMS + id);
  return response.data.message;
};
const itemServices = {
  createItem,
  getItems,
  updateItem,
  deleteItem,
};
export default itemServices;
