import React from "react";

const ToggleSwitch = ({ isChecked, onToggle, id }) => {
  return (
    <>
      <input
        type="checkbox"
        id={id}
        className="hidden"
        checked={isChecked}
        onChange={onToggle}
      />
      <label
        htmlFor={id}
        className="relative cursor-pointer w-[50px] h-[20px] p-1 flex items-center border-2 border-[#D9D9D9] bg-transparent rounded-full transition-colors duration-200 ease-in-out"
      >
        <span
          className={`absolute left-0 w-[10px] h-[10px]  bg-[#D9D9D9] rounded-full shadow-md transform transition-transform duration-200 ease-in-out ${
            isChecked ? "translate-x-[32px] bg-teal-700" : "translate-x-1"
          }`}
        />
      </label>
    </>
  );
};

export default ToggleSwitch;
