import React from "react";
import SidebarSetting from "../SidebarSetting/SidebarSetting";
import Navbar from "../Navbar/Navbar";
import Profile from "./Profile";

const EditUser = () => {
  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      <div>
        <Navbar page="Settings" />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="lg:w-[30%] md:w-[39%]  md:block hidden overflow-y-auto scrollbar-hide bg-white shadow-md">
          <SidebarSetting />
        </div>

        {/* Main Content */}
        <div className="flex-1 md:p-8 py-6 overflow-y-auto scrollbar-hide">
          {/* <UserForm /> */}
          <Profile />
        </div>
      </div>
    </div>
    //
  );
};

export default EditUser;
