import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { RiTakeawayFill } from "react-icons/ri";
import { GiKnifeFork } from "react-icons/gi";
import DeliveryOption from "./DeliveryOption";
import axios from "axios";
import { BACKEND_BASEURL } from "../../db/baseUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const OrderType = ({ deliveryDatas, setDeliveryDatas, setOrder }) => {
  const [dineInData, setDineInData] = useState({
    customerName: "",
  });
  const [errors, setErrors] = useState({});
  const [deliveryData, setDeliveryData] = useState({
    customerName: "",
    customerNumber: "",
    customerAddress: "",
  });
  const [customerId, setCustomerId] = useState("");
  const [selectedDeliveryOption, setSelectedDeliveryOption] =
    useState("Delivery Charges");
  const [counterValue, setCounterValue] = useState(3.5);
  const [selectedOption, setSelectedOption] = useState("Dine In / Take Away");
  const [filterCustomers, setFilterCustomers] = useState([]);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setDeliveryData({});
    setDineInData({});
  };

  const handleDineInChange = (e) => {
    const { name, value } = e.target;
    setDineInData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDeliveryChange = (e) => {
    const { name, value } = e.target;
    setDeliveryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNumberChange = async (e) => {
    const { name, value } = e.target;
    await setDeliveryData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    getCustomer(deliveryData.customerNumber);
  };

  const validateForm = () => {
    const newErrors = {};
    if (selectedOption === "Delivery") {
      if (!deliveryData.customerNumber)
        newErrors.customerNumber = "Customer Number is required.";
      if (!deliveryData.customerAddress)
        newErrors.customerAddress = "Customer Address is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const saveUser = async (deliveryData) => {
    try {
      console.log(deliveryData);
      const response = await axios.post(
        `${BACKEND_BASEURL}api/customers/createCustomer`,
        deliveryData
      );
      console.log(response);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };
  const editUser = async (deliveryData, customerId) => {
    try {
      const response = await axios.patch(
        `${BACKEND_BASEURL}api/customers/updateCustomer/${customerId}`,
        deliveryData
      );
      const customer = response.data.customer;
      if (customer) {
        setDeliveryData({
          customerName: customer.name,
          customerNumber: customer.number,
          customerAddress: customer.address,
        });
        setCustomerId(customer._id);
      }

      console.log(response.data.customer);
      toast.success(response.data.message);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleSetDeliveryData = async (customer) => {
    setDeliveryData({
      customerName: customer.name,
      customerNumber: customer.number,
      customerAddress: customer.address,
    });
    setCustomerId(customer._id);
    setFilterCustomers([]);
  };
  const getCustomer = async (customerNumber) => {
    if (customerNumber) {
      try {
        const response = await axios.post(
          `${BACKEND_BASEURL}api/customers/getCustomer`,

          { customerNumber }
        );
        setFilterCustomers(response.data.filterCustomers || []);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(message);
      }
    }
  };
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();

    let updatedData;

    if (selectedOption === "Dine In / Take Away") {
      // console.log("Dine In / Take Away Data:", dineInData);
      updatedData = {
        customerName: dineInData.customerName,
        OrderType: selectedOption,
      };
      await setDeliveryDatas(updatedData);
      navigate("/orders");
    } else {
      if (validateForm()) {
        // console.log("Delivery Data:", deliveryData);
        // console.log("DeliveryType:", selectedDeliveryOption);

        if (selectedDeliveryOption === "Delivery Charges") {
          // console.log("Delivery Charges:", counterValue);
        }
        updatedData = {
          OrderType: selectedOption,
          customerName: deliveryData.customerName,
          customerNumber: deliveryData.customerNumber,
          customerAddress: deliveryData.customerAddress,
          DeliveryType: selectedDeliveryOption,
          DeliveryCharges:
            selectedDeliveryOption === "Delivery Charges" ? counterValue : 0,
        };

        await setDeliveryDatas(updatedData);
        navigate("/orders");
      }
    }
  };
  useEffect(() => {
    setOrder([]);
  }, [setOrder]);

  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1] relative">
      {/* Navbar */}
      <Navbar page="Order" />

      <div className="flex overflow-hidden items-center justify-center gap-16 h-full">
        <div className="flex items-center justify-center flex-col  gap-6">
          <label
            htmlFor="dineInTakeAway"
            className={`w-[12rem] h-[9rem] rounded-xl text-[1rem] font-bold p-2 flex flex-col gap-2 justify-center items-center ${
              selectedOption === "Dine In / Take Away"
                ? "bg-[#0D868C] text-white"
                : "bg-gray-200"
            }`}
          >
            <input
              type="radio"
              id="dineInTakeAway"
              name="orderOption"
              value="Dine In / Take Away"
              checked={selectedOption === "Dine In / Take Away"}
              onChange={handleOptionChange}
              className="hidden"
            />
            <GiKnifeFork className="size-10" />
            Dine In / Take Away
          </label>

          <label
            htmlFor="delivery"
            className={`w-[12rem] h-[9rem] rounded-xl text-[1rem] font-bold p-2 flex flex-col gap-2 justify-center items-center ${
              selectedOption === "Delivery"
                ? "bg-[#0D868C] text-white"
                : "bg-gray-200"
            }`}
          >
            <input
              type="radio"
              id="delivery"
              name="orderOption"
              value="Delivery"
              checked={selectedOption === "Delivery"}
              onChange={handleOptionChange}
              className="hidden"
            />
            <RiTakeawayFill className="size-10" />
            Delivery
          </label>
        </div>

        {/* Input fields for Dine In / Take Away */}
        {selectedOption === "Dine In / Take Away" && (
          <div className="lg:w-[30%] md:w-[40%] w-[60%]">
            <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
              Name
            </label>
            <input
              type="text"
              name="customerName"
              placeholder="Enter the Customer Name"
              value={dineInData.customerName}
              onChange={handleDineInChange}
              className="w-full outline-none bg-transparent text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b-2 border-[#0D868C]"
            />
          </div>
        )}

        {/* Input fields for Delivery */}
        {selectedOption === "Delivery" && (
          <div className="lg:w-[30%] md:w-[40%] w-[60%] flex justify-center items-center flex-col lg:gap-4 gap-3">
            <div className="w-full">
              <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
                Name
              </label>

              <input
                type="text"
                name="customerName"
                placeholder="Enter the Customer Name"
                value={deliveryData.customerName}
                onChange={handleDeliveryChange}
                onFocus={() => setFilterCustomers([])}
                className="w-full outline-none bg-transparent text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b-2 border-[#0D868C]"
              />
            </div>
            <div className="w-full relative">
              <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
                Phone Number
              </label>
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name="customerNumber"
                placeholder="923030000000"
                value={deliveryData.customerNumber}
                onChange={handleNumberChange}
                className="w-full outline-none bg-transparent text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b-2 border-[#0D868C]"
              />
              {errors.customerNumber && (
                <span className="text-red-500 text-sm">
                  {errors.customerNumber}
                </span>
              )}

              {deliveryData.customerNumber && filterCustomers?.length > 0 && (
                <div className=" absolute bg-white w-full mt-2 rounded-lg flex flex-col text-[#0D868C] shadow max-h-[15rem] h-min overflow-y-auto scrollbar-hide">
                  {filterCustomers.map((customer) => (
                    <div
                      key={customer._id}
                      className="flex flex-col p-2 border-b last:border-none hover:bg-[#A9A9A9]/10 cursor-pointer"
                      onClick={() => handleSetDeliveryData(customer)}
                    >
                      <div className="flex gap-2 text-[12px] flex-wrap">
                        <p className=" capitalize font-bold">{customer.name}</p>
                        <p>Phone: {customer.number}</p>
                      </div>
                      <p className="text-wrap text-[12px]">
                        {customer.address}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="w-full">
              <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
                EirCode / Address
              </label>
              <input
                type="text"
                name="customerAddress"
                placeholder="Enter the Customer address / EirCode"
                value={deliveryData.customerAddress}
                onChange={handleDeliveryChange}
                className="w-full outline-none bg-transparent text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b-2 border-[#0D868C]"
              />
              {errors.customerAddress && (
                <span className="text-red-500 text-sm">
                  {errors.customerAddress}
                </span>
              )}
            </div>
            <div className="w-full">
              <DeliveryOption
                counterValue={counterValue}
                setCounterValue={setCounterValue}
                selectedDeliveryOption={selectedDeliveryOption}
                setSelectedDeliveryOption={setSelectedDeliveryOption}
              />
            </div>
          </div>
        )}
      </div>

      <div className="absolute right-10 bottom-2 flex gap-4">
        {selectedOption === "Delivery" && (
          <button
            onClick={() => saveUser(deliveryData)}
            className="uppercase bg-[#0D868C] text-[white] lg:w-[10rem] ml-auto text-[.8rem] lg:text-[1rem] font-bold w-[8rem] p-3 rounded-full"
          >
            Save User
          </button>
        )}

        <button
          className="uppercase bg-[#0D868C] text-[white] lg:w-[10rem] ml-auto text-[.8rem] lg:text-[1rem] font-bold w-[8rem] p-3 rounded-full"
          onClick={handleSubmit}
        >
          Take Order
        </button>
      </div>
      {selectedOption === "Delivery" && deliveryData.customerNumber && (
        <div className="absolute left-10 bottom-2 flex gap-4">
          <button
            onClick={() => editUser(deliveryData, customerId)}
            className="uppercase bg-[#0D868C] text-[white] lg:w-[10rem] ml-auto text-[.8rem] lg:text-[1rem] font-bold w-[8rem] p-3 rounded-full"
          >
            edit User
          </button>
        </div>
      )}
    </div>
  );
};

export default OrderType;
