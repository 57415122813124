import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import authServices from "../redux/features/auth/AuthServies";
// import { toast } from "react-toastify";

const useRedirectLoginUser = ({ path1, path2 }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectLoginUser = async () => {
      let isLoggedIn;
      try {
        isLoggedIn = await authServices.getLoginStatus();
        console.log(isLoggedIn); // You can remove this after testing
      } catch (error) {
        console.log(error);
      }

      if (isLoggedIn) {
        try {
          const user = await authServices.getUser(); // Ensure this is a valid call
          if (user?.data?.role === "admin") {
            navigate(path1); // Redirect to admin path
          } else if (user?.data?.role === "user") {
            navigate(path2); // Redirect to user path
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    redirectLoginUser();
  }, [path1, path2, navigate]);
};

export default useRedirectLoginUser;
