import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar/Navbar";
import CategorySlider from "../../component/categorySlider/CategorySlider";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";

import Order from "../../component/Order/Order";
import { useParams } from "react-router-dom";
import { BACKEND_BASEURL } from "../../db/baseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getToppings,
  selectToppings,
} from "../../redux/features/topping/ToppingSlice";
import { getSauces, selectSauces } from "../../redux/features/sauce/SauceSlice";
import { getBases, selectBases } from "../../redux/features/base/BaseSlice";
import Counter from "../Order/Counter";
import {
  getCheeses,
  selectCheeses,
} from "../../redux/features/cheese/CheeseSlice";

const ItemDetail = ({ order, setOrder, option, setOption, deliveryDatas }) => {
  useRedirectLogoutUser("/login");
  const [count, setCount] = useState(1);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [selectedIndexes, setSelectedIndexes] = useState({});
  const toppings = useSelector(selectToppings);
  const bases = useSelector(selectBases);
  const sauces = useSelector(selectSauces);
  const cheeses = useSelector(selectCheeses);
  const dispatch = useDispatch();
  const [selectedSizePrice, setSelectedSizePrice] = useState(0);
  const [price, setPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [selectedTopping, setSelectedTopping] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedAdd_Ons, setSelectedAdd_Ons] = useState({
    fries: null,
    can: null,
  });
  // const [errors, setErrors] = useState({});

  // get item by id
  const getItemById = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(`${BACKEND_BASEURL}api/item/${id}`);
      setData(response.data);
      setLoading(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setData(null);
      setLoading(false);
    }
  };
  // apply discount
  const applyDiscount = (price, discount) => {
    return discount > 0 ? (price - (price * discount) / 100).toFixed(2) : price;
  };
  // select size

  const handleSizeChange = (event, itemId) => {
    const newIndex = parseInt(event.target.value);
    setSelectedIndexes((prev) => ({
      ...prev,
      [itemId]: newIndex,
    }));
    const price = applyDiscount(data.variants.prices[newIndex], data.discount);
    setSelectedSize(data.variants.sizes[newIndex]);
    setSelectedSizePrice(price);
    setDiscountedPrice(price);
    setPrice(data.variants.prices[newIndex]);
  };
  // select base
  const [selectedBaseIndex, setSelectedBaseIndex] = useState("");

  const handleBaseChange = (event, base) => {
    const newIndex = event.target.value;
    setSelectedBaseIndex(newIndex);
  };

  // select sauce
  const [selectedSauceIndex, setSelectedSauceIndex] = useState("");

  const handleSauceChange = (event, sauce) => {
    const newIndex = event.target.value;
    setSelectedSauceIndex(newIndex);
  };

  // select cheese
  const [selectedCheeseIndex, setSelectedCheeseIndex] = useState("");

  const handleCheeseChange = (event, cheese) => {
    const newIndex = event.target.value;
    setSelectedCheeseIndex(newIndex);
  };

  const orderData = {
    name: "",
    description: "",
    quantity: 0,
    price: 0,
    discountPrice: 0,
    discount: 0,
    size: "",
    add_ons: [],
    base: "",
    sauce: "",
    cheese: "",
    toppings: [],
    toppingPrice: [],
  };

  const validation = () => {
    const newErrors = {};

    // Validate size selection
    if (data.variants.sizes.length > 0) {
      if (!selectedSize || !selectedSizePrice) {
        newErrors.selectedSize = "Please select the size";
        toast.error(newErrors.selectedSize);
        return false;
      } else {
        orderData.size = selectedSize;
        orderData.price = Number(price);
        orderData.discountPrice = Number(selectedSizePrice);
      }
    } else {
      if (data.discount > 0) {
        // const discountedPrice = applyDiscount(data.price, data.discount);
        setDiscountedPrice(applyDiscount(data.price, data.discount));
        orderData.discountPrice = Number(discountedPrice);
      }
      orderData.price = Number(data.price);
    }

    if (data?.category?.includes("pizza")) {
      const names = selectedTopping.map((item) => item.name);
      const prices = selectedTopping.map((item) => item.price);

      if (!selectedSauceIndex || !selectedBaseIndex || !selectedCheeseIndex) {
        newErrors.selectedBaseIndex =
          "Please select the base, sauce and cheese";
        toast.error(newErrors.selectedBaseIndex);
        return false;
      } else {
        orderData.base = selectedBaseIndex;
        orderData.sauce = selectedSauceIndex;
        orderData.cheese = selectedCheeseIndex;
      }
      orderData.toppings = names;
      orderData.toppingPrice = prices;
    }
    if (
      data?.category.includes("burger") ||
      data?.category.includes("salthill fresh kabab")
    ) {
      orderData.add_ons = Object.values(selectedAdd_Ons).filter(
        (item) => item !== null
      );
    }

    orderData.name = data.name;
    orderData.description = data.description;
    orderData.quantity = count;
    orderData.discount = data.discount;

    // setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const updateOrder = async (orderData, event) => {
    if (event) event.preventDefault();
    if (validation()) {
      await setOrder((prevOrders) => [...prevOrders, orderData]);
      // console.log(order);
    }
  };

  useEffect(() => {
    if (id) {
      getItemById(id);
    }
  }, [id]);

  useEffect(() => {
    if (data) {
      const validCategories = ["pizza"];
      if (
        validCategories.some((category) => data.category.includes(category))
      ) {
        dispatch(getToppings());
        dispatch(getSauces());
        dispatch(getBases());
        dispatch(getCheeses());
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data?.name.includes("build your own")) {
      setSelectedTopping([]);
    } else if (data?.toppings?.length > 0) {
      const initialToppings = data.toppings.map((topping) => ({
        name: topping,
        price: 0,
      }));
      setSelectedTopping(initialToppings);
    }
  }, [data?.name, data?.toppings]);

  const MAX_TOPPINGS = data && data.toppings ? data.toppings.length : 0;

  const handleToppingToggle = (topping, price) => {
    setSelectedTopping((prevSelectedTopping) => {
      if (!Array.isArray(prevSelectedTopping)) {
        return [topping];
      }

      const exists = prevSelectedTopping.some(
        (selected) => selected.name === topping
      );

      if (exists) {
        let filteredToppings = prevSelectedTopping.filter(
          (selected) => selected.name !== topping
        );
        if (filteredToppings?.length > 0) {
          filteredToppings = filteredToppings.map((topping, index) => {
            const normalizedToppingName =
              topping.name?.trim().toLowerCase() || "";
            const matchingTopping = toppings.find(
              (predefined) =>
                predefined.name?.trim().toLowerCase() === normalizedToppingName
            );
            return {
              ...topping,
              price: index < MAX_TOPPINGS ? 0 : matchingTopping?.price || 0.5,
            };
          });
        }

        // console.log(filteredToppings);
        return filteredToppings;
      } else {
        if (prevSelectedTopping.length < MAX_TOPPINGS) {
          return [...prevSelectedTopping, { name: topping, price: 0 }];
        } else {
          setSelectedTopping((prevSelectedTopping) => [
            ...prevSelectedTopping,
            { name: topping, price: price || 0.5 },
          ]);
          return prevSelectedTopping;
        }
      }
    });
  };

  // add-ons for burger
  const [dips, setDips] = useState();
  const [drinks, setDrinks] = useState();
  const chips = {
    name: "chips",
    category: "fries",
  };
  const getItemByCategoryDrinks = async () => {
    try {
      const category = "drinks";
      const response = await axios.get(`${BACKEND_BASEURL}api/${category}`);
      setDrinks(response.data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const getItemByCategorydips = async () => {
    try {
      const category = "dips";
      const response = await axios.get(`${BACKEND_BASEURL}api/${category}`);
      setDips(response.data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleToggle = (category, item) => {
    let price = 0;
    if (category === "fries") {
      price = 2.49;
    } else if (category === "can") {
      price = 1.49;
    } else if (category === "dip") {
      price = 0.99;
    }

    setSelectedAdd_Ons((prevState) => ({
      ...prevState,
      [category]:
        prevState[category]?.name === item.name
          ? null
          : { name: item.name, price },
    }));
  };

  useEffect(() => {
    if (
      data?.category.includes("burger") ||
      data?.category.includes("salthill fresh kabab")
    ) {
      getItemByCategoryDrinks();
      getItemByCategorydips();
    }
  }, [data]);
  const [remainingToppings, setRemainingToppings] = useState([]);
  useEffect(() => {
    const filteredToppings = toppings?.filter(
      (topping) => !data?.toppings?.includes(topping.name)
    );
    setRemainingToppings(filteredToppings || toppings);
  }, [toppings, data]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="flex flex-col h-screen bg-[#F1F1F1]">
        <Navbar page="Order" />
        <CategorySlider />
        <div className="flex overflow-hidden h-full">
          <div className="flex flex-1 flex-col lg:p-8 p-4  overflow-y-auto scrollbar-hide ">
            {data ? (
              <>
                <div className="flex flex-col h-full justify-between gap-4 w-full">
                  {/* item */}
                  <div className="flex flex-row justify-between lg:gap-10 gap-6 w-full mx-auto">
                    <div className="flex-1">
                      <img
                        className="lg:w-[20rem] lg:h-[20rem] w-[13rem] h-[13rem] rounded-3xl bg-cover mx-auto bg-transparent"
                        src={data.itemImage}
                        alt={data.name}
                      />
                    </div>
                    <div className="flex-1 flex flex-col lg:gap-6 gap-4 ">
                      <div>
                        <h1 className="uppercase text-[#0D868C] text-wrap font-bold lg:text-2xl text-[1.25rem]">
                          {data.name}
                        </h1>
                        <p className="text-wrap text-[#A9A9A9] lg:text-[1rem] text-[.8rem] capitalize">
                          {data.description}
                        </p>
                      </div>

                      {data.price > 0 && (
                        <div className="flex gap-2 items-center">
                          <span className="font-bold text-[.8rem] lg:text-[1rem]">
                            Price :
                          </span>

                          <span
                            className={`text-[#1E1E1E] lg:text-[1rem] text-[.8rem] font-bold ${
                              data.discount > 0
                                ? "line-through text-gray-500"
                                : ""
                            }`}
                          >
                            € {data.price * count}
                          </span>
                          {data.discount > 0 && (
                            <span className="text-[#1E1E1E] lg:text-[1rem] text-[.8rem] font-bold">
                              €{" "}
                              {applyDiscount(data.price, data.discount) * count}
                              <span className="ml-2">{data.discount}%</span>
                            </span>
                          )}
                        </div>
                      )}

                      {data.variants.sizes.length > 0 && (
                        <div className="flex flex-col lg:gap-6 gap-4">
                          <div className="flex gap-2 items-center flex-wrap">
                            {data.variants.sizes.map((size, index) => (
                              <label
                                key={index}
                                className="flex items-center gap-2"
                              >
                                <input
                                  type="radio"
                                  name={`size-${data._id}`}
                                  value={index}
                                  checked={selectedIndexes[data._id] === index}
                                  onChange={(e) =>
                                    handleSizeChange(e, data._id)
                                  }
                                  className="form-radio text-blue-500 hidden h-4 w-4"
                                />
                                <span
                                  className={`text-[#1E1E1E]  lg:text-[1rem] text-[.8rem]  px-2 py-1 rounded-xl cursor-pointer border border-[#A9A9A9] ${
                                    selectedIndexes[data._id] === index
                                      ? "bg-[#0D868C] text-white font-semibold "
                                      : ""
                                  } font-inter-400`}
                                >
                                  {size.toUpperCase()}
                                </span>
                              </label>
                            ))}
                          </div>

                          <div className="flex gap-2 items-center">
                            <span className="font-bold text-[.8rem] lg:text-[1rem]">
                              Price :
                            </span>
                            <span
                              className={`text-[#1E1E1E] font-bold text-[.8rem] lg:text-[1rem]  ${
                                data.discount > 0
                                  ? "line-through text-gray-500"
                                  : ""
                              }`}
                            >
                              €
                              {(
                                data.variants.prices[
                                  selectedIndexes[data._id] ?? 0
                                ] * count
                              ).toFixed(2)}
                            </span>
                            {data.discount > 0 && (
                              <span className="text-[#1E1E1E] text-[.8rem] lg:text-[1rem] font-bold">
                                €
                                {(
                                  applyDiscount(
                                    parseFloat(
                                      data.variants.prices[
                                        selectedIndexes[data._id] ?? 0
                                      ]
                                    ),
                                    data.discount
                                  ) * count
                                ).toFixed(2)}
                                {/* <span className="ml-2">{data.discount}%</span> */}
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      {data?.name.includes("build your own") ? (
                        <div>
                          <p>Please customize your order!</p>
                        </div>
                      ) : (
                        data?.toppings?.length > 0 && (
                          <div className="flex gap-2 items-center flex-wrap">
                            {data?.toppings?.length > 0 && (
                              <div className="flex gap-2 items-center flex-wrap">
                                {data.toppings.map((topping, index) => (
                                  <label
                                    key={index}
                                    className="flex items-center gap-2 cursor-pointer"
                                  >
                                    <input
                                      type="checkbox"
                                      name={`topping-${data._id}`}
                                      value={topping}
                                      onChange={() =>
                                        handleToppingToggle(topping)
                                      }
                                      checked={selectedTopping.some(
                                        (selected) => selected.name === topping
                                      )}
                                      // disabled={
                                      //   selectedTopping.length >=
                                      //     MAX_TOPPINGS &&
                                      //   !selectedTopping.some(
                                      //     (selected) =>
                                      //       selected.name === topping
                                      //   )
                                      // }
                                      className="form-checkbox text-blue-500 hidden h-4 w-4"
                                    />
                                    <span
                                      className={`text-[#1E1E1E] lg:text-[1rem] text-[.8rem] px-2 py-1 rounded-xl border border-[#A9A9A9] ${
                                        selectedTopping.some(
                                          (selected) =>
                                            selected.name === topping
                                        )
                                          ? "bg-[#0D868C] text-white font-semibold"
                                          : ""
                                      } font-inter-400`}
                                    >
                                      {topping.toUpperCase()}
                                    </span>
                                  </label>
                                ))}
                              </div>
                            )}
                          </div>
                        )
                      )}
                      <Counter
                        quantity={count}
                        onQuantityChange={setCount}
                        textSize="text-[1rem] lg:text-[1.5rem]"
                        textColor="text-[#706e6e]"
                      />
                    </div>
                  </div>
                  {/* Add-ons */}
                  {data &&
                    (data.category === "burger" ||
                      data.category === "salthill fresh kabab") && (
                      <div className="flex flex-col w-full gap-4">
                        {data && data.category === "burger" ? (
                          <label className="flex justify-center mb-2 w-full uppercase lg:text-[1rem] text-[.8rem] font-bold px-10 py-2 text-[#0D868C]  ">
                            Add Chips for &euro;2.49 Add drinks for &euro;1.49
                          </label>
                        ) : (
                          <label className="flex justify-center mb-2 w-full uppercase lg:text-[1rem] text-[.8rem] font-bold px-10 py-2 text-[#0D868C]  ">
                            Add Chips for &euro;2.49 Add drinks for &euro;1.49
                            Add dip for &euro;.99
                          </label>
                        )}
                        <div className="flex flex-col gap-3 mb-2">
                          <h1 className="font-semibold capitalize ml-1">
                            Chips
                          </h1>
                          <div className="flex items-center gap-2">
                            <div className="flex items-center">
                              <label className="flex items-center gap-2 cursor-pointer">
                                <input
                                  type="checkbox"
                                  checked={
                                    selectedAdd_Ons.fries?.name === chips.name
                                  }
                                  onChange={() => handleToggle("fries", chips)}
                                  className="accent-blue-500 hidden"
                                />
                                <span
                                  className={`lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl font-inter-400 capitalize ${
                                    selectedAdd_Ons.fries?.name === chips.name
                                      ? "text-white bg-[#0D868C]"
                                      : "text-[#1E1E1E]/60 bg-white"
                                  }`}
                                >
                                  chips
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>

                        {drinks?.filter((item) =>
                          item.name.toLowerCase().includes("can")
                        ).length > 0 && (
                          <div className="flex flex-col gap-3 mb-2">
                            <h1 className="font-semibold capitalize ml-1">
                              Can
                            </h1>
                            <div className="flex items-center gap-2">
                              {drinks
                                ?.filter((item) =>
                                  item.name.toLowerCase().includes("can")
                                )
                                ?.map((item, i) => (
                                  <div key={i} className=" flex items-center">
                                    <label
                                      key={item._id}
                                      className="flex items-center gap-2 cursor-pointer"
                                    >
                                      <input
                                        type="checkbox"
                                        checked={
                                          selectedAdd_Ons.can?.name ===
                                          item.name
                                        }
                                        onChange={() =>
                                          handleToggle("can", item)
                                        }
                                        className="hidden"
                                      />
                                      <span
                                        className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl  font-inter-400 capitalize ${
                                          selectedAdd_Ons.can?.name ===
                                          item.name
                                            ? "text-[white] bg-[#0D868C]"
                                            : " text-[#1E1E1E]/60 bg-white"
                                        }`}
                                      >
                                        {item.name}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                        {data &&
                          data.category === "salthill fresh kabab" &&
                          dips?.length > 0 && (
                            <div className="flex flex-col gap-3 mb-2">
                              <h1 className="font-semibold capitalize ml-1">
                                Dips
                              </h1>
                              <div className="flex items-center gap-2">
                                {dips?.map((item, i) => (
                                  <div key={i} className="flex items-center">
                                    <label className="flex items-center gap-2 cursor-pointer">
                                      <input
                                        type="checkbox"
                                        checked={
                                          selectedAdd_Ons.dip?.name ===
                                          item.name
                                        }
                                        onChange={() =>
                                          handleToggle("dip", item)
                                        }
                                        className="accent-blue-500 hidden"
                                      />
                                      <span
                                        className={`lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl font-inter-400 capitalize ${
                                          selectedAdd_Ons.dip?.name ===
                                          item.name
                                            ? "text-white bg-[#0D868C]"
                                            : "text-[#1E1E1E]/60 bg-white"
                                        }`}
                                      >
                                        {item.name}
                                      </span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                  {/* bases */}
                  {data &&
                    data.category.includes("pizza") &&
                    bases.length > 0 && (
                      <div className=" flex flex-wrap">
                        <h1 className="flex justify-center w-full uppercase lg:text-[1.5rem] text-[1rem] font-bold px-10 py-2 text-[#0D868C] lg:border-y-2 border-y border-[#0D868C]">
                          Bases
                        </h1>
                        <div className="flex w-full gap-2 mt-4 justify-center items-center flex-wrap">
                          {bases.map((base, index) => (
                            <div key={index} className=" flex items-center">
                              <label className="flex items-center ">
                                <input
                                  type="radio"
                                  name="base"
                                  value={base.name}
                                  onChange={(e) => handleBaseChange(e, base)}
                                  className="form-radio hidden text-blue-500 h-4 w-4"
                                />
                                <span
                                  className={`text-[#1E1E1E]  lg:text-[1rem] text-[.8rem] uppercase  px-3 py-2 rounded-xl cursor-pointer border border-[#A9A9A9] ${
                                    selectedBaseIndex === base.name
                                      ? "bg-[#0D868C] text-white font-bold "
                                      : ""
                                  } font-inter-600`}
                                >
                                  {base.name}
                                </span>
                              </label>
                              {/* <img
                            src={base.image}
                            alt={base.name}
                            className="w-32 h-32 object-cover my-2"
                          /> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  {/* sauces */}
                  {data &&
                    data.category.includes("pizza") &&
                    sauces.length > 0 && (
                      <div className=" flex flex-wrap">
                        <h1 className="flex justify-center w-full uppercase lg:text-[1.5rem] text-[1rem] font-bold px-10 py-2 text-[#0D868C] lg:border-y-2 border-y border-[#0D868C]">
                          Sauces
                        </h1>
                        <div className="flex w-full gap-2 mt-4 justify-center items-center flex-wrap">
                          {sauces.map((sauce, index) => (
                            <div key={index} className=" flex items-center">
                              <label className="flex items-center ">
                                <input
                                  type="radio"
                                  name="sauce"
                                  value={sauce.name}
                                  onChange={(e) => handleSauceChange(e, sauce)}
                                  className="form-radio hidden text-blue-500 h-4 w-4"
                                />
                                <span
                                  className={`text-[#1E1E1E]  lg:text-[1rem] text-[.8rem] uppercase  px-3 py-2 rounded-xl cursor-pointer border border-[#A9A9A9] ${
                                    selectedSauceIndex === sauce.name
                                      ? "bg-[#0D868C] text-white font-bold "
                                      : ""
                                  } font-inter-600`}
                                >
                                  {sauce.name}
                                </span>
                              </label>
                              {/* <img
                            src={sauce.image}
                            alt={sauce.name}
                            className="w-32 h-32 object-cover my-2"
                          /> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                  {/* cheeses */}
                  {data &&
                    data.category.includes("pizza") &&
                    cheeses.length > 0 && (
                      <div className=" flex flex-wrap">
                        <h1 className="flex justify-center w-full uppercase lg:text-[1.5rem] text-[1rem] font-bold px-10 py-2 text-[#0D868C] lg:border-y-2 border-y border-[#0D868C]">
                          cheese
                        </h1>
                        <div className="flex w-full gap-2 mt-4 justify-center items-center flex-wrap">
                          {cheeses.map((cheese, index) => (
                            <div key={index} className=" flex items-center">
                              <label className="flex items-center ">
                                <input
                                  type="radio"
                                  name="cheese"
                                  value={cheese.name}
                                  onChange={(e) =>
                                    handleCheeseChange(e, cheese)
                                  }
                                  className="form-radio hidden text-blue-500 h-4 w-4"
                                />
                                <span
                                  className={`text-[#1E1E1E]  lg:text-[1rem] text-[.8rem] uppercase  px-3 py-2 rounded-xl cursor-pointer border border-[#A9A9A9] ${
                                    selectedCheeseIndex === cheese.name
                                      ? "bg-[#0D868C] text-white font-bold "
                                      : ""
                                  } font-inter-600`}
                                >
                                  {cheese.name}
                                </span>
                              </label>
                              {/* <img
                            src={sauce.image}
                            alt={sauce.name}
                            className="w-32 h-32 object-cover my-2"
                          /> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                  {data &&
                    data.category.includes("pizza") &&
                    toppings.length > 0 && (
                      <div className=" flex flex-wrap">
                        <h1 className="flex justify-center w-full uppercase lg:text-[1.5rem] text-[1rem] font-bold px-10 py-2 text-[#0D868C] lg:border-y-2 border-y border-[#0D868C]">
                          Toppings
                        </h1>
                        <div className="flex w-full gap-2 mt-4 justify-center items-center flex-wrap">
                          {remainingToppings.map((topping, index) => (
                            <div key={index} className=" flex items-center">
                              <label className="flex items-center ">
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    handleToppingToggle(
                                      topping.name,
                                      topping.price
                                    )
                                  }
                                  checked={selectedTopping.some(
                                    (selected) => selected.name === topping.name
                                  )}
                                  className="form-checkbox hidden text-blue-500 h-4 w-4"
                                />
                                <span
                                  className={`text-[#1E1E1E] lg:text-[1rem] text-[.8rem] uppercase px-3 py-2 rounded-xl cursor-pointer border border-[#A9A9A9] ${
                                    selectedTopping.some(
                                      (selected) =>
                                        selected.name === topping.name
                                    )
                                      ? "bg-[#0D868C] text-white font-bold"
                                      : ""
                                  } font-inter-600`}
                                >
                                  {topping.name}{" "}
                                </span>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                  <button
                    className=" uppercase sticky lg:bottom-0 lg:right-0 bottom-3 right-3  bg-[#0D868C] text-[white] lg:w-[10rem] ml-auto text-[.8rem] lg:text-[1rem] font-bold  w-[8rem] p-3 rounded-full"
                    onClick={() => updateOrder(orderData)}
                  >
                    confirm
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
            {/* <div className="fixed bottom-5 right-3 lg:mr-[35%] mr-[40%] ">
              <button className="bg-[#0D868C] py-2 px-3 uppercase rounded-full lg:text-[1rem] text-[.8rem] font-semibold text-white">
                {" "}
                confirm
              </button>
            </div> */}
          </div>
          <div className="flex flex-2 lg:w-[35%] w-[40%] bg-white">
            <Order
              order={order}
              setOrder={setOrder}
              option={option}
              setOption={setOption}
              deliveryData={deliveryDatas}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemDetail;
