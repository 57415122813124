import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Forgot from "./Pages/Forgot/Forgot";
import Login from "./Pages/Login/Login";
import Items from "./Pages/Items/Items";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Category from "./Pages/Category/Category";
import CreateItems from "./component/CreateItems/CreateItems";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getLoginStatus } from "./redux/features/auth/AuthSlice";
import Setting from "./Pages/Setting/Setting";
import CreateUser from "./component/CreateUser/CreateUser";
import ChangePassword from "./component/Change Password/ChangePassword";
import EditUser from "./component/Edit user/EditUser";
import Reset from "./Pages/Reset/Reset";
import EditItem from "./component/EditItem/EditItem";
import Base from "./Pages/Base/Base";
import Sauce from "./Pages/Sauce/Sauce";
import Topping from "./Pages/Topping/Topping";
import Sale from "./Pages/Sale/Sale";
import ItemByCategory from "./component/ItemByCategory/ItemByCategory";
import ItemDetail from "./component/ItemDetail/ItemDetial";
import Cheese from "./Pages/Cheese/Cheese";
import Receipt from "./Pages/Receipt/Receipt";
import CompletingOrder from "./Pages/Completing Order/CompletingOrder";
import { OnlyAdmin } from "./Protect/HiddenLink";
// import DynamicInput from "./DynamicInput";
import OrderType from "./component/OrderType/OrderType";
import DealItemDetail from "./component/DealItemDetail/DealItemDetail";
import User from "./Pages/User/User";

axios.defaults.withCredentials = true;

function App() {
  const [order, setOrder] = useState([]);
  const [option, setOption] = useState("card");
  const [deliveryData, setDeliveryData] = useState({
    OrderType: "",
    customerName: "",
    customerNumber: "",
    customerAddress: "",
    DeliveryCharges: 0,
    DeliveryType: "",
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoginStatus());
  }, [dispatch]);
  return (
    <BrowserRouter>
      <ToastContainer />
      <div>
        <Routes>
          <Route path="/" element={<LandingPage />} />

          <Route
            path="/orderType"
            element={
              <OrderType
                setOrder={setOrder}
                deliveryDatas={deliveryData}
                setDeliveryDatas={setDeliveryData}
              />
            }
          />

          <Route
            path="/items"
            element={
              <OnlyAdmin>
                <Items />
              </OnlyAdmin>
            }
          />
          <Route path="/receipts" element={<Receipt />} />
          <Route
            path="/bases"
            element={
              <OnlyAdmin>
                <Base />
              </OnlyAdmin>
            }
          />
          <Route
            path="/sauces"
            element={
              <OnlyAdmin>
                <Sauce />
              </OnlyAdmin>
            }
          />
          <Route
            path="/cheeses"
            element={
              <OnlyAdmin>
                <Cheese />
              </OnlyAdmin>
            }
          />
          <Route
            path="/categories"
            element={
              <OnlyAdmin>
                <Category />
              </OnlyAdmin>
            }
          />
          <Route
            path="/toppings"
            element={
              <OnlyAdmin>
                <Topping />
              </OnlyAdmin>
            }
          />
          <Route path="/resetPassword/:resetToken" element={<Reset />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgotPassword" element={<Forgot />} />
          <Route
            path="/createItem"
            element={
              <OnlyAdmin>
                <CreateItems />
              </OnlyAdmin>
            }
          />
          <Route
            path="/editItem/:id"
            element={
              <OnlyAdmin>
                <EditItem />
              </OnlyAdmin>
            }
          />
          <Route path="/settings" element={<Setting />} />
          <Route
            path="/orders"
            element={
              <Sale
                order={order}
                setOrder={setOrder}
                option={option}
                setOption={setOption}
                deliveryDatas={deliveryData}
              />
            }
          />
          <Route
            path="/query/:category"
            element={
              <ItemByCategory
                order={order}
                setOrder={setOrder}
                option={option}
                setOption={setOption}
                deliveryDatas={deliveryData}
              />
            }
          />
          <Route
            path="/query/item/:id"
            element={
              <ItemDetail
                order={order}
                setOrder={setOrder}
                option={option}
                setOption={setOption}
                deliveryDatas={deliveryData}
              />
            }
          />
          <Route
            path="/query/dealItem/:id"
            element={
              <DealItemDetail
                order={order}
                setOrder={setOrder}
                option={option}
                setOption={setOption}
                deliveryDatas={deliveryData}
              />
            }
          />
          <Route path="/completingOrder" element={<CompletingOrder />} />
          <Route path="/settings/users" element={<User />} />

          <Route
            path="/settings/users/createUser"
            element={
              <OnlyAdmin>
                <CreateUser />
              </OnlyAdmin>
            }
          />
          <Route path="/settings/changePassword" element={<ChangePassword />} />
          <Route
            path="/settings/users/editUser/:id"
            element={
              <OnlyAdmin>
                <EditUser />
              </OnlyAdmin>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
