import React from "react";
import SidebarSetting from "../SidebarSetting/SidebarSetting";
import Navbar from "../Navbar/Navbar";
import UserForm from "./UserForm";

const CreateUser = () => {
  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      <div>
        <Navbar page="Settings" />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="lg:w-[30%] md:w-[39%] scrollbar-hide overflow-y-auto md:block hidden bg-white shadow-md">
          <SidebarSetting />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-8 relative overflow-y-auto scrollbar-hide">
          <UserForm />
        </div>
      </div>
    </div>
    //
  );
};

export default CreateUser;
