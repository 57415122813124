import React from "react";
import mainLogo from "../../Asset/Mask group.png";
import Logo from "../../Asset/Logo 2 1.png";
import { NavLink } from "react-router-dom";
import useRedirectLoginUser from "../../CustomHook/useRedirectLoginUser";

const LandingPage = () => {
  useRedirectLoginUser("/items");
  return (
    <div className="flex flex-col justify-center items-center md:flex-row min-h-screen bg-[#0D868C]">
      <div className="w-full md:w-1/2 bg-[ #0D868C] flex flex-col justify-center items-center p-8 md:p-12">
        <img src={mainLogo} alt="" />
      </div>
      <hr className="hidden md:block  h-[265px] border-l-2 border-white my-auto" />
      <div className="w-full md:w-1/2 flex flex-col justify-center items-center p-8 md:p-12 mx-auto space-y-6  ">
        {/* <NavLink to={"/register"}>
          <button className="w-[268px] py-3 px-4 uppercase bg-white text-[#0D868C] rounded-full text-[1.25rem] font-inter font-inter-400 ">
            REGISTER
          </button>
        </NavLink> */}
        <NavLink to={"/login"}>
          <button className="w-[268px] py-3 px-4 uppercase bg-white text-[#0D868C] rounded-full text-[1.25rem] font-inter font-inter-400 ">
            SIGN IN
          </button>
        </NavLink>
      </div>
    </div>
  );
};

export default LandingPage;
