import axios from "axios";
import { BACKEND_BASEURL } from "../../../db/baseUrl";
export const API_CATEGORIES = `${BACKEND_BASEURL}api/categories/`;

const createCategory = async (categoryData) => {
  console.log(categoryData);
  const response = await axios.post(
    API_CATEGORIES + "createCategory",
    categoryData
  );
  return response.data;
};

const getCategories = async () => {
  const response = await axios.get(API_CATEGORIES + "getCategories");
  return response.data;
};

const updateCategory = async (categoryData) => {
  const response = await axios.patch(
    API_CATEGORIES + "updateCategory",
    categoryData
  );
  return response.data;
};
const CategoryServices = {
  createCategory,
  getCategories,
  updateCategory,
};
export default CategoryServices;
