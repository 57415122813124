import React from "react";

const DeliveryOption = ({
  selectedDeliveryOption,
  setSelectedDeliveryOption,
  counterValue,
  setCounterValue,
}) => {
  // const [selectedDeliveryOption, setSelectedDeliveryOption] = useState("Delivery Charges");
  // const [counterValue, setCounterValue] = useState(3.5);

  // Handle radio option change
  const handleOptionChange = (e) => {
    setSelectedDeliveryOption(e.target.value);
  };

  // Handle increment for counter
  const handleIncrement = () => {
    setCounterValue((prevValue) => parseFloat((prevValue + 0.5).toFixed(2)));
  };

  // Handle decrement for counter
  const handleDecrement = () => {
    if (counterValue > 3.5) {
      setCounterValue((prevValue) =>
        prevValue > 0.5 ? parseFloat((prevValue - 0.5).toFixed(2)) : prevValue
      );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center bg-[#F1F1F1]">
      <div className="flex gap-6">
        {/* Free Delivery Radio */}
        <div className="flex items-center">
          <input
            type="radio"
            id="freeDelivery"
            name="deliveryOption"
            value="Free Delivery"
            checked={selectedDeliveryOption === "Free Delivery"}
            onChange={handleOptionChange}
            className="hidden"
          />
          <label
            htmlFor="freeDelivery"
            className={`cursor-pointer text-[1rem] p-3 rounded-md transition-all duration-300 ${
              selectedDeliveryOption === "Free Delivery"
                ? "bg-[#0D868C] text-white"
                : "bg-gray-200"
            }`}
          >
            Free Delivery
          </label>
        </div>

        {/* Counter Radio */}
        <div className="flex items-center">
          <input
            type="radio"
            id="deliveryCharges"
            name="deliveryOption"
            value="Delivery Charges"
            checked={selectedDeliveryOption === "Delivery Charges"}
            onChange={handleOptionChange}
            className="hidden"
          />
          <label
            htmlFor="deliveryCharges"
            className={`cursor-pointer text-[1rem] p-3 rounded-md transition-all duration-300 ${
              selectedDeliveryOption === "Delivery Charges"
                ? "bg-[#0D868C] text-white"
                : "bg-gray-200"
            }`}
          >
            Delivery Charges
          </label>
        </div>
      </div>

      {/* Counter Controls */}
      {selectedDeliveryOption === "Delivery Charges" && (
        <div className="mt-6 flex items-center gap-4">
          <button
            onClick={handleDecrement}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-full"
          >
            -
          </button>
          <span className="text-xl font-semibold">{`€${counterValue.toFixed(
            2
          )}`}</span>
          <button
            onClick={handleIncrement}
            className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded-full"
          >
            +
          </button>
        </div>
      )}
    </div>
  );
};

export default DeliveryOption;
