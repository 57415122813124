import React, { useRef } from "react";
import "../../App.css";
import { NavLink } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";
import EditItemForm from "./EditItemFrom";

const EditItem = () => {
  useRedirectLogoutUser("/login");

  const formRef = useRef();

  const handleSubmit = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  return (
    <div>
      <nav className="bg-[#0D868C] shadow-md p-4 flex justify-between items-center sticky top-0 z-20 ">
        <NavLink to={"/items"}>
          <button className="text-xl font-inter-600 text-white flex items-center gap-3 ml-3">
            <IoIosArrowBack className="size-6" /> Update Items
          </button>
        </NavLink>
        <NavLink onClick={handleSubmit}>
          <button className="text-xl font-inter-600 text-white flex items-center uppercase mr-3">
            Update
          </button>
        </NavLink>
      </nav>

      <div>
        <EditItemForm ref={formRef} />
      </div>
    </div>
  );
};

export default EditItem;
