import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar/Navbar";
// import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";
import CategorySlider from "../../component/categorySlider/CategorySlider";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";

import itemLogo from "../../Asset/item logo.png";
import Order from "../../component/Order/Order";
import { NavLink, useParams } from "react-router-dom";
import { BACKEND_BASEURL } from "../../db/baseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../Loader/LoadingSpinner";

const ItemByCategory = ({
  order,
  setOrder,
  option,
  setOption,
  deliveryDatas,
}) => {
  useRedirectLogoutUser("/login");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const { category } = useParams();

  const getItemByCategory = async (category) => {
    try {
      setLoading(true);
      const response = await axios.get(`${BACKEND_BASEURL}api/${category}`);

      setData(response.data);
      // console.log(response.data);
      setLoading(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setData("");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (category) {
      getItemByCategory(category);
    }
  }, [category]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="flex flex-col h-screen bg-[#F1F1F1]">
        {/* Navbar */}

        <Navbar page="Order" />

        <CategorySlider />
        <div className="flex overflow-hidden h-full">
          <div className="flex flex-1 flex-col p-8 overflow-y-auto scrollbar-hide">
            {data ? (
              <div className="mx-auto">
                <div className="flex  justify-start  gap-4 w-full flex-wrap">
                  {data.map((item, i) => (
                    <NavLink
                      to={
                        item.category.includes("deal") ||
                        item.category.includes("meal")
                          ? `/query/dealItem/${item._id}`
                          : `/query/item/${item._id}`
                      }
                      key={i}
                      className="lg:w-[9rem] w-[7.5rem] lg:h-[9rem] h-[7.5rem] bg-[#A9A9A9]/20 rounded-3xl overflow-hidden flex items-center justify-center relative"
                    >
                      {item.itemImage ? (
                        <img
                          src={item.itemImage}
                          alt={item.name}
                          className="w-full h-full object-cover"
                        />
                      ) : (
                        <span className="text-gray-500">No Image</span>
                      )}
                      <p className="absolute bg-[#20BEC6CC]/60 w-full text-center text-[14px] top-[40%] font-extrabold text-white py-1 capitalize">
                        {item.name}
                      </p>
                    </NavLink>
                  ))}
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <div className="w-[19rem] lg:w-[30rem]  relative  lg:h-[24rem] h-[16rem] bg-[#A9A9A9] rounded-[2.4rem] text-white flex flex-col justify-center items-center ">
                  <img
                    src={itemLogo}
                    className="mb-4 lg:w-[10rem] w-[5rem]"
                    alt="Logo"
                  />
                  <p className="text-[.9rem] lg:text-[1.2rem] font-inter-700 text-center">
                    You have no items with {category} yet
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-2 lg:w-[35%] w-[40%] bg-white">
            <Order
              order={order}
              setOrder={setOrder}
              option={option}
              setOption={setOption}
              deliveryData={deliveryDatas}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemByCategory;
