import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import SauceServices from "./SauceServices";

const initialState = {
  sauce: null,
  sauces: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// createsauce thunkApi
export const createSauce = createAsyncThunk(
  "sauce/createSauce",
  async (sauceData, thunkAPI) => {
    try {
      return await SauceServices.createSauce(sauceData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getSauces = createAsyncThunk(
  "sauce/getSauces",
  async (_, thunkAPI) => {
    try {
      return await SauceServices.getSauces();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateSauce = createAsyncThunk(
  "sauce/updateSauce",
  async (sauceData, thunkAPI) => {
    try {
      return await SauceServices.updateSauce(sauceData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteSauce = createAsyncThunk(
  "sauce/deleteSauce",
  async (id, thunkAPI) => {
    try {
      return await SauceServices.deleteSauce(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const sauceSlice = createSlice({
  name: "sauce",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create sauce
      .addCase(createSauce.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createSauce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sauce = action.payload;
        console.log(action.payload);
        toast.success("Create new sauce successfully");
      })

      .addCase(createSauce.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.sauce = null;
        toast.error(action.payload);
      })

      .addCase(getSauces.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getSauces.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sauces = action.payload.data;
      })

      .addCase(getSauces.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.sauces = null;
        toast.error(action.payload);
      })

      .addCase(updateSauce.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateSauce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sauce = action.payload.data;
        console.log(action.payload);
      })

      .addCase(updateSauce.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.sauce = null;
        toast.error(action.payload);
      })
      .addCase(deleteSauce.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteSauce.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sauce = null;
        toast.success(action.payload);
        console.log(action.payload);
      })

      .addCase(deleteSauce.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.sauce = null;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = sauceSlice.actions;
export const selectSauce = (state) => state.sauce.sauce;
export const selectSauces = (state) => state.sauce.sauces;
export const selectSauceSuccess = (state) => state.sauce.isSuccess;

export default sauceSlice.reducer;
