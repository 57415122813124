import React, { useEffect } from "react";
// import { FaPlus } from "react-icons/fa";
import Navbar from "../../component/Navbar/Navbar";
import Sidebar from "../../component/sidebar/Sidebar";
import { sidebarSections } from "../Items/Items";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";
import CreateBase from "../../component/CreateBase/CreateBase";
import BaseLogo from "../../Asset/base logo.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBase,
  getBases,
  selectBases,
  selectBaseSuccess,
} from "../../redux/features/base/BaseSlice";
import EditBase from "../../component/EditBase/EditBase";
import { MdDelete } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";

const Base = () => {
  useRedirectLogoutUser("/login");
  const bases = useSelector(selectBases);
  const isSuccess = useSelector(selectBaseSuccess);
  const dispatch = useDispatch();

  const confirmDelete = (id, name) => {
    if (!id || !name) {
      toast.error("ID or Name is undefined");
      return;
    }
    confirmAlert({
      title: "Delete This Base",
      message: `Are you sure to delete this ${name}?`,
      buttons: [
        {
          label: "Delete",
          onClick: () => removeUser(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };
  const removeUser = async (id) => {
    await dispatch(deleteBase(id));
    if (isSuccess) {
      dispatch(getBases());
    }
  };

  useEffect(() => {
    dispatch(getBases());
  }, [dispatch]);

  const sidebarSection = sidebarSections;
  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      {/* Navbar */}

      <Navbar page="Bases" />

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="w-[30%] md:block hidden bg-white shadow-md">
          <Sidebar sidebarSections={sidebarSection} />
        </div>

        {/* Main Content */}
        <div className="flex-1 flex justify-center p-8 overflow-y-auto">
          {bases ? (
            <div className="mx-auto">
              <div className="flex  justify-start  gap-4 w-full flex-wrap">
                {bases.map((base, i) => (
                  <div
                    key={i}
                    className="w-[9rem] h-[9rem] bg-slate-300 rounded-3xl overflow-hidden flex items-center justify-center relative"
                  >
                    {base.image ? (
                      <img
                        src={base.image}
                        alt={`Category ${i + 1}`}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-gray-500">No Image</span>
                    )}
                    <button
                      onClick={() => confirmDelete(base._id, base.name)}
                      className="absolute text-white bg-[#fa3426cc] rounded-lg p-[4px] top-3 left-3"
                    >
                      <MdDelete className="size-4" />
                    </button>

                    <EditBase id={base._id} />
                    <p className="absolute bg-[#20BEC6CC] w-full text-center text-[14px] bottom-8 font-semibold text-white py-1 capitalize">
                      {base.name}
                    </p>
                  </div>
                ))}
              </div>
              <CreateBase />
            </div>
          ) : (
            <div className="flex items-center">
              <div className="w-[30rem] lg:w-[38rem]  relative  lg:h-[30rem] h-[23rem] bg-[#A9A9A9] rounded-[2.4rem] text-white flex flex-col justify-center items-center ">
                <img
                  src={BaseLogo}
                  className="size-16 md:size-32 mb-2"
                  alt="Logo"
                />

                <p className="text-[1rem] md:text-[1.5rem] font-inter-700 text-center">
                  You have no base yet
                </p>
                <p className="text-[.8rem] md:text-[1rem] font-inter-400 text-center">
                  To add a base, press the (+) button
                </p>
                <CreateBase />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Base;
