import React from "react";
import SidebarSetting from "../SidebarSetting/SidebarSetting";
import Navbar from "../Navbar/Navbar";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  changePassword,
  logoutUser,
  selectSuccess,
} from "../../redux/features/auth/AuthSlice";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    watch,
  } = useForm();

  const dispatch = useDispatch();
  const isSuccess = useSelector(selectSuccess);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    if (data.newPassword !== data.confirmPassword) {
      setError("confirmPassword", {
        type: "manual",
        message: "Passwords do not match",
      });
      return;
    }
    const userData = {
      oldPassword: data.password,
      password: data.newPassword,
    };
    await dispatch(changePassword(userData));

    if (isSuccess) {
      toast.success("Your Password has been changed,please login again ");
      await dispatch(logoutUser());
      if (isSuccess) {
        navigate("/login");
      }
    }
  };

  const newPassword = watch("newPassword");
  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      <div>
        <Navbar page="Settings" />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="lg:w-[30%] md:w-[39%]  md:block hidden scrollbar-hide overflow-y-auto bg-white shadow-md">
          <SidebarSetting />
        </div>

        <div className="flex-1 p-8 flex items-center justify-center overflow-y-auto scrollbar-hide">
          <div className=" flex items-center   ">
            <div className="w-full ">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col lg:w-[35rem] w-[25rem]"
              >
                {/* Current Password */}
                <div className="mb-6 flex items-center justify-end  gap-4">
                  <label className=" flex-2 text-gray-700 font-semibold md:text-[1rem] text-[.75rem]  text-end">
                    Current Password:
                  </label>
                  <div>
                    <input
                      type="password"
                      placeholder="Enter current password"
                      className="lg:w-[20rem] w-[15rem] flex-1 px-4 py-3 border-2 bg-transparent border-[#D4D4D4] rounded-lg"
                      {...register("password", {
                        required: "Current password is required",
                      })}
                    />
                    {errors.password && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* New Password */}
                <div className="mb-6 flex items-center  justify-end gap-4">
                  <label className="flex-2 text-gray-700 font-semibold md:text-[1rem] text-[.75rem]  text-end">
                    New Password:
                  </label>
                  <div className="">
                    <input
                      type="password"
                      placeholder="Enter new password"
                      className="lg:w-[20rem] w-[15rem] flex-1 px-4 py-3 border-2 bg-transparent border-[#D4D4D4] rounded-lg"
                      {...register("newPassword", {
                        required: "New password is required",
                        minLength: {
                          value: 8,
                          message:
                            "Password must be at least 8 characters long",
                        },
                      })}
                    />
                    {errors.newPassword && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.newPassword.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Confirm Password */}
                <div className="mb-6 flex items-center justify-end gap-4">
                  <label className=" flex-1 text-gray-700 font-semibold md:text-[1rem] text-[.75rem]  text-end">
                    Confirm Password:
                  </label>
                  <div>
                    <input
                      type="password"
                      placeholder="Confirm new password"
                      className="lg:w-[20rem] w-[15rem] flex-1 px-4 py-3 border-2 bg-transparent border-[#D4D4D4] rounded-lg"
                      {...register("confirmPassword", {
                        required: "Confirm password is required",
                        validate: (value) =>
                          value === newPassword || "Passwords do not match",
                      })}
                    />
                    {errors.confirmPassword && (
                      <p className="text-red-500 text-sm mt-1">
                        {errors.confirmPassword.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* Submit Button */}
                <div className="mt-32 w-full">
                  <button
                    type="submit"
                    className="bg-[#0D868C] text-white px-6 py-2 rounded-full w-full"
                  >
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    //
  );
};

export default ChangePassword;
