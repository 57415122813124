import React, { useEffect } from "react";
import Navbar from "../../component/Navbar/Navbar";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";
import {
  getCategories,
  selectCategories,
} from "../../redux/features/category/CategorySlice";
import { useDispatch, useSelector } from "react-redux";
import CategoryLogo from "../../Asset/Category logo.png";
import Order from "../../component/Order/Order";
import { NavLink } from "react-router-dom";

const Sale = ({ order, setOrder, option, setOption, deliveryDatas }) => {
  useRedirectLogoutUser("/login");
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      {/* Navbar */}

      <Navbar page="Order" />

      <div className="flex overflow-hidden  h-full">
        <div className="flex flex-1 flex-col p-8 overflow-y-auto scrollbar-hide ">
          {categories ? (
            <div className="mx-auto">
              <div className="flex justify-start gap-4 w-full flex-wrap">
                {categories.map((category, i) => (
                  <NavLink
                    to={`/query/${category.name}`}
                    key={i}
                    className="lg:w-[9rem] w-[7.5rem] lg:h-[9rem] h-[7.5rem] bg-slate-300 rounded-3xl overflow-hidden flex items-center justify-center relative"
                  >
                    {category.image ? (
                      <img
                        src={category.image}
                        alt={`Category ${i + 1}`}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <span className="text-gray-500">No Image</span>
                    )}
                    <p className="absolute bg-[#20BEC6CC] w-full text-center text-[14px] bottom-8 font-semibold text-white py-1 capitalize">
                      {category.name}
                    </p>
                  </NavLink>
                ))}
              </div>
            </div>
          ) : (
            <div className="flex items-center">
              <div className="w-[30rem] lg:w-[38rem]  relative  lg:h-[30rem] h-[23rem] bg-[#A9A9A9] rounded-[2.4rem] text-white flex flex-col justify-center items-center ">
                <img
                  src={CategoryLogo}
                  className="size-16 md:size-32 mb-2"
                  alt="Logo"
                />
                <p className="text-[1rem] md:text-[1.5rem] font-inter-700 text-center">
                  You have no categories yet
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-2 lg:w-[35%] w-[40%] bg-white">
          <Order
            order={order}
            setOrder={setOrder}
            option={option}
            setOption={setOption}
            deliveryData={deliveryDatas}
          />
        </div>
      </div>
    </div>
  );
};

export default Sale;
