import React, { useEffect, useState } from "react";
import { BiPencil } from "react-icons/bi";
import { FaFolderOpen, FaImage } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { TiCamera } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import {
  getToppings,
  selectToppings,
  selectToppingSuccess,
  updateTopping,
} from "../../redux/features/topping/ToppingSlice";

const EditTopping = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    toppingName: "",
    price: 0,
    // variantSize: "",
    // variantPrice: "",
  });
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});
  const toppings = useSelector(selectToppings);
  const topping = toppings.find((topping) => topping._id === id);
  const dispatch = useDispatch();
  const isSuccess = useSelector(selectToppingSuccess);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    // setPreview(null);
    // setFormData("");
    setImage(null);
    setErrors({});
  };

  useEffect(() => {
    if (topping) {
      setFormData({
        toppingName: topping.name,
        price: topping.price,
        // variantSize: topping.variants.sizes.join(",") || "",
        // variantPrice: topping.variants.prices.join(",") || "",
      });
      setPreview(topping.toppingImage);
    }
  }, [topping]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  // let sizeArray = [];
  // let priceArray = [];

  const validateForm = () => {
    const newErrors = {};
    if (!formData.toppingName) newErrors.toppingName = "Name is required";
    if (!formData.price) newErrors.price = "Price is required";
    // if (!formData.variantSize)
    //   newErrors.variantSize = "variant size is required";
    // if (!formData.variantPrice)
    //   newErrors.variantPrice = "variant price is required";
    // if (formData.variantSize && formData.variantPrice) {
    //   sizeArray = formData.variantSize.split(",").map((s) => s.trim());
    //   priceArray = formData.variantPrice.split(",").map((p) => p.trim());

    //   if (sizeArray.length !== priceArray.length) {
    //     newErrors.variantMismatch =
    //       "Sizes and prices must have the same length.";
    //   }
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    console.log(formData);
    const updateToppingData = new FormData();
    updateToppingData.append("id", topping._id);
    updateToppingData.append("name", formData.toppingName);
    // updateToppingData.append("sizes", sizeArray);
    updateToppingData.append("price", formData.price);
    if (image) {
      updateToppingData.append("toppingImage", image);
    }
    // Log FormData entries to verify
    // for (let [key, value] of updateToppingData.entries()) {
    //   console.log(`${key}:`, value);
    // }
    await dispatch(
      updateTopping({ id: topping._id, data: updateToppingData })
    ).unwrap();
    if (isSuccess) {
      await dispatch(getToppings());
      handleClose();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      setImage(file);
    }
  };

  return (
    <div>
      {/* Button to open the modal */}
      <button
        onClick={handleOpen}
        className="absolute text-white bg-[#20BEC6CC] rounded-lg p-[4px] top-3 right-3"
      >
        <BiPencil className="size-4" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 overflow-y-scroll ">
          <div className="bg-white shadow-xl rounded-[10px] md:w-[650px] w-[90%] mx-auto p-8 space-y-6 mt-32 lg:mt-0">
            <div className="flex justify-between items-center border-b-2 border-[#DEDEDE] pb-4 mb-4">
              <h1 className="md:text-[1.75rem] text-[1rem] font-bold text-[#0D868C]">
                Update Topping
              </h1>
              <button type="button" onClick={handleClose}>
                <FiX className="md:size-8 size-5 text-[#0D868C]" />
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              {/* name */}
              <div className="mb-2 flex flex-col items-start">
                <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
                  Topping Name
                </label>
                <input
                  type="text"
                  name="toppingName"
                  value={formData.toppingName}
                  onChange={handleInputChange}
                  placeholder="Name"
                  className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C]"
                />
                {errors.toppingName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.toppingName}
                  </p>
                )}
              </div>
              {/* size */}
              {/* <div className="mb-2 flex flex-col items-start mt-2">
                <label className=" text-[.75rem] font-semibold text-[#7D7D7D]">
                  Size
                </label>
                <input
                  type="text"
                  name="variantSize"
                  value={formData.variantSize}
                  onChange={handleInputChange}
                  placeholder="xs,sm,md etc."
                  className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] "
                />
                {errors.variantSize && (
                  <span className="text-red-500 text-sm">
                    {errors.variantSize}
                  </span>
                )}
              </div> */}
              {/* price */}
              <div className="mb-4 flex flex-col items-start mt-2">
                <label className=" text-[.75rem] font-semibold text-[#7D7D7D]">
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  min={0}
                  step={0.1}
                  onWheel={(e) => e.target.blur()}
                  value={formData.price}
                  onChange={handleInputChange}
                  placeholder=".50 etc."
                  className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] "
                />
                {errors.price && (
                  <span className="text-red-500 text-sm">{errors.price}</span>
                )}
                {/* {errors.variantMismatch && (
                  <span className="text-red-500 text-sm">
                    {errors.variantMismatch}
                  </span>
                )} */}
              </div>

              <div className="mb-2 flex flex-col justify-start items-start">
                <h2 className="text-[12px] text-[#7D7D7D] font-semibold mb-2">
                  Representation on POS
                </h2>
                <div className="mt-2 flex md:flex-row flex-col gap-4 items-start md:space-x-4">
                  <div className="w-28 h-28 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center cursor-pointer hover:border-blue-500 transition duration-300">
                    {preview ? (
                      <img
                        src={preview}
                        alt="Preview"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <FaImage className="text-gray-400 text-3xl" />
                    )}
                  </div>

                  <div className="flex flex-col justify-center gap-4">
                    <div className="relative inline-block md:w-[13rem] w-[10rem]">
                      <label
                        htmlFor="file-upload"
                        className="uppercase border-2 flex items-center justify-center border-[#0D868C] text-[#0D868C] md:text-[1rem] text-[.7rem] px-4 py-2 rounded-full cursor-pointer text-center"
                      >
                        <FaFolderOpen className="mr-2 size-6 text-[#0D868C]" />
                        Choose Photo
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </div>

                    <div className="relative inline-block md:w-[13rem] w-[10rem]">
                      <label
                        htmlFor="take-photo"
                        className="uppercase border-2 flex items-center justify-center border-[#0D868C] text-[#0D868C] md:text-[1rem] text-[.7rem] px-4 py-2 rounded-full cursor-pointer text-center"
                      >
                        <TiCamera className="mr-2 size-6 text-[#0D868C]" />
                        Take Photo
                      </label>
                      <input
                        id="take-photo"
                        type="file"
                        accept="image/*"
                        capture="environment"
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
                {errors.image && (
                  <p className="text-red-500 text-sm mt-1">{errors.image}</p>
                )}
              </div>

              <div className="text-center mt-8">
                <button
                  type="submit"
                  className="text-white bg-[#0D868C] w-[50%] py-3 rounded-full outline-none uppercase font-normal md:text-[1rem] text-[.75rem]"
                >
                  Update Topping
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditTopping;
