import axios from "axios";
import { BACKEND_BASEURL } from "../../../db/baseUrl";
export const API_TOPPINGS = `${BACKEND_BASEURL}api/toppings/`;

const createTopping = async (toppingData) => {
  console.log(toppingData);
  const response = await axios.post(
    API_TOPPINGS + "createTopping",
    toppingData
  );
  return response.data;
};

const getToppings = async () => {
  const response = await axios.get(API_TOPPINGS + "getToppings");
  return response.data;
};

const updateTopping = async ({ id, data }) => {
  const response = await axios.patch(
    `${API_TOPPINGS}updateTopping/${id}`,
    data
  );
  return response.data;
};
const deleteTopping = async (id) => {
  const response = await axios.delete(API_TOPPINGS + id);
  return response.data.message;
};
const ToppingServices = {
  createTopping,
  getToppings,
  updateTopping,
  deleteTopping,
};
export default ToppingServices;
