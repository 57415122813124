import React, { useEffect, useState } from "react";
import Navbar from "../../component/Navbar/Navbar";
import { RiTakeawayFill } from "react-icons/ri";
import { GiKnifeFork } from "react-icons/gi";
// import { IoEyeSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteOrder,
  getPendingOrders,
  selectPendingOrders,
  updateOrderStatus,
} from "../../redux/features/order/OrderSlice";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import PreviewOrder from "../../component/Preview Order/PreviewOrder";
import { useNavigate } from "react-router-dom";

const CompletingOrder = () => {
  const dispatch = useDispatch();
  const pendingOrders = useSelector(selectPendingOrders);
  const [delivery, setDelivery] = useState([]);
  const [dineInTakeAway, setDineInTakeAway] = useState([]);

  useEffect(() => {
    const filteredDelivery = pendingOrders?.filter(
      (order) => order.orderType.toLowerCase() === "delivery"
    );
    const filteredDineInTakeAway = pendingOrders?.filter(
      (order) => order.orderType.toLowerCase() === "dine in / take away"
    );

    setDelivery(filteredDelivery);
    setDineInTakeAway(filteredDineInTakeAway);
  }, [pendingOrders]);
  useEffect(() => {
    dispatch(getPendingOrders());
    const intervalId = setInterval(() => {
      dispatch(getPendingOrders());
    }, 30000);
    return () => clearInterval(intervalId);
  }, [dispatch]);

  // confirm complete the order
  const confirmComplete = (id, orderNo, status) => {
    if (!id || !orderNo || !status) {
      toast.error("ID or order no or status is undefined");
      return;
    }
    confirmAlert({
      title: "Complete This Order",
      message: `Are you sure to change status of this #${orderNo}?`,
      buttons: [
        {
          label: "Complete",
          onClick: () => complete(id, status),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };
  const complete = async (id, status) => {
    await dispatch(updateOrderStatus({ status, id }));
    dispatch(getPendingOrders());
  };

  // confirm delete the order
  const confirmCancel = (id, orderNo) => {
    if (!id || !orderNo) {
      toast.error("ID or order no is undefined");
      return;
    }
    confirmAlert({
      title: "Delete This Order",
      message: `Are you sure to delete this #${orderNo}?`,
      buttons: [
        {
          label: "Delete",
          onClick: () => cancel(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const cancel = async (id) => {
    await dispatch(deleteOrder(id));
    dispatch(getPendingOrders());
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (!pendingOrders) {
      navigate("/orderType");
    }
  }, [navigate, pendingOrders]);

  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      <div>
        <Navbar page="Completing Orders" />
      </div>
      <div className="flex gap-4 px-8 py-4   overflow-y-auto scrollbar-hide">
        <div className="flex-1 flex flex-col gap-4">
          <p className="flex justify-center items-center gap-2 bg-white w-full p-4 text-[#0D868C] lg:text-[1.25rem] text-[1rem] font-bold rounded-full">
            <RiTakeawayFill />
            Delivery
          </p>
          <div className="w-full h-[70vh] rounded-3xl bg-white flex flex-col justify-start gap-2 lg:p-4 py-2 pt-6 overflow-y-auto scrollbar-hide">
            {delivery?.length > 0 ? (
              delivery?.map((order, index) => (
                <div
                  key={index}
                  className="w-[90%] mx-auto flex justify-between items-center text-[#0D868C] lg:p-4 p-2 bg-[#A9A9A9]/20 rounded"
                >
                  <div className="lg:text-[1rem] text-[.7rem] font-bold">
                    #{order.orderNo}
                  </div>
                  <div className="flex items-center lg:gap-4 gap-2">
                    <div>
                      <PreviewOrder id={order._id} />
                    </div>
                    <div>
                      {order.orderStatus === "pending" ? (
                        <button
                          className="capitalize text-[#0D868C] py-2 lg:px-3 px-2 text-[.7rem] lg:text-[1rem] rounded-xl font-bold bg-[#fff]"
                          onClick={() =>
                            confirmComplete(order._id, order.orderNo, "pending")
                          }
                        >
                          dispatch
                        </button>
                      ) : (
                        <button
                          className="capitalize text-white py-2 lg:px-3 px-2 text-[.7rem] lg:text-[1rem] rounded-xl font-bold bg-[#0D868C]"
                          onClick={() =>
                            confirmComplete(
                              order._id,
                              order.orderNo,
                              "dispatch"
                            )
                          }
                        >
                          complete
                        </button>
                      )}
                    </div>
                    <div>
                      <button
                        className="capitalize text-white py-2 lg:px-3 px-2 text-[.7rem] lg:text-[1rem] rounded-xl font-bold bg-[#cf4c4c]"
                        onClick={() => confirmCancel(order._id, order.orderNo)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-start lg:text-[2rem] text-[1.5rem]  capitalize font-bold text-[#A9A9A9]">
                No delivery
              </div>
            )}
          </div>
        </div>
        <div className="flex-1 flex flex-col gap-4">
          <p className="flex justify-center items-center gap-2 bg-white w-full p-4 text-[#0D868C] lg:text-[1.25rem] text-[1rem] font-bold rounded-full">
            <GiKnifeFork />
            Dine In / Take Away
          </p>
          <div className="w-full h-[70vh] rounded-3xl bg-white flex flex-col gap-2 justify-start lg:p-4 py-2 pt-6 overflow-y-auto scrollbar-hide">
            {dineInTakeAway?.length > 0 ? (
              dineInTakeAway?.map((order, index) => (
                <div
                  key={index}
                  className="w-[90%] mx-auto  flex justify-between items-center text-[#0D868C] lg:p-4 p-2 bg-[#A9A9A9]/20 rounded"
                >
                  <div className="lg:text-[1rem] text-[.7rem] font-bold">
                    #{order.orderNo}
                  </div>
                  <div className="flex items-center lg:gap-4 gap-2">
                    <div>
                      <PreviewOrder id={order._id} />
                    </div>
                    <div>
                      <button
                        className="capitalize text-white py-2 lg:px-3 px-2 text-[.7rem] lg:text-[1rem] rounded-xl font-bold bg-[#0D868C]"
                        onClick={() =>
                          confirmComplete(order._id, order.orderNo, "pending")
                        }
                      >
                        Complete
                      </button>
                    </div>
                    <div>
                      <button
                        className="capitalize text-white py-2 lg:px-3 px-2 text-[.7rem] lg:text-[1rem] rounded-xl font-bold bg-[#cf4c4c]"
                        onClick={() => confirmCancel(order._id, order.orderNo)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-start lg:text-[2rem] text-[1.5rem]  capitalize font-bold text-[#A9A9A9]">
                No dine in / take away
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletingOrder;
