import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import ToppingServices from "./ToppingServices";

const initialState = {
  topping: null,
  toppings: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// createtopping thunkApi
export const createTopping = createAsyncThunk(
  "topping/createTopping",
  async (toppingData, thunkAPI) => {
    try {
      return await ToppingServices.createTopping(toppingData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getToppings = createAsyncThunk(
  "topping/getToppings",
  async (_, thunkAPI) => {
    try {
      return await ToppingServices.getToppings();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateTopping = createAsyncThunk(
  "topping/updateTopping",
  async ({ id, data }, thunkAPI) => {
    try {
      return await ToppingServices.updateTopping({ id, data });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTopping = createAsyncThunk(
  "topping/deleteTopping",
  async (id, thunkAPI) => {
    try {
      return await ToppingServices.deleteTopping(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const toppingSlice = createSlice({
  name: "topping",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create topping
      .addCase(createTopping.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createTopping.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.topping = action.payload;
        console.log(action.payload);
        toast.success("Create new topping successfully");
      })

      .addCase(createTopping.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.topping = null;
        toast.error(action.payload);
      })

      .addCase(getToppings.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getToppings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.toppings = action.payload.data;
      })

      .addCase(getToppings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.toppings = null;
        toast.error(action.payload);
      })

      .addCase(updateTopping.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateTopping.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.topping = action.payload.data;
        console.log(action.payload);
      })

      .addCase(updateTopping.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.topping = null;
        toast.error(action.payload);
      })
      .addCase(deleteTopping.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteTopping.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.topping = null;
        toast.success(action.payload);
        console.log(action.payload);
      })

      .addCase(deleteTopping.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.topping = null;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = toppingSlice.actions;
export const selectTopping = (state) => state.topping.topping;
export const selectToppings = (state) => state.topping.toppings;
export const selectToppingSuccess = (state) => state.topping.isSuccess;

export default toppingSlice.reducer;
