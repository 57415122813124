import React from "react";
import "./loader.css";
function LoadingSpinner() {
  return (
    <>
      <div className="loading">Loading&#8230;</div>
    </>
  );
}

export default LoadingSpinner;
