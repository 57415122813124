import React from "react";
// import profile from "../../Asset/profile.png";
import action from "../../Asset/action.png";
import { NavLink } from "react-router-dom";

const UsersTable = ({ role, email, date, name, profile, id }) => {
  const dateStr = date;
  const date1 = new Date(dateStr);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date1.toLocaleDateString("en-US", options);

  return (
    <>
      <tr className="border-b last:border-none mx-2 lg:h-[5rem] h-[4rem]">
        <td
          className={`p-3  lg:text-sm text-[12px] text-gray-700 whitespace-nowrap text-start   `}
        >
          <img src={profile} className="w-12 h-12 rounded-full" alt="" />
        </td>
        <td
          className={`p-3 lg:text-sm text-[12px] text-gray-700 whitespace-nowrap text-start flex flex-col items-start gap-2 `}
        >
          <h1>{name}</h1>
          <p>{role}</p>
        </td>
        <td
          className={`p-3 lg:text-sm text-[12px] text-gray-700 whitespace-nowrap text-start   `}
        >
          {email}
        </td>
        <td
          className={`p-3 lg:text-sm text-[12px] text-gray-700 whitespace-nowrap text-start   `}
        >
          {formattedDate}
        </td>

        <td className="p-3 lg:text-sm text-[12px] text-gray-700 whitespace-nowrap text-center">
          <button>
            <NavLink
              className={"text-center"}
              to={`/settings/users/editUser/${id}`}
            >
              <img src={action} alt="" />
            </NavLink>
          </button>
        </td>
      </tr>
    </>
  );
};

export default UsersTable;
