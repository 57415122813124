import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  selectCategories,
} from "../../redux/features/category/CategorySlice";
import { NavLink } from "react-router-dom";
import allItems from "../../Asset/all items.jpeg";

const CategorySlider = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);

  const containerRef = useRef(null);

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  // Mouse and Touch Scroll Handlers
  const startScroll = (event) => {
    const container = containerRef.current;
    container.dataset.isDown = true;
    const pageX = event.type.includes("touch")
      ? event.touches[0].pageX
      : event.pageX;
    container.dataset.startX = pageX - container.offsetLeft;
    container.dataset.scrollLeft = container.scrollLeft;
  };

  const moveScroll = (event) => {
    const container = containerRef.current;
    // event.preventDefault();
    if (container.dataset.isDown === "true") {
      const pageX = event.type.includes("touch")
        ? event.touches[0].pageX
        : event.pageX;
      const x = pageX - container.offsetLeft;
      const walk = (x - container.dataset.startX) * 2;
      container.scrollLeft = container.dataset.scrollLeft - walk;
    }
  };

  const endScroll = () => {
    containerRef.current.dataset.isDown = false;
  };

  return (
    <>
      {categories.length > 0 ? (
        <div className="bg-white w-full flex justify-center items-center relative">
          {/* Scrollable Menu Container */}
          <div
            ref={containerRef}
            className="flex overflow-hidden gap-4 items-center px-4 scrollbar-hide"
            onMouseDown={startScroll}
            onMouseMove={moveScroll}
            onMouseUp={endScroll}
            onMouseLeave={endScroll}
            onTouchStart={startScroll}
            onTouchMove={moveScroll}
            onTouchEnd={endScroll}
          >
            <div className="flex-shrink-0">
              <NavLink
                to={`/query/all`}
                className="flex items-center lg:gap-3 gap-2 bg-white lg:py-2 py-1 px-2 lg:px-4 my-4 rounded-full shadow-md blur-1"
              >
                <img
                  src={allItems}
                  alt="all items"
                  className="lg:h-10 h-8 lg:w-10 w-8 object-cover rounded-full"
                />
                <span className=" uppercase lg:text-sm text-xs font-semibold">
                  all
                </span>
              </NavLink>
            </div>

            {categories.map((category, index) => (
              <div key={index} className="flex-shrink-0">
                <NavLink
                  to={`/query/${category.name}`}
                  className="flex items-center gap-2 lg:gap-3 bg-white lg:py-2 py-1 px-2 lg:px-4 my-4 rounded-full shadow-md blur-1"
                >
                  <img
                    src={category.image}
                    alt={category.name}
                    className="lg:h-10 h-8 lg:w-10 w-8 object-cover rounded-full"
                  />
                  <span className=" uppercase text-sm font-semibold">
                    {category.name}
                  </span>
                </NavLink>
              </div>
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default CategorySlider;
