import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import itemServices from "./ItemServies";

const initialState = {
  item: null,
  items: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// createItem thunkApi
export const createItem = createAsyncThunk(
  "item/createItem",
  async (itemData, thunkAPI) => {
    try {
      return await itemServices.createItem(itemData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getItems = createAsyncThunk(
  "item/getItems",
  async (_, thunkAPI) => {
    try {
      return await itemServices.getItems();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateItem = createAsyncThunk(
  "item/updateItem",
  async ({ id, updateItemData }, thunkAPI) => {
    try {
      return await itemServices.updateItem({ id, updateItemData });
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteItem = createAsyncThunk(
  "item/deleteItem",
  async (id, thunkAPI) => {
    try {
      return await itemServices.deleteItem(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const itemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create item
      .addCase(createItem.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = action.payload;
        console.log(action.payload);
        toast.success("Create new item successfully");
      })

      .addCase(createItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.item = null;
        toast.error(action.payload);
      })

      .addCase(getItems.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getItems.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.items = action.payload.data;
      })

      .addCase(getItems.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.items = null;
        toast.error(action.payload);
      })

      .addCase(updateItem.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.item = action.payload.data;
        console.log(action.payload);
      })

      .addCase(updateItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload;
        state.item = null;
        toast.error(action.payload);
      })

      .addCase(deleteItem.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.item = null;
        console.log(action.payload);
        toast.success(action.payload);
      })

      .addCase(deleteItem.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = itemSlice.actions;
export const selectItem = (state) => state.item.item;
export const selectitems = (state) => state.item.items;
export const selectItemSuccess = (state) => state.item.isSuccess;

export default itemSlice.reducer;
