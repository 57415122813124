import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filteredReceipts: [],
  filteredItems: [],
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    filterReceipts(state, action) {
      const { search, orders } = action.payload;
      state.filteredReceipts = orders.filter((order) =>
        String(order.orderNo).toLowerCase().includes(search.toLowerCase())
      );
    },
    filterItems(state, action) {
      const { search, items } = action.payload;
      state.filteredItems = items.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      );
    },
  },
});

// Action and Selector Exports
export const { filterReceipts, filterItems } = filterSlice.actions;
export const selectFilteredReceipts = (state) => state.filter.filteredReceipts;
export const selectFilteredItems = (state) => state.filter.filteredItems;

export default filterSlice.reducer;
