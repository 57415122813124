import axios from "axios";
import { BACKEND_BASEURL } from "../../../db/baseUrl";
export const API_SAUCES = `${BACKEND_BASEURL}api/sauces/`;

const createSauce = async (sauceData) => {
  console.log(sauceData);
  const response = await axios.post(API_SAUCES + "createSauce", sauceData);
  return response.data;
};

const getSauces = async () => {
  const response = await axios.get(API_SAUCES + "getSauces");
  return response.data;
};

const updateSauce = async (sauceData) => {
  const response = await axios.patch(API_SAUCES + "updateSauce", sauceData);
  return response.data;
};
const deleteSauce = async (id) => {
  const response = await axios.delete(API_SAUCES + id);
  return response.data.message;
};
const SauceServices = {
  createSauce,
  getSauces,
  updateSauce,
  deleteSauce,
};
export default SauceServices;
