import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FaFolderOpen, FaImage, FaPlus } from "react-icons/fa";
import { TiCamera } from "react-icons/ti";
import ToggleSwitch from "./ToggleSwitch";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  selectCategories,
} from "../../redux/features/category/CategorySlice";
import {
  createItem,
  selectItemSuccess,
} from "../../redux/features/item/ItemSlice";
import { useNavigate } from "react-router-dom";
import { FiX } from "react-icons/fi";

const CreateItemForm = forwardRef((props, ref) => {
  const [formData, setFormData] = useState({
    itemName: "",
    description: "",
    category: "",
    price: 0,
    toppings: "",
    discount: 0,
    variantSize: "",
    variantPrice: "",
  });

  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const [addVariant, setAddVariant] = useState(false);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) {
      setErrors((prev) => ({ ...prev, [name]: undefined }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewImage(URL.createObjectURL(file));
    }
  };
  let sizeArray = [];
  let priceArray = [];
  let toppingArray = [];

  const validateForm = () => {
    const newErrors = {};
    if (!formData.itemName) newErrors.itemName = "Item name is required.";
    if (!formData.category) newErrors.category = "Item category is required.";
    if (formData.category === "pizza") {
      if (!formData.toppings) newErrors.toppings = "Item toppings is required.";
      if (formData.toppings) {
        toppingArray = formData.toppings.split(",").map((t) => t.trim());
      }
    }
    if (["deal", "meal"].includes(formData.category)) {
      validateFields();
    }
    if (addVariant) {
      if (!formData.variantSize) {
        newErrors.variantSize = "Variant size is required.";
      }
      if (!formData.variantPrice) {
        newErrors.variantPrice = "Variant price is required.";
      }

      // Check size-price length matching
      if (formData.variantSize && formData.variantPrice) {
        sizeArray = formData.variantSize.split(",").map((s) => s.trim());
        priceArray = formData.variantPrice.split(",").map((p) => p.trim());

        if (sizeArray.length !== priceArray.length) {
          newErrors.variantMismatch =
            "Sizes and prices must have the same length.";
        }
      }
      formData.price = 0;
    } else if (!formData.price) {
      newErrors.price = "Price is required.";
      sizeArray = [];
      priceArray = [];
    }

    // Image validation
    if (!image) {
      newErrors.image = "Image is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const dispatch = useDispatch();
  const isSuccess = useSelector(selectItemSuccess);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    submitForm: handleSubmit,
  }));

  const categories = useSelector(selectCategories);
  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  // deal category and quantity
  const [fields, setFields] = useState([{ quantity: 1, category: "" }]);
  const [error, setError] = useState([]);

  const handleAddField = () => {
    setFields([...fields, { quantity: 1, category: "" }]);
    setError([...error, {}]);
  };

  const handleRemoveField = (index) => {
    if (fields.length > 1) {
      setFields(fields.filter((_, i) => i !== index));
      setError(error.filter((_, i) => i !== index));
    }
  };

  const handleChange = (index, name, value) => {
    const updatedFields = [...fields];
    updatedFields[index][name] = value;
    setFields(updatedFields);

    // Clear errors when a field is updated
    const updatedErrors = [...error];
    updatedErrors[index] = { ...updatedErrors[index], [name]: "" };
    setError(updatedErrors);
  };

  const validateFields = () => {
    const newErrors = fields.map((field) => {
      const fieldErrors = {};
      if (!field.quantity || isNaN(field.quantity) || field.quantity <= 0) {
        fieldErrors.quantity = "Quantity must be a positive number.";
      }
      if (!field.category) {
        fieldErrors.category = "Category is required.";
      }
      return fieldErrors;
    });

    setError(newErrors);
    return newErrors.every((error) => Object.keys(error).length === 0);
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    if (validateForm()) {
      console.log("Form Data:", formData);
      console.log("Selected Image:", image);
      console.log("dealItem:", fields);
      const itemData = new FormData();
      itemData.append("name", formData.itemName);
      itemData.append("description", formData.description);
      itemData.append("category", formData.category);
      itemData.append("price", formData.price);
      itemData.append("discount", formData.discount);
      itemData.append("toppings", toppingArray);
      itemData.append("sizes", sizeArray);
      itemData.append("prices", priceArray);
      itemData.append("dealItem", JSON.stringify(fields));

      if (image) {
        itemData.append("itemImage", image);
      }
      // // Log FormData values
      for (let [key, value] of itemData.entries()) {
        console.log(`${key}:`, value);
      }
      await dispatch(createItem(itemData));
      if (isSuccess) {
        navigate("/items");
      }
    }
  };

  // const handle = (e) => {
  //   e.preventDefault();
  //   if (validateFields()) {
  //     console.log("Submitted Data:", fields);
  //   } else {
  //     console.log("Validation failed");
  //   }
  // };
  return (
    <div className="flex items-center w-full justify-center bg-[#F1F1F1] min-h-screen inner-container">
      <div className="bg-white py-8 md:px-16 px-8 my-16 rounded-lg shadow-md md:w-[75%] w-[85%]">
        <form
        // onSubmit={handleSubmit}
        >
          {/* Item Name */}
          <div className="mb-4 flex flex-col items-start">
            <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
              Item Name
            </label>
            <input
              type="text"
              name="itemName"
              value={formData.itemName}
              onChange={handleInputChange}
              placeholder="Name"
              className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C]"
            />
            {errors.itemName && (
              <span className="text-red-500 text-sm">{errors.itemName}</span>
            )}
          </div>

          {/* Description */}
          <div className="mb-4 flex flex-col items-start">
            <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
              Description
            </label>
            <input
              type="text"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
              placeholder="Description"
              className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C]"
            />
          </div>

          {/* Category */}
          <div className="mb-4 flex flex-col items-start">
            <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
              Category
            </label>
            <select
              name="category"
              value={formData.category}
              onChange={handleInputChange}
              className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] capitalize"
            >
              <option value="">Select category</option>
              {categories?.map((category, index) => (
                <option
                  key={index}
                  value={category.name.toLowerCase()}
                  className="capitalize"
                >
                  {category.name}
                </option>
              ))}
            </select>
            {errors.category && (
              <span className="text-red-500 text-sm">{errors.category}</span>
            )}
          </div>

          {/* Price */}
          <div className="mb-4 flex flex-col items-start">
            <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
              Price
            </label>
            <input
              type="number"
              name="price"
              min={0}
              step={0.1}
              onWheel={(e) => e.target.blur()}
              value={formData.price}
              onChange={handleInputChange}
              placeholder="Price"
              className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C]"
            />
            {errors.price && (
              <span className="text-red-500 text-sm">{errors.price}</span>
            )}
          </div>

          {/* Discount */}
          <div className="mb-4 flex flex-col items-start">
            <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
              Discount
            </label>
            <div className="flex w-full gap-3">
              <input
                type="number"
                min={0}
                step={0.1}
                onWheel={(e) => e.target.blur()}
                name="discount"
                value={formData.discount}
                onChange={handleInputChange}
                placeholder="0.00"
                className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C]"
              />

              {/* <div className="flex text-white gap-3">
                <div
                  className={`flex justify-center items-center ${
                    formData.discountType === "%"
                      ? "bg-[#0D868C]"
                      : "bg-[#D9D9D9]"
                  } w-[37px] h-[37px] rounded-[10px] cursor-pointer`}
                  onClick={() => handleDiscountTypeChange("%")}
                >
                  <FaPercent />
                </div>

                <div
                  className={`flex justify-center items-center ${
                    formData.discountType === "euro"
                      ? "bg-[#0D868C]"
                      : "bg-[#D9D9D9]"
                  } w-[37px] h-[37px] rounded-[10px] cursor-pointer`}
                  onClick={() => handleDiscountTypeChange("euro")}
                >
                  <MdEuro />
                </div>
              </div> */}
            </div>
          </div>
          {/* Toppings */}
          {formData.category === "pizza" && (
            <div className="mb-4 flex flex-col items-start mt-2">
              <label className=" text-[.75rem] font-semibold text-[#7D7D7D]">
                Toppings
              </label>
              <input
                type="text"
                name="toppings"
                value={formData.toppings}
                onChange={handleInputChange}
                placeholder="xs,sm,md etc."
                className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] "
              />
              {errors.toppings && (
                <span className="text-red-500 text-sm">{errors.toppings}</span>
              )}
            </div>
          )}

          {/* deal category and quantity */}
          {["deal", "meal"].includes(formData.category) && (
            <div className="mb-4 flex flex-col items-start mt-2 gap-3">
              <p className="text-[#0D868C] font-semibold my-4">
                Add Category that are includes in this deal or meal
              </p>

              {fields.map((field, index) => (
                <div className="w-full mb-4">
                  <div key={index} className="w-full flex items-end gap-4 ">
                    {/* Select Dropdown */}
                    <div className=" flex flex-col items-start flex-1">
                      <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
                        Category
                      </label>
                      <select
                        name="category"
                        value={field.category}
                        onChange={(e) =>
                          handleChange(index, "category", e.target.value)
                        }
                        className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] capitalize"
                      >
                        <option value="" disabled>
                          Select Category
                        </option>
                        {categories
                          ?.filter(
                            (category) =>
                              !["deal", "meal"].some((term) =>
                                category.name.toLowerCase().includes(term)
                              )
                          )
                          .map((category, index) => (
                            <option
                              key={index}
                              value={category.name.toLowerCase()}
                              className="capitalize"
                            >
                              {category.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    {/* Number Input */}

                    <div className="flex-1  ">
                      <div className="flex items-center justify-between gap-2">
                        <div className="flex-grow  flex flex-col items-start ">
                          <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
                            Quantity
                          </label>
                          <input
                            type="number"
                            name="quantity"
                            min={1}
                            onWheel={(e) => e.target.blur()}
                            value={field.quantity}
                            onChange={(e) =>
                              handleChange(index, "quantity", e.target.value)
                            }
                            placeholder="Enter the quantity"
                            className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C]"
                          />
                        </div>

                        {/* Remove Button */}
                        {fields.length > 1 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveField(index)}
                            className="bg-red-500 text-white rounded-md  flex-none"
                          >
                            <FiX className="size-8" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flex w-full gap-4">
                    {error[index]?.category && (
                      <span className="flex-1 text-red-500 text-sm">
                        {error[index].category}
                      </span>
                    )}
                    {error[index]?.quantity && (
                      <span className="flex-1 text-red-500 text-sm">
                        {error[index].quantity}
                      </span>
                    )}
                  </div>
                </div>
              ))}
              <button
                type="button"
                onClick={handleAddField}
                className="uppercase border-2 flex items-center justify-center border-[#0D868C] text-[#0D868C] px-4 py-2 rounded-full cursor-pointer "
              >
                <FaPlus className="size-5 mr-3" />
                Add Category
              </button>
              {/* <button onClick={handle}>ok</button> */}
            </div>
          )}

          <div className="text-[#DEDEDE] border-b-2 w-full my-10"></div>

          {/* Variants */}
          <div className="w-full text-left space-y-4">
            <div className="flex  justify-between">
              <h2 className="text-[.75rem] font-semibold text-[#7D7D7D]">
                Add Variants
              </h2>
              <ToggleSwitch
                isChecked={addVariant}
                onToggle={() => setAddVariant(!addVariant)}
                id="addVarient"
              />
              {/* <AddVariant /> */}
            </div>
            {addVariant && (
              <div className="mb-4 flex flex-col items-start mt-2">
                <label className=" text-[.75rem] font-semibold text-[#7D7D7D]">
                  Size
                </label>
                <input
                  type="text"
                  name="variantSize"
                  value={formData.variantSize}
                  onChange={handleInputChange}
                  placeholder="xs,sm,md etc."
                  className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] "
                />
                {errors.variantSize && (
                  <span className="text-red-500 text-sm">
                    {errors.variantSize}
                  </span>
                )}
              </div>
            )}
            {addVariant && (
              <div className="mb-4 flex flex-col items-start mt-2">
                <label className=" text-[.75rem] font-semibold text-[#7D7D7D]">
                  Price
                </label>
                <input
                  type="text"
                  name="variantPrice"
                  value={formData.variantPrice}
                  onChange={handleInputChange}
                  placeholder="7.8,10,12 etc."
                  className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] "
                />
                {errors.variantPrice && (
                  <span className="text-red-500 text-sm">
                    {errors.variantPrice}
                  </span>
                )}
              </div>
            )}
            {errors.variantMismatch && (
              <span className="text-red-500 text-sm">
                {errors.variantMismatch}
              </span>
            )}
          </div>

          <div className="text-[#DEDEDE] border-b-2 w-full my-10"></div>

          {/* Image Upload with Preview */}
          <div className="mb-4 flex flex-col justify-start items-start">
            <h2 className="text-[12px] text-[#7D7D7D] font-semibold mb-2">
              Representation on POS
            </h2>

            <div className="mt-2 flex md:flex-row flex-col gap-4 items-start md:space-x-4">
              <div className="w-28 h-28 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center">
                {previewImage ? (
                  <img
                    src={previewImage}
                    alt="Preview"
                    className="w-full h-full object-cover rounded-md"
                  />
                ) : (
                  <FaImage className="text-gray-400 text-3xl" />
                )}
              </div>

              <div className="flex flex-col justify-center gap-4">
                <label
                  htmlFor="file-upload"
                  className="uppercase border-2 flex items-center justify-center border-[#0D868C] text-[#0D868C] px-4 py-2 rounded-full cursor-pointer"
                >
                  <FaFolderOpen className="mr-2" />
                  Choose Photo
                </label>
                <input
                  id="file-upload"
                  type="file"
                  className="hidden"
                  onChange={handleImageChange}
                />

                <label
                  htmlFor="take-photo"
                  className="uppercase border-2 flex items-center justify-center border-[#0D868C] text-[#0D868C] px-4 py-2 rounded-full cursor-pointer"
                >
                  <TiCamera className="mr-2" />
                  Take Photo
                </label>
                <input
                  id="take-photo"
                  type="file"
                  accept="image/*"
                  capture="environment"
                  className="hidden"
                  onChange={handleImageChange}
                />
              </div>
            </div>
            {errors.image && (
              <span className="text-red-500 text-sm">{errors.image}</span>
            )}
          </div>
          {/* <button
            type="submit"
            className="w-full bg-[#0D868C] text-white py-2 rounded-md mt-4"
          >
            Submit
          </button> */}
        </form>
      </div>
    </div>
  );
});

export default CreateItemForm;
