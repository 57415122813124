import React, { useLayoutEffect, useState } from "react";
import { FaFolderOpen, FaImage } from "react-icons/fa";
import { FiX } from "react-icons/fi";
import { TiCamera } from "react-icons/ti";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategories,
  selectCategories,
  selectSuccess,
  updateCategory,
} from "../../redux/features/category/CategorySlice";
import { BiPencil } from "react-icons/bi";

const EditCategory = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState({});

  const categories = useSelector(selectCategories);
  const category = categories.find((category) => category._id === id);
  //   console.log(category);

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => {
    setIsOpen(false);
    // setPreview(null);
    // setCategoryName("");
    setImage(null);
    setErrors({});
  };

  useLayoutEffect(() => {
    if (category) {
      setCategoryName(category.name);
      // setImage(category.image);
      setPreview(category.image);
    }
  }, [category]);

  const validateForm = () => {
    const newErrors = {};
    if (!categoryName) newErrors.categoryName = "Name is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const dispatch = useDispatch();
  const isSuccess = useSelector(selectSuccess);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    console.log("Form Data:", { categoryName, image });
    const updateCategoryData = new FormData();
    updateCategoryData.append("name", categoryName);
    if (image) {
      updateCategoryData.append("categoryImage", image);
    }
    updateCategoryData.append("id", id);

    // Log FormData entries to verify
    // for (let [key, value] of updateCategoryData.entries()) {
    //   console.log(`${key}:`, value);
    // }
    await dispatch(updateCategory(updateCategoryData));

    if (isSuccess) {
      await dispatch(getCategories());
      handleClose();
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPreview(URL.createObjectURL(file));
      setImage(file);
    }
  };

  return (
    <div>
      {/* Button to open the modal */}
      <button
        onClick={handleOpen}
        className="absolute text-white bg-[#20BEC6CC] rounded-lg p-[4px] top-3 right-3"
      >
        <BiPencil className="size-4" />
      </button>

      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white shadow-xl rounded-[10px] md:w-[650px] w-[90%] mx-auto p-10 space-y-6">
            <div className="flex justify-between items-center border-b-2 border-[#DEDEDE] pb-4 mb-8">
              <h1 className="md:text-[1.75rem] text-[1rem] font-bold text-[#0D868C]">
                Update Category
              </h1>
              <button type="button" onClick={handleClose}>
                <FiX className="md:size-8 size-5 text-[#0D868C]" />
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="mb-6 flex flex-col items-start">
                <label className="text-[.75rem] font-semibold text-[#7D7D7D]">
                  Category Name
                </label>
                <input
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  type="text"
                  placeholder="Name"
                  className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C]"
                />
                {errors.categoryName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.categoryName}
                  </p>
                )}
              </div>

              <div className="mb-4 flex flex-col justify-start items-start">
                <h2 className="text-[12px] text-[#7D7D7D] font-semibold mb-2">
                  Representation on POS
                </h2>
                <div className="mt-2 flex md:flex-row flex-col gap-4 items-start md:space-x-4">
                  <div className="w-28 h-28 border-2 border-dashed border-gray-300 rounded-md flex items-center justify-center cursor-pointer hover:border-blue-500 transition duration-300">
                    {preview ? (
                      <img
                        src={preview}
                        alt="Preview"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <FaImage className="text-gray-400 text-3xl" />
                    )}
                  </div>

                  <div className="flex flex-col justify-center gap-4">
                    <div className="relative inline-block w-[13rem]">
                      <label
                        htmlFor="file-upload"
                        className="uppercase border-2 flex items-center justify-center border-[#0D868C] text-[#0D868C] text-[1rem] px-4 py-2 rounded-full cursor-pointer text-center"
                      >
                        <FaFolderOpen className="mr-2 size-6 text-[#0D868C]" />
                        Choose Photo
                      </label>
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/*"
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </div>

                    <div className="relative inline-block w-[13rem]">
                      <label
                        htmlFor="take-photo"
                        className="uppercase border-2 flex items-center justify-center border-[#0D868C] text-[#0D868C] text-[1rem] px-4 py-2 rounded-full cursor-pointer text-center"
                      >
                        <TiCamera className="mr-2 size-6 text-[#0D868C]" />
                        Take Photo
                      </label>
                      <input
                        id="take-photo"
                        type="file"
                        accept="image/*"
                        capture="environment"
                        className="hidden"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>
                </div>
                {errors.image && (
                  <p className="text-red-500 text-sm mt-1">{errors.image}</p>
                )}
              </div>

              <div className="text-center mt-8">
                <button
                  type="submit"
                  className="text-white bg-[#0D868C] w-[50%] py-3 rounded-full outline-none uppercase font-normal text-[1rem]"
                >
                  Update Category
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditCategory;
