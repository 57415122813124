import React from "react";

function Counter({ quantity, onQuantityChange, textSize, textColor }) {
  const handleIncrement = () => {
    onQuantityChange(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      onQuantityChange(quantity - 1);
    }
  };

  return (
    <div
      className={`flex items-center border rounded-full w-max ${
        textColor ? textColor : "text-[#A9A9A9]"
      } ${textColor ? "px-3 border-[#A9A9A9]" : ""}`}
    >
      <button
        className={`pl-2 ${
          textSize ? textSize : "text-[.8rem] lg:text-[1.25rem]"
        }  `}
        onClick={handleDecrement}
      >
        -
      </button>
      <span
        className={`mx-3 ${
          textSize ? "lg:text-[1.2rem] text-[.9rem]" : "text-[.7rem] "
        }  `}
      >
        {quantity}
      </span>
      <button
        className={`${
          textSize ? textSize : "text-[.8rem] lg:text-[1.25rem]"
        }  pr-2`}
        onClick={handleIncrement}
      >
        +
      </button>
    </div>
  );
}

export default Counter;
