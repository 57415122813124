import React from "react";
import SidebarNav from "../SidebarNav/SidebarNav";
// import { BiSearchAlt } from "react-icons/bi";

const Navbar = ({ page }) => {
  return (
    <div>
      <nav className="bg-[#0D868C] shadow-md p-4 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <SidebarNav />
          <h1 className="text-xl font-semibold text-white">{page}</h1>
        </div>
        {/* <BiSearchAlt className="text-white text-xl cursor-pointer" /> */}
      </nav>
    </div>
  );
};

export default Navbar;
