import React from "react";
import Navbar from "../../component/Navbar/Navbar";
import SidebarSetting from "../../component/SidebarSetting/SidebarSetting";
import Users from "../../component/users/Users";

const User = () => {
  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      <div>
        <Navbar page="Settings" />
      </div>
      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="lg:w-[30%] md:w-[39%]  md:block hidden bg-white shadow-md">
          <SidebarSetting />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-8 relative overflow-y-auto">
          <Users />

          {/* Add New user Button */}
        </div>
      </div>
    </div>
  );
};

export default User;
