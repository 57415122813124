import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import CheeseServices from "./CheeseServices";

const initialState = {
  cheese: null,
  cheeses: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// createCheese thunkApi
export const createCheese = createAsyncThunk(
  "cheese/createCheese",
  async (cheeseData, thunkAPI) => {
    try {
      return await CheeseServices.createCheese(cheeseData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getCheeses = createAsyncThunk(
  "cheese/getCheeses",
  async (_, thunkAPI) => {
    try {
      return await CheeseServices.getCheeses();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCheese = createAsyncThunk(
  "cheese/updateCheese",
  async (cheeseData, thunkAPI) => {
    try {
      return await CheeseServices.updateCheese(cheeseData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCheese = createAsyncThunk(
  "cheese/deleteCheese",
  async (id, thunkAPI) => {
    try {
      return await CheeseServices.deleteCheese(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const cheeseSlice = createSlice({
  name: "cheese",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create cheese
      .addCase(createCheese.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createCheese.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cheese = action.payload;
        console.log(action.payload);
        toast.success("Create new cheese successfully");
      })

      .addCase(createCheese.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.cheese = null;
        toast.error(action.payload);
      })

      .addCase(getCheeses.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getCheeses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cheeses = action.payload.data;
      })

      .addCase(getCheeses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.cheeses = null;
        toast.error(action.payload);
      })

      .addCase(updateCheese.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateCheese.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cheese = action.payload.data;
        console.log(action.payload);
      })

      .addCase(updateCheese.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.cheese = null;
        toast.error(action.payload);
      })
      .addCase(deleteCheese.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteCheese.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cheese = null;
        toast.success(action.payload);
        console.log(action.payload);
      })

      .addCase(deleteCheese.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.cheese = null;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = cheeseSlice.actions;
export const selectCheese = (state) => state.cheese.cheese;
export const selectCheeses = (state) => state.cheese.cheeses;
export const selectCheeseSuccess = (state) => state.cheese.isSuccess;

export default cheeseSlice.reducer;
