import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import CategorySlider from "../categorySlider/CategorySlider";
import Order from "../Order/Order";
import { useParams } from "react-router-dom";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";
import { BACKEND_BASEURL } from "../../db/baseUrl";
import axios from "axios";
import { toast } from "react-toastify";
import LoadingSpinner from "../Loader/LoadingSpinner";
import Counter from "../Order/Counter";

import DealItem from "./DealItem";

const DealItemDetail = ({
  order,
  setOrder,
  option,
  setOption,
  deliveryDatas,
}) => {
  useRedirectLogoutUser("/login");

  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [count, setCount] = useState(1);
  const [selectedPizza, setSelectedPizza] = useState("");
  const [selectedItems, setSelectedItems] = useState({});
  const [selectedTopping, setSelectedTopping] = useState([]);
  const [selectedBaseIndex, setSelectedBaseIndex] = useState("");
  const [selectedSauceIndex, setSelectedSauceIndex] = useState("");
  const [selectedCheeseIndex, setSelectedCheeseIndex] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [selectedAdd_Ons, setSelectedAdd_Ons] = useState({
    dip: null,
    can: null,
    bottle: null,
  });

  const getItemById = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get(`${BACKEND_BASEURL}api/item/${id}`);
      setData(response.data);
      // console.log(response.data);
      setLoading(false);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
      setData(null);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   console.log(selectedItems);
  // }, [selectedItems]);

  const applyDiscount = (price, discount) => {
    return discount > 0 ? (price - (price * discount) / 100).toFixed(2) : price;
  };
  useEffect(() => {
    if (id) {
      getItemById(id);
    }
  }, [id]);

  const orderData = {
    name: "",
    description: "",
    quantity: 0,
    price: 0,
    discountPrice: 0,
    discount: 0,
    dealItems: [
      {
        itemName: "",
        base: "",
        sauce: "",
        cheese: "",
        toppings: [],
        toppingPrice: [],
      },
    ],
    add_ons: [],
    size: "",
    base: "",
    sauce: "",
    cheese: "",
    toppings: [],
    toppingPrice: [],
  };

  const validation = () => {
    const newErrors = {};
    if (data.discount > 0) {
      setDiscountedPrice(applyDiscount(data.price, data.discount));
      orderData.discountPrice = Number(discountedPrice);
    }
    orderData.price = Number(data.price);
    if (data?.dealItem?.some((item) => item.category === "pizza")) {
      if (!selectedPizza) {
        newErrors.selectedPizza = "Please select the pizza";
        toast.error(newErrors.selectedPizza);
        return;
      }
      const dealItem = orderData.dealItems[0];

      dealItem.itemName = selectedPizza;

      const names = selectedTopping.map((item) => item.name);
      const prices = selectedTopping.map((item) => item.price);

      if (!selectedSauceIndex || !selectedBaseIndex || !selectedCheeseIndex) {
        newErrors.selectedBaseIndex =
          "Please select the base, sauce, and cheese";
        toast.error(newErrors.selectedBaseIndex);
        return false;
      } else {
        dealItem.base = selectedBaseIndex;
        dealItem.sauce = selectedSauceIndex;
        dealItem.cheese = selectedCheeseIndex;
      }
      dealItem.toppings = names;
      dealItem.toppingPrice = prices;
    }

    if (data?.dealItem?.some((item) => item.category !== "pizza")) {
      Object.entries(selectedItems).forEach(([key, value]) => {
        orderData.dealItems.push({
          itemName: value,
        });
      });
    }

    orderData.add_ons = Object.values(selectedAdd_Ons).filter(
      (item) => item !== null
    );
    orderData.name = data.name;
    orderData.description = data.description;
    orderData.quantity = count;
    orderData.discount = data.discount;
    return Object.keys(newErrors).length === 0;
  };

  const updateOrder = async (orderData) => {
    if (validation()) {
      await setOrder((prevOrders) => [...prevOrders, orderData]);
      console.log(orderData);
    }
  };

  // useEffect(() => {
  //   console.log(selectedAdd_Ons);
  // }, [selectedAdd_Ons]);

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="flex flex-col h-screen bg-[#F1F1F1]">
        <Navbar text="order" />
        <CategorySlider />
        <div className="flex overflow-hidden h-full">
          <div className="flex flex-1 flex-col lg:p-8 p-4  overflow-y-auto scrollbar-hide ">
            {data ? (
              <div className="flex flex-col h-full justify-between gap-4 w-full">
                {/* Main Content Section */}
                <div className="flex flex-row justify-between lg:gap-10 gap-6 w-full mx-auto">
                  {/* Image Section */}
                  <div className="flex-1">
                    <img
                      className="lg:w-[20rem] lg:h-[20rem] w-[13rem] h-[13rem] rounded-3xl bg-cover mx-auto bg-transparent"
                      src={data.itemImage}
                      alt={data.name || "Item Image"}
                    />
                  </div>

                  {/* Details Section */}
                  <div className="flex-1 flex flex-col lg:gap-6 gap-4">
                    {/* Item Name and Description */}
                    <div>
                      <h1 className="uppercase text-[#0D868C] text-wrap font-bold lg:text-2xl text-[1.25rem]">
                        {data.name}
                      </h1>
                      <p className="text-wrap text-[#A9A9A9] lg:text-[1rem] text-[.8rem] capitalize">
                        {data.description || "No description available."}
                      </p>
                    </div>

                    {/* Price Display */}
                    {data.price > 0 && (
                      <div className="flex gap-2 items-center">
                        <span className="font-bold text-[.8rem] lg:text-[1rem]">
                          Price :
                        </span>
                        <span
                          className={`text-[#1E1E1E] lg:text-[1rem] text-[.8rem] font-bold ${
                            data.discount > 0
                              ? "line-through text-gray-500"
                              : ""
                          }`}
                        >
                          € {data.price * count}
                        </span>
                        {data.discount > 0 && (
                          <span className="text-[#1E1E1E] lg:text-[1rem] text-[.8rem] font-bold">
                            € {applyDiscount(data.price, data.discount) * count}
                            <span className="ml-2">{data.discount}%</span>
                          </span>
                        )}
                      </div>
                    )}

                    {/* Counter Component */}
                    <Counter
                      quantity={count}
                      onQuantityChange={setCount}
                      textSize="text-[1rem] lg:text-[1.5rem]"
                      textColor="text-[#706e6e]"
                    />
                  </div>
                </div>
                <div>
                  <DealItem
                    data={data}
                    setSelectedPizza={setSelectedPizza}
                    selectedPizza={selectedPizza}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    selectedTopping={selectedTopping}
                    setSelectedTopping={setSelectedTopping}
                    selectedBaseIndex={selectedBaseIndex}
                    setSelectedBaseIndex={setSelectedBaseIndex}
                    selectedSauceIndex={selectedSauceIndex}
                    setSelectedSauceIndex={setSelectedSauceIndex}
                    selectedCheeseIndex={selectedCheeseIndex}
                    setSelectedCheeseIndex={setSelectedCheeseIndex}
                    selectedAdd_Ons={selectedAdd_Ons}
                    setSelectedAdd_Ons={setSelectedAdd_Ons}
                  />
                </div>
                <button
                  className=" uppercase sticky lg:bottom-0 lg:right-0 bottom-3 right-3  bg-[#0D868C] text-[white] lg:w-[10rem] ml-auto text-[.8rem] lg:text-[1rem] font-bold  w-[8rem] p-3 rounded-full"
                  onClick={() => updateOrder(orderData)}
                >
                  confirm
                </button>
              </div>
            ) : (
              <div>No data available.</div>
            )}
          </div>
          <div className="flex flex-2 lg:w-[35%] w-[40%] bg-white">
            <Order
              order={order}
              setOrder={setOrder}
              option={option}
              setOption={setOption}
              deliveryData={deliveryDatas}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DealItemDetail;
