import React, { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";

const OrderDiscount = ({
  setBillingDetails,
  billigDetails,
  totalAmount,
  amountType,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const [discount, setDiscount] = useState("");
  const [charged, setCharged] = useState("");
  const [returnAmount, setReturnAmount] = useState("");
  const [time, setTime] = useState("");
  const [additionalNote, setAdditionalNote] = useState("");
  const [errors, setErrors] = useState({});

  const togglePopup = () => {
    setShowPopup(!showPopup);
    setErrors({});
  };
  const validationForm = () => {
    const newErrors = {};
    if (Number(discount) > totalAmount) {
      newErrors.discount = `Discount is always less ${totalAmount}`;
      setErrors(newErrors);
      return;
    }
    if (!charged) {
      newErrors.charged = `charged Amount is requried`;
    }
    if (discount) {
      if (Number(charged) < totalAmount - Number(discount)) {
        newErrors.charged = `Charged Amount must be at least ${
          totalAmount - Number(discount)
        }`;
      }
    } else if (Number(charged) < totalAmount) {
      newErrors.charged = `Charged Amount must be at least ${totalAmount}`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    let calculatedReturnAmount = 0;

    if (Number(charged)) {
      const discountValue = Number(discount) >= 0.1 ? Number(discount) : 0;
      const minimumRequired = totalAmount - discountValue;

      if (Number(charged) >= minimumRequired) {
        calculatedReturnAmount = Number(charged) - minimumRequired;
      }
    }
    setReturnAmount(Math.max(calculatedReturnAmount.toFixed(2), 0));
  }, [discount, totalAmount, charged]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validationForm()) {
      await setBillingDetails({
        totalAmountDiscount: discount || 0,
        charged: charged,
        returnAmount: returnAmount || 0,
        AdditionalNote: additionalNote || "",
        deliverAt: time || "",
      });

      togglePopup();
    }
  };

  useEffect(() => {
    // console.log(billigDetails);
  }, [billigDetails]);

  return (
    <div>
      <button
        onClick={togglePopup}
        title="Apply Discount & Other Details"
        className="text-white bg-[#0D868C] lg:text-[.8rem] md:text-[.65rem]  font-bold px-3 py-2 rounded-full"
      >
        Billing Details
      </button>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-xl shadow-lg lg:w-[40%] w-[60%]">
            <div className="flex justify-between items-center mb-4">
              <h2 className="md:text-[1.2rem] text-[1rem] font-bold text-[#0D868C]">
                Billing Details
              </h2>
              <button type="button" onClick={togglePopup}>
                <FiX className="md:size-8 size-5 text-[#0D868C]" />
              </button>
            </div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-[.9rem]  text-[#000] bg-slate-300 w-max py-2 px-4 rounded-full">
                Total Amount: {totalAmount}
              </h2>
              <h2 className="text-[.9rem]  text-[#000] bg-slate-300 w-max py-2 px-4 rounded-full">
                Amount Type: {amountType}
              </h2>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="flex w-full justify-between gap-4 items-center">
                <div className="mb-0 flex-1">
                  <label
                    htmlFor="discount"
                    className="text-[.75rem] font-semibold text-[#7D7D7D]"
                  >
                    Discount
                  </label>
                  <input
                    type="number"
                    id="discount"
                    step={0.01}
                    min={0}
                    onWheel={(e) => e.target.blur()}
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] capitalize"
                    placeholder="Enter discounted Amount"
                  />
                </div>

                <div className="mb-0 flex-1">
                  <label
                    htmlFor="charged"
                    className="text-[.75rem] font-semibold text-[#7D7D7D]"
                  >
                    Charged Amount
                  </label>
                  <input
                    type="number"
                    id="charged"
                    step={0.01}
                    min={0}
                    onWheel={(e) => e.target.blur()}
                    value={charged}
                    onChange={(e) => setCharged(e.target.value)}
                    className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] capitalize"
                    placeholder="Enter charged amount"
                  />
                </div>
              </div>
              <div>
                <div className="flex w-full justify-between mb-3 gap-4 items-center">
                  <div className="flex-1 ">
                    {errors.discount && (
                      <span className="text-red-500 text-[10px] capitalize">
                        {errors.discount}
                      </span>
                    )}
                  </div>
                  <div className="flex-1 ">
                    {errors.charged && (
                      <span className="text-red-500 flex-1 capitalize text-[10px]">
                        {errors.charged}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex w-full justify-between gap-4 items-center">
                <div className="mb-4 flex-1">
                  <label
                    htmlFor="returnAmount"
                    className="text-[.75rem] font-semibold text-[#7D7D7D]"
                  >
                    Return Amount
                  </label>
                  <input
                    type="number"
                    id="returnAmount"
                    min={0}
                    step={0.01}
                    onWheel={(e) => e.target.blur()}
                    value={returnAmount}
                    onChange={(e) => setReturnAmount(e.target.value)}
                    className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] capitalize"
                    placeholder="Enter return amount"
                  />
                </div>

                <div className="mb-4 flex-1">
                  <label
                    htmlFor="time"
                    className="text-[.75rem] font-semibold text-[#7D7D7D]"
                  >
                    Time
                  </label>
                  <input
                    type="time"
                    id="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] capitalize"
                  />
                </div>
              </div>

              <div className="mb-4">
                <label
                  htmlFor="additionalNote"
                  className="text-[.75rem] font-semibold text-[#7D7D7D]"
                >
                  Additional Note
                </label>
                <textarea
                  id="additionalNote"
                  value={additionalNote}
                  onChange={(e) => setAdditionalNote(e.target.value)}
                  className="w-full outline-none text-[1rem] font-normal text-[#A9A9A9] pr-3 pt-2 border-b border-[#0D868C] capitalize"
                  placeholder="Enter any additional note"
                />
              </div>

              <div className="flex justify-center items-center">
                <button
                  type="submit"
                  className="text-white bg-[#0D868C] w-[50%] py-3 rounded-full outline-none uppercase font-normal text-[1rem]"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderDiscount;
