import React, { useEffect } from "react";
import UsersTable from "./UsersTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  selectIsLoading,
  selectUsers,
} from "../../redux/features/auth/AuthSlice";
import LoadingSpinner from "../Loader/LoadingSpinner";
import { NavLink } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import userlogo from "../../Asset/user logo.png";

const Users = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const isloading = useSelector(selectIsLoading);
  // console.log(users);
  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);
  return (
    <div>
      {isloading && <LoadingSpinner />}
      {users.length > 0 ? (
        <div className="mt-[2rem] w-[100%] overflow-auto scrollbar-hide touch-auto border-none rounded-xl">
          <table className="w-[100%]   ">
            <thead className="bg-[#D9D9D9] lg:h-[5rem] h-[4rem]  lg:text-[1rem] text-[.9rem]  ">
              <tr>
                <th
                  className="p-3 font-bold tracking-wide text-start "
                  scope="col"
                >
                  <span className=" invisible">Profile</span>
                </th>
                <th
                  className="p-3 font-bold tracking-wide text-start "
                  scope="col"
                >
                  User
                </th>
                <th
                  className="p-3 font-bold tracking-wide text-start "
                  scope="col"
                >
                  Email
                </th>
                <th
                  className="p-3 font-bold tracking-wide text-start "
                  scope="col"
                >
                  Date
                </th>
                <th
                  className="p-3 font-bold tracking-wide text-start  "
                  scope="col"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {users.map((user, i) => (
                <UsersTable
                  key={i}
                  id={user._id}
                  profile={user.profile}
                  name={user.name}
                  role={user.role}
                  email={user.email}
                  date={user.createdAt}
                  index={i}
                />
              ))}
            </tbody>
          </table>
          <NavLink
            to={"/settings/users/createUser"}
            className="fixed bottom-8 right-10 md:w-14 w-10 md:h-14 h-10 bg-[#0D868C] text-white rounded-full shadow-lg flex items-center justify-center hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
          >
            <FaPlus className="md:text-xl text-[1rem]" />
          </NavLink>
        </div>
      ) : (
        <div className="flex items-center justify-center">
          <div className="w-[29rem] lg:w-[38rem]  relative  lg:h-[30rem] h-[23rem] bg-[#A9A9A9] rounded-[2.4rem] text-white flex flex-col justify-center items-center ">
            <img src={userlogo} className="mb-2" alt="Logo" />

            <p className="text-[1rem] md:text-[1.5rem] font-inter-700 text-center">
              You have no user yet
            </p>
            <p className="text-[.8rem] md:text-[1rem] font-inter-400 text-center">
              To add a user, press the (+) button
            </p>
            <NavLink
              to={"/settings/users/createUser"}
              className=" absolute bottom-8 right-8 md:w-14 w-10 md:h-14 h-10 bg-[#0D868C] text-white rounded-full shadow-lg flex items-center justify-center hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
            >
              <FaPlus className="md:text-xl text-[1rem]" />
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Users;
