import React, { useEffect, useLayoutEffect, useState } from "react";
import { BiPencil } from "react-icons/bi";
import profileimg from "../../Asset/default profile.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  getUsers,
  selectSuccess,
  selectUsers,
  updateUser,
} from "../../redux/features/auth/AuthSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

function Profile() {
  const [editProfile, setEditProfile] = useState(true);
  const [imagePreview, setImagePreview] = useState(profileimg);
  const { id } = useParams();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, setValue } = useForm();
  const users = useSelector(selectUsers);
  const user = users.find((user) => user._id === id);
  // console.log(user);

  const navigate = useNavigate();
  const isSuccess = useSelector(selectSuccess);

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  useLayoutEffect(() => {
    if (user) {
      reset({
        name: user.name,
        email: user.email,
        role: user.role,
      });
      setImagePreview(user.profile || profileimg);
    }
  }, [user, reset]);

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete This User",
      message: "Are you sure to delete this user?",
      buttons: [
        {
          label: "Delete",
          onClick: () => removeUser(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const removeUser = async (id) => {
    await dispatch(deleteUser(id));
    if (isSuccess) {
      navigate("/settings");
    }
  };

  const handleUpdate = async (data) => {
    console.log("Updated Data:", data);
    const updatedUserData = new FormData();
    updatedUserData.append("name", data.name);
    updatedUserData.append("role", data.role);
    updatedUserData.append("email", data.email);
    if (data.profile) {
      updatedUserData.append("profileImage", data.profile);
    }

    // // Log FormData values
    // for (let [key, value] of updatedUserData.entries()) {
    //   console.log(`${key}:`, value);
    // }
    await dispatch(updateUser(updatedUserData));
    if (isSuccess) {
      toast.success("user has been update");
      setEditProfile(true);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImagePreview(URL.createObjectURL(file));
      setValue("profile", file);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center mt-2">
      <form onSubmit={handleSubmit(handleUpdate)} className="w-full">
        {/* image */}
        <div className="flex justify-center mb-10 ">
          <div className="relative">
            <img
              className="size-32  rounded-full"
              src={imagePreview}
              alt="user_img"
            />

            <label
              htmlFor="editImg"
              className="absolute right-2 bottom-0 rounded-full"
            >
              {!editProfile && (
                <BiPencil className="text-3xl bg-white rounded-full p-1 hover:text-[#0D868C] transition-all ease-linear duration-300 cursor-pointer ring-1 ring-[#0D868C]" />
              )}
            </label>
          </div>
          <div className="hidden">
            <input
              type="file"
              name="profile_img"
              id="editImg"
              {...register("profile")}
              accept="image/*"
              onChange={handleImageChange}
              disabled={editProfile}
            />
          </div>
        </div>
        <div className="mt-4 md:w-[80%] w-[90%]  mx-auto ">
          <div className=" flex items-center justify-center">
            <div className=" md:w-[90%] lg:w-[80%] ">
              {/* name */}
              <div className="mb-6 flex items-center gap-4">
                <label
                  htmlFor="full-name"
                  className="w-[30%] lg:text-[20px] md:text-[16px] text-[12px] font-semibold text-black text-right"
                >
                  Full Name:
                </label>
                <input
                  type="text"
                  id="full-name"
                  className={`bg-transparent border-2 border-[#D4D4D4] rounded-[10px] w-[70%] h-[50px] px-3 outline-none ${
                    editProfile ? "text-[#acaaaa]" : ""
                  }`}
                  placeholder="Amir Saeed"
                  disabled={editProfile}
                  {...register("name", { required: true })}
                  required
                />
              </div>
              {/* email */}
              <div className="mb-6 flex items-center gap-4">
                <label
                  htmlFor="email"
                  className="w-[30%] lg:text-[20px] md:text-[16px] text-[12px] font-semibold text-black  text-right"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  className={`bg-transparent border-2 border-[#D4D4D4] rounded-[10px] w-[70%] h-[50px] px-3 outline-none text-[#acaaaa]`}
                  placeholder="admin123@gmail.com"
                  {...register("email")}
                  disabled
                />
              </div>
              {/* role */}
              <div className="mb-4 flex items-center gap-4">
                <label
                  htmlFor="role"
                  className="w-[30%] lg:text-[20px] md:text-[16px] text-[12px] font-semibold text-black  text-right"
                >
                  Role:
                </label>
                <select
                  disabled={editProfile}
                  {...register("role", { required: true })}
                  required
                  className={`bg-transparent border-2 border-[#D4D4D4] rounded-[10px] w-[70%] h-[50px] px-3 outline-none ${
                    editProfile ? "text-[#acaaaa]" : ""
                  }`}
                >
                  <option value="" disabled></option>

                  <option value="admin">Admin</option>
                  <option value="user">User</option>
                  <option value="suspended">Suspended</option>
                </select>
              </div>
            </div>
          </div>

          <div className="flex justify-between  items-center mt-20">
            <div>
              <p
                onClick={() => confirmDelete(id)}
                className="px-8 h-[43px] rounded-full flex justify-center items-center cursor-pointer bg-[#F13B3B] text-white text-[1rem] font-semibold"
              >
                Delete
              </p>
            </div>
            <div>
              {editProfile ? (
                <p
                  type="button"
                  onClick={() => setEditProfile(!editProfile)}
                  className="px-8 h-[43px] rounded-full flex justify-center items-center cursor-pointer bg-[#0D868C] text-white text-[1rem] font-semibold"
                >
                  Edit
                </p>
              ) : (
                <button
                  type="submit"
                  className="px-8 h-[43px] rounded-full text-center bg-[#0D868C] text-white text-[1rem] font-semibold"
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Profile;
