import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import BaseServices from "./BaseServices";

const initialState = {
  base: null,
  bases: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// createbase thunkApi
export const createBase = createAsyncThunk(
  "base/createBase",
  async (baseData, thunkAPI) => {
    try {
      return await BaseServices.createBase(baseData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getBases = createAsyncThunk(
  "base/getBases",
  async (_, thunkAPI) => {
    try {
      return await BaseServices.getBases();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateBase = createAsyncThunk(
  "base/updateBase",
  async (baseData, thunkAPI) => {
    try {
      return await BaseServices.updateBase(baseData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteBase = createAsyncThunk(
  "base/deleteBase",
  async (id, thunkAPI) => {
    try {
      return await BaseServices.deleteBase(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const baseSlice = createSlice({
  name: "base",
  initialState,
  reducers: {
    RESET(state) {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      // create base
      .addCase(createBase.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(createBase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.base = action.payload;
        console.log(action.payload);
        toast.success("Create new base successfully");
      })

      .addCase(createBase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.base = null;
        toast.error(action.payload);
      })

      .addCase(getBases.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(getBases.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.bases = action.payload.data;
      })

      .addCase(getBases.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.bases = null;
        toast.error(action.payload);
      })

      .addCase(updateBase.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(updateBase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.base = action.payload.data;
        console.log(action.payload);
      })

      .addCase(updateBase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.base = null;
        toast.error(action.payload);
      })
      .addCase(deleteBase.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(deleteBase.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.base = null;
        toast.success(action.payload);
        console.log(action.payload);
      })

      .addCase(deleteBase.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.base = null;
        toast.error(action.payload);
      });
  },
});

export const { RESET } = baseSlice.actions;
export const selectBase = (state) => state.base.base;
export const selectBases = (state) => state.base.bases;
export const selectBaseSuccess = (state) => state.base.isSuccess;

export default baseSlice.reducer;
