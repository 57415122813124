import React, { useEffect, useState } from "react";
import { BiSolidCategory } from "react-icons/bi";
import { FaPlus } from "react-icons/fa";
import { TfiMenuAlt } from "react-icons/tfi";
import Navbar from "../../component/Navbar/Navbar";
import Sidebar from "../../component/sidebar/Sidebar";
import { NavLink } from "react-router-dom";
import useRedirectLogoutUser from "../../CustomHook/useRedirectLogoutUser";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteItem,
  getItems,
  selectitems,
  selectItemSuccess,
} from "../../redux/features/item/ItemSlice";
import { MdDelete, MdEdit } from "react-icons/md";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import "./Items.css";
import base1 from "../../Asset/base 1.png";
import sauce1 from "../../Asset/sauce 1.png";
import topping1 from "../../Asset/topping 1.png";
import cheese1 from "../../Asset/cheese 1.png";
import base2 from "../../Asset/base 2.png";
import sauce2 from "../../Asset/sauce 2.png";
import topping2 from "../../Asset/topping 2.png";
import cheese2 from "../../Asset/cheese 2.png";
import ItemLogo from "../../Asset/item logo.png";
import {
  filterItems,
  selectFilteredItems,
} from "../../redux/features/filter/FilterSlice";

export const sidebarSections = [
  {
    name: "Items",
    icon: <TfiMenuAlt />,
    path: "/items",
  },
  {
    name: "Categories",
    icon: <BiSolidCategory />,
    path: "/categories",
  },
  {
    name: "Bases",
    image1: base1,
    image2: base2,
    path: "/bases",
  },
  {
    name: "Sauces",
    image1: sauce1,
    image2: sauce2,
    path: "/sauces",
  },
  {
    name: "Toppings",
    image1: topping1,
    image2: topping2,
    path: "/toppings",
  },
  {
    name: "Cheeses",
    image1: cheese1,
    image2: cheese2,
    path: "/cheeses",
  },
];

const Items = () => {
  useRedirectLogoutUser("/login");
  const [search, setSearch] = useState("");

  let items = useSelector(selectitems);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getItems());
  }, [dispatch]);

  const applyDiscount = (price, discount) => {
    if (discount > 0) {
      return (price - (price * discount) / 100).toFixed(2);
    }
    return price;
  };

  const [selectedIndexes, setSelectedIndexes] = useState({});

  const handleSizeChange = (event, itemId) => {
    const newIndex = event.target.value;
    setSelectedIndexes((prev) => ({
      ...prev,
      [itemId]: newIndex,
    }));
  };

  // delete item
  const confirmDelete = (id, name) => {
    if (!id || !name) {
      toast.error("ID or Name is undefined");
      return;
    }
    confirmAlert({
      title: "Delete This Item",
      message: `Are you sure to delete this ${name}?`,
      buttons: [
        {
          label: "Delete",
          onClick: () => removeItem(id),
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  // start pagination
  const fliterItems = useSelector(selectFilteredItems);
  const itemsPerPage = 10;
  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = fliterItems?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(fliterItems?.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % fliterItems?.length;

    setItemOffset(newOffset);
  };
  // end pagination

  const isSuccess = useSelector(selectItemSuccess);

  const removeItem = async (id) => {
    await dispatch(deleteItem(id));
    if (isSuccess) {
      dispatch(getItems());
    }
  };

  useEffect(() => {
    dispatch(filterItems({ search, items }));
  }, [dispatch, search, items]);

  return (
    <div className="flex flex-col h-screen bg-[#F1F1F1]">
      {/* Navbar */}
      <Navbar page="Items" />

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar */}
        <div className="w-[30%] md:block hidden bg-white shadow-md">
          <Sidebar sidebarSections={sidebarSections} />
        </div>

        {/* Main Content */}
        <div className="flex-1 p-8 relative overflow-y-auto  scrollbar-hide ">
          {/* Product List */}
          {items?.length > 0 ? (
            <>
              <div className="lg:w-full w-[37rem] mx-auto mb-10 ">
                <div className="flex lg:justify-end justify-start my-4 items-center ">
                  <input
                    type="search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="bg-transparent py-2 px-4 border-2 border-[#A9A9A9]  rounded-full outline-none"
                    placeholder="Search by name..."
                  />
                </div>
                <div className="space-y-4">
                  {currentItems.map((item, index) => {
                    const selectedIndex = selectedIndexes[item._id] || 0;

                    return (
                      <div
                        key={item._id}
                        className={`flex items-center justify-between pt-4 pb-2 ${
                          index !== 0 ? "border-t-2 border-[#DEDEDE]" : ""
                        }`}
                      >
                        <div className="flex items-center gap-4">
                          <img
                            src={item.itemImage}
                            alt={item.name}
                            className="w-[4.4rem] h-[4.4rem] rounded-[20px] object-cover"
                          />
                          <span className="font-inter-600 text-[1rem] text-wrap capitalize text-[#0D868C]">
                            {item.name}
                          </span>
                        </div>

                        <div className="flex items-center gap-8">
                          {item.price > 0 && (
                            <div className="flex gap-2 items-center">
                              <span
                                className={`text-[#1E1E1E] text-[1rem] font-inter-400 ${
                                  item.discount > 0
                                    ? "line-through text-gray-500"
                                    : ""
                                }`}
                              >
                                € {item.price}
                              </span>
                              {item.discount > 0 && (
                                <span className="text-[#1E1E1E] text-[1rem] font-inter-400">
                                  € {applyDiscount(item.price, item.discount)}
                                  <span className="ml-2">{item.discount}%</span>
                                </span>
                              )}
                            </div>
                          )}

                          {item.variants.sizes.length > 0 && (
                            <div className="flex items-center gap-3">
                              <select
                                id={item._id}
                                className="py-1 px-2 outline-none w-[6rem] rounded-lg border-2 bg-transparent border-[#DEDEDE] text-[#1E1E1E] text-[.9rem] font-inter-400 "
                                value={selectedIndex}
                                onChange={(e) => handleSizeChange(e, item._id)}
                              >
                                {item.variants.sizes.map((size, index) => (
                                  <option
                                    className=""
                                    key={index}
                                    value={index}
                                  >
                                    {size.toUpperCase()}
                                  </option>
                                ))}
                              </select>

                              <div className="flex gap-2 items-center">
                                <span
                                  className={`text-[#1E1E1E] text-[1rem] font-inter-400 ${
                                    item.discount > 0
                                      ? "line-through text-gray-500"
                                      : ""
                                  }`}
                                >
                                  €{item.variants.prices[selectedIndex]}
                                </span>
                                {item.discount > 0 && (
                                  <span className="text-[#1E1E1E] text-[1rem] font-inter-400">
                                    €
                                    {applyDiscount(
                                      parseFloat(
                                        item.variants.prices[selectedIndex]
                                      ),
                                      item.discount
                                    )}
                                    <span className="ml-2">
                                      {item.discount}%
                                    </span>
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          <div className="flex items-center gap-3">
                            <NavLink
                              className="bg-[#0D868C] text-center text-white p-1 rounded-lg cursor-pointer"
                              to={`/editItem/${item._id}`}
                            >
                              <MdEdit className="size-6" />
                            </NavLink>
                            <span
                              onClick={() => confirmDelete(item._id, item.name)}
                              className="bg-[#f1524d] text-center text-white p-1 rounded-lg cursor-pointer"
                            >
                              <MdDelete className="size-6" />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                {fliterItems?.length > itemsPerPage && (
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="Next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="Prev"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="page-num"
                    previousLinkClassName="page-num"
                    nextLinkClassName="page-num"
                    activeLinkClassName="activePage"
                  />
                )}
              </div>

              {/* Add New Item Button */}
              <NavLink
                to={"/createItem"}
                className="fixed bottom-8 right-10 md:w-14 w-10 md:h-14 h-10 bg-[#0D868C] text-white rounded-full shadow-lg flex items-center justify-center hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
              >
                <FaPlus className="md:text-xl text-[1rem]" />
              </NavLink>
            </>
          ) : (
            <div className="flex items-center justify-center">
              <div className="w-[29rem] lg:w-[38rem]  relative  lg:h-[30rem] h-[23rem] bg-[#A9A9A9] rounded-[2.4rem] text-white flex flex-col justify-center items-center ">
                <img src={ItemLogo} className="mb-2" alt="Logo" />

                <p className="text-[1rem] md:text-[1.5rem] font-inter-700 text-center">
                  You have no item yet
                </p>
                <p className="text-[.8rem] md:text-[1rem] font-inter-400 text-center">
                  To add a item, press the (+) button
                </p>
                <NavLink
                  to={"/createItem"}
                  className=" absolute bottom-8 right-8 md:w-14 w-10 md:h-14 h-10 bg-[#0D868C] text-white rounded-full shadow-lg flex items-center justify-center hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2"
                >
                  <FaPlus className="md:text-xl text-[1rem]" />
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Items;
