import React from "react";
import "./Forgot.css";
import { IoIosArrowBack } from "react-icons/io";
import { NavLink } from "react-router-dom";
import { useForm } from "react-hook-form";
import useRedirectLoginUser from "../../CustomHook/useRedirectLoginUser";
import { useDispatch } from "react-redux";
import { forgotPassword } from "../../redux/features/auth/AuthSlice";

const Forgot = () => {
  useRedirectLoginUser("/items");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const dispatch = useDispatch();
  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    const userData = data;
    await dispatch(forgotPassword(userData));
  };

  return (
    <div className="forgot-container">
      <div className="overlay relative">
        <div className="flex mb-6 absolute top-6 left-4">
          <NavLink to={"/login"}>
            <button className="text-[1rem] font-inter-600 text-white flex items-center gap-3">
              <IoIosArrowBack className="size-6" /> Forgot password
            </button>
          </NavLink>
        </div>
        <div className="w-[650px] flex items-center justify-center">
          <div className="bg-white rounded-[10px] shadow-lg py-[2rem] md:px-[4rem] px-[2rem] w-[100%] md:m-2 m-4">
            <h2 className="text-center mb-2 text-[1rem] text-[#1E1E1E] font-inter-400 md:w-[80%] w-[90%] mx-auto">
              Enter your email address or mobile no. to receive instructions to
              reset password.
            </h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                  type="text"
                  placeholder="Enter the email"
                  {...register("email", {
                    required: "Email is required.",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Enter a valid email.",
                    },
                  })}
                  className={`w-full border-b-2 text-[#7D7D7D] border-[#0D868C] outline-none py-2 `}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.email.message}
                  </p>
                )}
              </div>
              <div
                className={`text-center ${
                  Object.keys(errors).length > 0
                    ? "mt-[6rem] md:mt-[10.5rem]"
                    : "md:mt-[12rem] mt-[11rem]"
                }`}
              >
                <button
                  type="submit"
                  className="bg-[#0D868C] text-white md:w-[16.5rem] w-[12rem] py-3 rounded-[65px] mt-4 "
                >
                  SEND
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
