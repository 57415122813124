import { useEffect, useState } from "react";
import authServices from "../redux/features/auth/AuthServies";

const OnlyAdmin = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const response = await authServices.getUser();
      setUser(response.data);
    };
    fetchUser();
  }, []);

  return user?.role === "admin" ? <>{children}</> : null;
};

export { OnlyAdmin };
