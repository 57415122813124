import React, { useEffect, useState } from "react";
import { BACKEND_BASEURL } from "../../db/baseUrl";
import { toast } from "react-toastify";
import axios from "axios";
import {
  getToppings,
  selectToppings,
} from "../../redux/features/topping/ToppingSlice";
import { getSauces, selectSauces } from "../../redux/features/sauce/SauceSlice";
import { getBases, selectBases } from "../../redux/features/base/BaseSlice";
import {
  getCheeses,
  selectCheeses,
} from "../../redux/features/cheese/CheeseSlice";
import { useDispatch, useSelector } from "react-redux";

const DealItem = ({
  data,
  selectedItems,
  setSelectedItems,
  selectedPizza,
  setSelectedPizza,
  selectedTopping,
  setSelectedTopping,
  selectedBaseIndex,
  setSelectedBaseIndex,
  selectedSauceIndex,
  setSelectedSauceIndex,
  selectedCheeseIndex,
  setSelectedCheeseIndex,
  selectedAdd_Ons,
  setSelectedAdd_Ons,
}) => {
  const [itemsByCategories, setItemsByCategories] = useState({});
  const [pizza, setPizza] = useState();
  const dispatch = useDispatch();
  const toppings = useSelector(selectToppings);
  const bases = useSelector(selectBases);
  const sauces = useSelector(selectSauces);
  const cheeses = useSelector(selectCheeses);
  const handlePizzaSelect = async (itemName, items) => {
    setSelectedPizza(itemName);
    const selectedPizzaItem = await items.find(
      (item) => item.name === itemName
    );
    setPizza(selectedPizzaItem || null);
  };
  // useEffect(() => {
  //   console.log("Updated pizza Data:", pizza);
  // }, [pizza]);
  const handleSelectChange = (category, itemName, index) => {
    setSelectedItems((prevSelectedItems) => ({
      ...prevSelectedItems,
      [`${category}-${index}`]: itemName,
    }));
  };

  useEffect(() => {
    const fetchDealItems = async () => {
      if (!data?.dealItem?.length) return;

      const categoryItems = {};

      for (const item of data.dealItem) {
        try {
          const response = await axios.get(
            `${BACKEND_BASEURL}api/dealItem/${item.category}`
          );
          categoryItems[item.category] = response.data;
        } catch (error) {
          const message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          toast.error(message);
        }
      }

      setItemsByCategories(categoryItems);
    };

    fetchDealItems();
  }, [data]);

  useEffect(() => {
    if (data) {
      const validCategories = ["pizza"];
      Object.entries(itemsByCategories).map(([categoryName]) => {
        if (validCategories.some((category) => categoryName === category)) {
          dispatch(getToppings());
          dispatch(getSauces());
          dispatch(getBases());
          dispatch(getCheeses());
        }
        return null;
      });
    }
  }, [data, dispatch, itemsByCategories]);

  useEffect(() => {
    if (pizza?.name.includes("build your own")) {
      setSelectedTopping([]);
    } else if (pizza?.toppings?.length > 0) {
      const initialToppings = pizza.toppings.map((topping) => ({
        name: topping,
        price: 0,
      }));
      setSelectedTopping(initialToppings);
    }
  }, [pizza?.name, pizza?.toppings, setSelectedTopping]);

  const MAX_TOPPINGS = pizza && pizza.toppings ? pizza.toppings.length : 0;

  const handleToppingToggle = (topping, price) => {
    setSelectedTopping((prevSelectedTopping) => {
      if (!Array.isArray(prevSelectedTopping)) {
        return [topping];
      }

      const exists = prevSelectedTopping.some(
        (selected) => selected.name === topping
      );

      if (exists) {
        let filteredToppings = prevSelectedTopping.filter(
          (selected) => selected.name !== topping
        );
        if (filteredToppings?.length > 0) {
          filteredToppings = filteredToppings.map((topping, index) => {
            const normalizedToppingName =
              topping.name?.trim().toLowerCase() || "";
            const matchingTopping = toppings.find(
              (predefined) =>
                predefined.name?.trim().toLowerCase() === normalizedToppingName
            );
            return {
              ...topping,
              price: index < MAX_TOPPINGS ? 0 : matchingTopping?.price || 0.5,
            };
          });
        }

        // console.log(filteredToppings);
        return filteredToppings;
      } else {
        if (prevSelectedTopping.length < MAX_TOPPINGS) {
          return [...prevSelectedTopping, { name: topping, price: 0 }];
        } else {
          setSelectedTopping((prevSelectedTopping) => [
            ...prevSelectedTopping,
            { name: topping, price },
          ]);
          return prevSelectedTopping;
        }
      }
    });
  };

  //   bases select

  const handleBaseChange = (event, base) => {
    const newIndex = event.target.value;
    setSelectedBaseIndex(newIndex);
  };

  // select sauce

  const handleSauceChange = (event, sauce) => {
    const newIndex = event.target.value;
    setSelectedSauceIndex(newIndex);
  };

  // select cheese

  const handleCheeseChange = (event, cheese) => {
    const newIndex = event.target.value;
    setSelectedCheeseIndex(newIndex);
  };

  // add-ons section
  const [drinks, setDrinks] = useState();
  const [dips, setDips] = useState();
  const getItemByCategoryDrinks = async () => {
    try {
      const category = "drinks";
      const response = await axios.get(`${BACKEND_BASEURL}api/${category}`);
      setDrinks(response.data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const getItemByCategorydips = async () => {
    try {
      const category = "dips";
      const response = await axios.get(`${BACKEND_BASEURL}api/${category}`);
      setDips(response.data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      toast.error(message);
    }
  };

  const handleToggle = (category, item) => {
    let price = 0;
    if (category === "dip") {
      price = 0.99;
    } else if (category === "can") {
      price = 1.49;
    } else if (category === "bottle") {
      price = 3.75;
    }

    setSelectedAdd_Ons((prevState) => ({
      ...prevState,
      [category]:
        prevState[category]?.name === item.name
          ? null
          : { name: item.name, price },
    }));
  };

  const [remainingToppings, setRemainingToppings] = useState([]);
  useEffect(() => {
    const filteredToppings = toppings?.filter(
      (topping) =>
        !pizza?.toppings?.some(
          (dataTopping) =>
            dataTopping.toLowerCase() === topping.name.toLowerCase()
        )
    );

    setRemainingToppings(filteredToppings || toppings);
  }, [toppings, pizza]);

  useEffect(() => {
    if (["deal", "meal"].includes(data.category)) {
      getItemByCategoryDrinks();
      getItemByCategorydips();
    }
  }, [data]);
  return (
    <div>
      <div>
        {Object.entries(itemsByCategories).map(([categoryName, items]) => (
          <div key={categoryName} className="mb-4">
            {data.dealItem?.map((dt) => {
              if (dt.category === categoryName) {
                return Array.from({ length: dt.quantity }).map((_, index) => (
                  <div key={`${categoryName}-${index}`} className="mb-8">
                    <label className="flex justify-center mb-8 w-full uppercase lg:text-[1.5rem] text-[1rem] font-bold px-10 py-2 text-[#0D868C] lg:border-y-2 border-y border-[#0D868C]">
                      {categoryName.toUpperCase()}
                    </label>
                    {categoryName === "pizza" ? (
                      <>
                        <div className="flex gap-2 items-center flex-wrap ">
                          {items.map((item, i) => (
                            <label
                              key={i}
                              className="flex items-center cursor-pointer"
                            >
                              <input
                                type="radio"
                                name={categoryName}
                                value={item?.name}
                                checked={selectedPizza === item?.name}
                                onChange={() =>
                                  handlePizzaSelect(item?.name, items)
                                }
                                className="hidden"
                              />

                              <span
                                className={`lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                            font-inter-400 capitalize ${
                                              selectedPizza === item?.name
                                                ? "bg-[#0D868C] text-white font-bold"
                                                : "text-[#1E1E1E]/60 bg-white"
                                            } `}
                              >
                                {item?.name}
                              </span>
                            </label>
                          ))}
                        </div>
                        {selectedPizza && pizza && (
                          <div className="mt-4">
                            <h3 className="text-[1.5rem] capitalize font-bold mb-2 text-[#0D868C]">
                              {pizza.name}
                            </h3>

                            {pizza?.name.includes("build your own") ? (
                              <div>
                                <p>Please customize your order!</p>
                              </div>
                            ) : (
                              pizza?.toppings?.length > 0 && (
                                <div className="flex flex-col gap-3 mb-2">
                                  <h1 className="font-semibold capitalize">
                                    Topping with {pizza.name}
                                  </h1>
                                  <div className="flex gap-2 items-center flex-wrap">
                                    {pizza.toppings.map((topping, index) => (
                                      <label
                                        key={topping._id}
                                        className="flex items-center gap-2 cursor-pointer"
                                      >
                                        <input
                                          type="checkbox"
                                          name={`topping-${pizza._id}`}
                                          value={topping}
                                          onChange={() =>
                                            handleToppingToggle(topping)
                                          }
                                          checked={selectedTopping.some(
                                            (selected) =>
                                              selected.name === topping
                                          )}
                                          // disabled={
                                          //   selectedTopping.length >=
                                          //     MAX_TOPPINGS &&
                                          //   !selectedTopping.some(
                                          //     (selected) =>
                                          //       selected.name === topping
                                          //   )
                                          // }
                                          className="form-checkbox text-blue-500 hidden h-4 w-4"
                                        />
                                        <span
                                          className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                                ${
                                                  selectedTopping.some(
                                                    (selected) =>
                                                      selected.name === topping
                                                  )
                                                    ? "bg-[#0D868C] text-white font-semibold"
                                                    : "text-[#1E1E1E]/60 bg-white"
                                                }
                                          font-inter-400 capitalize`}
                                        >
                                          {topping}
                                        </span>
                                      </label>
                                    ))}
                                  </div>
                                </div>
                              )
                            )}
                            {/* bases */}
                            {bases && bases.length > 0 && (
                              <div className=" flex flex-col gap-3 mb-2">
                                <h1 className="font-semibold ">Bases</h1>
                                <div className="flex gap-2 items-center flex-wrap">
                                  {bases.map((base, index) => (
                                    <div
                                      key={base._id}
                                      className=" flex items-center"
                                    >
                                      <label className="flex items-center gap-2 cursor-pointer ">
                                        <input
                                          type="radio"
                                          name="base"
                                          value={base.name}
                                          onChange={(e) =>
                                            handleBaseChange(e, base)
                                          }
                                          className="form-radio hidden text-blue-500 h-4 w-4"
                                        />
                                        <span
                                          className={`lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                                ${
                                                  selectedBaseIndex ===
                                                  base.name
                                                    ? "bg-[#0D868C] text-white font-bold "
                                                    : "text-[#1E1E1E]/60 bg-white "
                                                }
                                          font-inter-400 capitalize`}
                                        >
                                          {base.name}
                                        </span>
                                      </label>
                                      {/* <img
                            src={base.image}
                            alt={base.name}
                            className="w-32 h-32 object-cover my-2"
                          /> */}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {/* sauces */}
                            {sauces && sauces.length > 0 && (
                              <div className=" flex flex-col gap-3 mb-2">
                                <h1 className="font-semibold">Sauces</h1>
                                <div className="flex gap-2 items-center flex-wrap">
                                  {sauces.map((sauce, index) => (
                                    <div
                                      key={sauce._id}
                                      className=" flex items-center"
                                    >
                                      <label className="flex items-center gap-2 cursor-pointer  ">
                                        <input
                                          type="radio"
                                          name="sauce"
                                          value={sauce.name}
                                          onChange={(e) =>
                                            handleSauceChange(e, sauce)
                                          }
                                          className="form-radio hidden text-blue-500 h-4 w-4"
                                        />
                                        <span
                                          className={`lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                                ${
                                                  selectedSauceIndex ===
                                                  sauce.name
                                                    ? "bg-[#0D868C] text-white font-bold "
                                                    : "text-[#1E1E1E]/60 bg-white "
                                                }
                                          font-inter-400 capitalize`}
                                        >
                                          {sauce.name}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                            {/* cheeses */}
                            {cheeses && cheeses.length > 0 && (
                              <div className=" flex flex-col gap-3 mb-2">
                                <h1 className="font-semibold">Cheese</h1>
                                <div className="flex gap-2 items-center flex-wrap">
                                  {cheeses.map((cheese, index) => (
                                    <div
                                      key={cheese._id}
                                      className=" flex items-center"
                                    >
                                      <label className="flex items-center gap-2 cursor-pointer  ">
                                        <input
                                          type="radio"
                                          name="cheese"
                                          value={cheese.name}
                                          onChange={(e) =>
                                            handleCheeseChange(e, cheese)
                                          }
                                          className="form-radio hidden text-blue-500 h-4 w-4"
                                        />
                                        <span
                                          className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                                ${
                                                  selectedCheeseIndex ===
                                                  cheese.name
                                                    ? "bg-[#0D868C] text-white font-bold "
                                                    : "text-[#1E1E1E]/60 bg-white"
                                                }
                                          font-inter-400 capitalize`}
                                        >
                                          {cheese.name}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}

                            {/* toppings */}
                            {toppings && toppings.length > 0 && (
                              <div className=" flex flex-col gap-3 mb-2">
                                <h1 className="font-semibold">Toppings</h1>
                                <div className="flex gap-2 items-center flex-wrap">
                                  {remainingToppings.map((topping, index) => (
                                    <div
                                      key={topping._id}
                                      className=" flex items-center"
                                    >
                                      <label className="flex items-center gap-2 cursor-pointer  ">
                                        <input
                                          type="checkbox"
                                          onChange={() =>
                                            handleToppingToggle(
                                              topping.name,
                                              topping.price
                                            )
                                          }
                                          checked={selectedTopping.some(
                                            (selected) =>
                                              selected.name === topping.name
                                          )}
                                          className="form-checkbox hidden text-blue-500 h-4 w-4"
                                        />
                                        <span
                                          className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                                ${
                                                  selectedTopping.some(
                                                    (selected) =>
                                                      selected.name ===
                                                      topping.name
                                                  )
                                                    ? "bg-[#0D868C] text-white font-bold"
                                                    : "text-[#1E1E1E]/60 bg-white"
                                                }
                                          font-inter-400 capitalize`}
                                        >
                                          {topping.name}{" "}
                                        </span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {/* <div className="flex items-start flex-col gap-3">
                          <div className=" p-1 pr-4 bg-white w-[40%] rounded-full">
                            <select
                              key={index}
                              className=" bg-transparent p-2 w-full outline-none text-sm"
                              value={
                                selectedItems[`${categoryName}-${index}`] || ""
                              }
                              onChange={(e) =>
                                handleSelectChange(
                                  categoryName,
                                  e.target.value,
                                  index
                                )
                              }
                            >
                              <option value="">Select an item</option>
                              {categoryName === "drinks"
                                ? items
                                    .filter((item) =>
                                      item.name.toLowerCase().includes("can")
                                    )
                                    .map((item) => (
                                      <option key={item._id} value={item.name}>
                                        {item.name}
                                      </option>
                                    ))
                                : items.map((item) => (
                                    <option key={item._id} value={item.name}>
                                      {item.name}
                                    </option>
                                  ))}
                            </select>
                          </div>
                          <span className=" capitalize  bg-[#0D868C] text-white px-3 p-2 rounded-full">
                            {selectedItems[`${categoryName}-${index}`]}
                          </span>
                        </div> */}
                        <div>
                          <div className="flex gap-2 items-center flex-wrap">
                            {categoryName === "drinks"
                              ? items
                                  .filter((item) =>
                                    item.name.toLowerCase().includes("can")
                                  )
                                  .map((item) => (
                                    <div
                                      key={index}
                                      className=" flex items-center"
                                    >
                                      <label
                                        key={item._id}
                                        className="flex items-center gap-2 cursor-pointer"
                                      >
                                        <input
                                          type="radio"
                                          name={`${categoryName}-${index}`}
                                          value={item.name}
                                          checked={
                                            selectedItems[
                                              `${categoryName}-${index}`
                                            ] === item.name
                                          }
                                          onChange={(e) =>
                                            handleSelectChange(
                                              categoryName,
                                              e.target.value,
                                              index
                                            )
                                          }
                                          className="accent-blue-500 hidden"
                                        />
                                        <span
                                          className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                            font-inter-400 capitalize
                                        ${
                                          selectedItems[
                                            `${categoryName}-${index}`
                                          ] === item.name
                                            ? "bg-[#0D868C] text-white font-bold"
                                            : "text-[#1E1E1E]/60 bg-white"
                                        }
                                        `}
                                        >
                                          {item.name}
                                        </span>
                                      </label>
                                    </div>
                                  ))
                              : items.map((item) => (
                                  <label
                                    key={item._id}
                                    className="flex items-center gap-2 cursor-pointer"
                                  >
                                    <input
                                      type="radio"
                                      name={`${categoryName}-${index}`}
                                      value={item.name}
                                      checked={
                                        selectedItems[
                                          `${categoryName}-${index}`
                                        ] === item.name
                                      }
                                      onChange={(e) =>
                                        handleSelectChange(
                                          categoryName,
                                          e.target.value,
                                          index
                                        )
                                      }
                                      className="accent-blue-500 hidden"
                                    />
                                    <span
                                      className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl 
                                        ${
                                          selectedItems[
                                            `${categoryName}-${index}`
                                          ] === item.name
                                            ? "bg-[#0D868C] text-white font-bold"
                                            : "text-[#1E1E1E]/60 bg-white"
                                        }
                                        font-inter-400 capitalize`}
                                    >
                                      {item.name}
                                    </span>
                                  </label>
                                ))}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ));
              }
              return null;
            })}
          </div>
        ))}

        <div className="flex flex-col w-full gap-4">
          <label className="flex justify-center mb-2 w-full uppercase lg:text-[1rem] text-[.8rem] font-bold px-10 py-2 text-[#0D868C]  ">
            Add Dip for 99¢ Add Can for &euro;1.49 or Bottle &euro;3.75
          </label>
          {dips?.length > 0 && (
            <div className="flex flex-col gap-3 mb-2">
              <h1 className="font-semibold capitalize ml-1">Dips</h1>
              <div className="flex items-center gap-2">
                {dips?.map((item, i) => (
                  <div key={i} className="flex items-center">
                    <label className="flex items-center gap-2 cursor-pointer">
                      <input
                        type="checkbox"
                        checked={selectedAdd_Ons.dip?.name === item.name}
                        onChange={() => handleToggle("dip", item)}
                        className="accent-blue-500 hidden"
                      />
                      <span
                        className={`lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl font-inter-400 capitalize ${
                          selectedAdd_Ons.dip?.name === item.name
                            ? "text-white bg-[#0D868C]"
                            : "text-[#1E1E1E]/60 bg-white"
                        }`}
                      >
                        {item.name}
                      </span>
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}

          {drinks?.filter((item) => item.name.toLowerCase().includes("can"))
            .length > 0 && (
            <div className="flex flex-col gap-3 mb-2">
              <h1 className="font-semibold capitalize ml-1">Can</h1>
              <div className="flex items-center gap-2">
                {drinks
                  ?.filter((item) => item.name.toLowerCase().includes("can"))
                  ?.map((item, i) => (
                    <div key={i} className=" flex items-center">
                      <label
                        key={item._id}
                        className="flex items-center gap-2 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          checked={selectedAdd_Ons.can?.name === item.name}
                          onChange={() => handleToggle("can", item)}
                          className="hidden"
                        />
                        <span
                          className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl  font-inter-400 capitalize ${
                            selectedAdd_Ons.can?.name === item.name
                              ? "text-[white] bg-[#0D868C]"
                              : " text-[#1E1E1E]/60 bg-white"
                          }`}
                        >
                          {item.name}
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {drinks?.filter((item) => item.name.toLowerCase().includes("bottle"))
            .length > 0 && (
            <div className="flex flex-col gap-3 mb-2">
              <h1 className="font-semibold capitalize ml-1">Bottle</h1>

              <div className="flex items-center gap-2">
                {drinks
                  ?.filter((item) => item.name.toLowerCase().includes("bottle"))
                  ?.map((item, i) => (
                    <div key={i} className=" flex items-center">
                      <label
                        key={item._id}
                        className="flex items-center gap-2 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          checked={selectedAdd_Ons.bottle?.name === item.name}
                          onChange={() => handleToggle("bottle", item)}
                          className="hidden"
                        />
                        <span
                          className={` lg:text-[.9rem] text-[.7rem] px-3 py-2 rounded-xl  font-inter-400 capitalize ${
                            selectedAdd_Ons.bottle?.name === item.name
                              ? "text-[white] bg-[#0D868C]"
                              : " text-[#1E1E1E]/60 bg-white"
                          }`}
                        >
                          {item.name}
                        </span>
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DealItem;
