import React, { useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaUsers } from "react-icons/fa";
// import printer from "../../Asset/printer.png";
import display1 from "../../Asset/display 1.png";
import display2 from "../../Asset/display 2.png";
import taxes1 from "../../Asset/taxes 1.png";
import taxes2 from "../../Asset/taxes 2.png";
// import changePassword from "../../Asset/change-password.png";
import general1 from "../../Asset/general 1.png";
import general2 from "../../Asset/general 2.png";
import backOffice from "../../Asset/back office.png";
import support from "../../Asset/support.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getUser,
  logoutUser,
  selectSuccess,
  selectUser,
} from "../../redux/features/auth/AuthSlice";
import { RiPrinterFill } from "react-icons/ri";
import { RiLockPasswordFill } from "react-icons/ri";
import { OnlyAdmin } from "../../Protect/HiddenLink";

const sidebarSections = [
  { name: "Printer", icon: <RiPrinterFill />, path: "/settings/printer" },
  {
    name: "Customer displays",
    image1: display1,
    image2: display2,
    path: "/settings/customerDisplays",
  },
  { name: "Taxes", image1: taxes1, image2: taxes2, path: "/settings/taxes" },
  {
    name: "General",
    image1: general1,
    image2: general2,
    path: "/settings/general",
  },
  {
    name: "Users",
    icon: <FaUsers />,
    path: "/settings/users",
  },
  {
    name: "Change Password",
    icon: <RiLockPasswordFill />,
    path: "/settings/changePassword",
  },
];

const sidebarGuide = [
  { name: "Back office", image: backOffice },
  { name: "Support", image: support, version: "v 2.30.1" },
];

const SidebarSetting = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  // console.log(user);
  useEffect(() => {
    const fetchUser = async () => {
      try {
        await dispatch(getUser());
      } catch (error) {
        console.log(error);
      }
    };
    fetchUser();
  }, [dispatch]);

  const navigate = useNavigate();
  const isSuccess = useSelector(selectSuccess);
  const logout = async () => {
    await dispatch(logoutUser());
    if (isSuccess) {
      navigate("/login");
    }
  };

  const location = useLocation();

  const isactive = (path) => location.pathname === path;

  return (
    <div>
      <div className="flex min-h-[90vh]  mb-0 flex-col justify-between">
        {/* Sidebar Sections */}
        <div>
          {sidebarSections.map((section, index) =>
            section.name === "Users" ? (
              <OnlyAdmin key={index}>
                <NavLink
                  to={section.path}
                  className={({ isActive }) =>
                    `py-3 lg:py-4 lg:px-8 px-6 cursor-pointer lg:text-[1rem] text-[.8rem] font-inter-600 flex items-center 
          hover:bg-[#E6E6E6] ${
            isActive ? "bg-[#E6E6E6] text-[#0D868C]" : "bg-white text-[#A9A9A9]"
          }`
                  }
                >
                  {section.icon && (
                    <span className="mr-5 text-2xl">{section.icon}</span>
                  )}
                  {isactive(section.path)
                    ? section.image2 && (
                        <img
                          src={section.image2}
                          className="mr-5 w-[1.3rem] "
                          alt={section.name}
                        />
                      )
                    : section.image1 && (
                        <img
                          src={section.image1}
                          className="mr-5 w-[1.3rem] "
                          alt={section.name}
                        />
                      )}
                  <span>{section.name}</span>
                </NavLink>
              </OnlyAdmin>
            ) : (
              // Regular rendering for non-'Users' sections
              <NavLink
                key={index}
                to={section.path}
                className={({ isActive }) =>
                  `py-3 lg:py-4 lg:px-8 px-6 cursor-pointer lg:text-[1rem] text-[.8rem] font-inter-600 flex items-center 
        hover:bg-[#E6E6E6] ${
          isActive ? "bg-[#E6E6E6] text-[#0D868C]" : "bg-white text-[#A9A9A9]"
        }`
                }
              >
                {section.icon && (
                  <span className="mr-5 text-2xl">{section.icon}</span>
                )}
                {isactive(section.path)
                  ? section.image2 && (
                      <img
                        src={section.image2}
                        className="mr-5 w-[1.3rem] "
                        alt={section.name}
                      />
                    )
                  : section.image1 && (
                      <img
                        src={section.image1}
                        className="mr-5 w-[1.3rem] "
                        alt={section.name}
                      />
                    )}
                <span>{section.name}</span>
              </NavLink>
            )
          )}
        </div>

        {/* Guide Section */}
        <div className="lg:mt-6 mt-4">
          <div className="flex items-center justify-between px-6 lg:px-8 mb-3">
            <div className="flex items-center">
              <img
                src={user?.profile}
                className="w-8 lg:mr-4 mr-2 rounded-full"
                alt=""
              />
              <p className=" capitalize text-[#A9A9A9] lg:text-[1rem] md:text-[.8rem] font-normal">
                {user?.name}
              </p>
            </div>
            <div>
              <button
                onClick={logout}
                className=" uppercase bg-[#A9A9A9] text-nowrap lg:text-[.75rem] text-[.65rem] rounded-full text-white font-semibold lg:px-5 px-2 lg:py-2 py-[6px]"
              >
                sign Out
              </button>
            </div>
          </div>
          {sidebarGuide &&
            sidebarGuide.map((guide, i) => (
              <div
                key={i}
                className="py-2 lg:py-3 lg:px-8 px-6 cursor-pointer lg:text-[1rem] text-[.8rem] font-inter-600 flex items-center bg-white text-[#A9A9A9]"
              >
                <img src={guide.image} alt={guide.name} className="mr-4" />
                <div className="flex justify-between w-full items-center">
                  <span className="text-[#0D868C]">{guide.name}</span>
                  {guide.version && (
                    <span className="lg:text-[12px] text-[10px] font-inter-600">
                      {guide.version}
                    </span>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SidebarSetting;
