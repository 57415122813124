import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { IoIosArrowBack, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  loginUser,
  selectSuccess,
  selectUser,
} from "../../redux/features/auth/AuthSlice";
import useRedirectLoginUser from "../../CustomHook/useRedirectLoginUser";

const Login = () => {
  const path1 = "/items";
  const path2 = "/orders";

  useRedirectLoginUser({ path1, path2 });
  const [showPassword, setShowPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const isSuccess = useSelector(selectSuccess);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    const userData = {
      email: data.emailOrMobile,
      password: data.password,
    };

    await dispatch(loginUser(userData));
  };

  useEffect(() => {
    if (isSuccess && user) {
      if (user?.data.role === "admin") {
        navigate("/items");
      } else if (user?.data.role === "user") {
        navigate("/orders");
      } else if (user?.data.role === "suspended") {
        navigate("/");
      }
    }
  }, [isSuccess, user, navigate]);

  return (
    <div className="h-screen w-full bg-[#0D868C] bg-cover bg-center relative">
      <div className="relative flex items-center justify-center h-screen">
        <div className="flex mb-6 absolute top-6 left-4">
          <NavLink to={"/"}>
            <button className="text-[1rem] font-inter-600 text-white flex items-center gap-3">
              <IoIosArrowBack className="size-6" /> Sign in
            </button>
          </NavLink>
        </div>
        <div className="w-[650px] flex items-center justify-center">
          <div className="bg-white rounded-[10px] shadow-lg pb-[2rem] pt-[4rem] lg:px-[4rem] px-[2rem] w-full lg:m-2 m-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Email or Mobile Input */}
              <div>
                <input
                  type="text"
                  placeholder="Email ."
                  {...register("emailOrMobile", {
                    required: "Email or mobile is required.",
                    pattern: {
                      value: /^([^\s@]+@[^\s@]+\.[^\s@]+|[0-9]{10,15})$/,
                      message: "Enter a valid email or mobile number.",
                    },
                  })}
                  className={`w-full border-b-2 text-[#7D7D7D] border-[#0D868C] outline-none py-2 `}
                />
                {errors.emailOrMobile && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.emailOrMobile.message}
                  </p>
                )}
              </div>

              {/* Password Input */}
              <div className="mt-6 w-full border-b-2 border-[#0D868C] flex items-center justify-between gap-3">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required.",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters.",
                    },
                  })}
                  className={`w-full text-[#7D7D7D] outline-none py-2 ${
                    errors.password ? "border-red-500" : ""
                  }`}
                />
                {showPassword ? (
                  <IoMdEyeOff
                    onClick={togglePasswordVisibility}
                    className="size-6 text-[#cfcece] mr-4 cursor-pointer"
                  />
                ) : (
                  <IoMdEye
                    onClick={togglePasswordVisibility}
                    className="size-6 text-[#cfcece] mr-4 cursor-pointer"
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">
                  {errors.password.message}
                </p>
              )}

              {/* Forgot Password */}
              <div className="text-center lg:mt-7 mt-5">
                <NavLink
                  className="underline text-[#0D868C] font-inter-400"
                  to={"/forgotPassword"}
                >
                  Forgot Password?
                </NavLink>
              </div>

              {/* Submit Button */}
              <div
                className={`text-center ${
                  Object.keys(errors).length > 0
                    ? "mt-[2rem] lg:mt-[6rem]"
                    : "lg:mt-[8rem] mt-[4rem]"
                }`}
              >
                <button
                  type="submit"
                  className="bg-[#0D868C] text-white lg:w-[16.5rem] w-[12rem] py-3 rounded-[65px] mt-4"
                >
                  LOGIN
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
