import axios from "axios";
import { BACKEND_BASEURL } from "../../../db/baseUrl";
export const API_CHEESES = `${BACKEND_BASEURL}api/cheeses/`;

const createCheese = async (cheeseData) => {
  console.log(cheeseData);
  const response = await axios.post(API_CHEESES + "createCheese", cheeseData);
  return response.data;
};

const getCheeses = async () => {
  const response = await axios.get(API_CHEESES + "getCheeses");
  return response.data;
};

const updateCheese = async (cheeseData) => {
  const response = await axios.patch(API_CHEESES + "updateCheese", cheeseData);
  return response.data;
};
const deleteCheese = async (id) => {
  const response = await axios.delete(API_CHEESES + id);
  return response.data.message;
};
const CheeseServices = {
  createCheese,
  getCheeses,
  updateCheese,
  deleteCheese,
};
export default CheeseServices;
