import React, { useState } from "react";
import { IoIosArrowBack, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { NavLink, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import "./Reset.css";
import { useDispatch } from "react-redux";
import {
  resetPassword,
  // selectSuccess,
} from "../../redux/features/auth/AuthSlice";

const Reset = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { resetToken } = useParams();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevState) => !prevState);
  };

  // const isSuccess = useSelector(selectSuccess);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    const userData = {
      password: data.password,
    };
    console.log(resetToken);

    await dispatch(resetPassword({ userData, resetToken }));
  };

  return (
    <div className="reset-container h-[100vh] ">
      <div className="overlay relative ">
        <div className="flex mb-6 absolute top-6 left-4">
          <NavLink to={"/login"}>
            <button className="text-[1rem] font-inter-600 text-white flex items-center gap-3">
              <IoIosArrowBack className="size-6" /> Reset Password
            </button>
          </NavLink>
        </div>
        <div className="w-[650px] flex items-center justify-center ">
          <div className="bg-white rounded-[10px] shadow-lg pb-[2rem] pt-[4rem] md:px-[4rem] px-[2rem] w-[100%] md:m-2 m-4 mt-20">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Password Input */}
              <div className="mt-6 w-full border-b-2 border-[#0D868C] flex items-center justify-between gap-3">
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 8,
                      message: "Password must be at least 8 characters.",
                    },
                  })}
                  className={`w-full text-[#7D7D7D] outline-none py-2 ${
                    errors.password ? "border-red-500" : ""
                  }`}
                />
                {showPassword ? (
                  <IoMdEyeOff
                    onClick={togglePasswordVisibility}
                    className="size-6 text-[#cfcece] mr-4 cursor-pointer"
                  />
                ) : (
                  <IoMdEye
                    onClick={togglePasswordVisibility}
                    className="size-6 text-[#cfcece] mr-4 cursor-pointer"
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-red-500 mt-1">{errors.password.message}</p>
              )}

              {/* Confirm Password Input */}
              <div className="mt-6 w-full border-b-2 border-[#0D868C] flex items-center justify-between gap-3">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  {...register("confirmPassword", {
                    required: "Confirm Password is required",
                    validate: (value) =>
                      value === watch("password") || "Passwords do not match",
                  })}
                  className={`w-full text-[#7D7D7D] outline-none py-2 ${
                    errors.confirmPassword ? "border-red-500" : ""
                  }`}
                />
                {showConfirmPassword ? (
                  <IoMdEyeOff
                    onClick={toggleConfirmPasswordVisibility}
                    className="size-6 text-[#cfcece] mr-4 cursor-pointer"
                  />
                ) : (
                  <IoMdEye
                    onClick={toggleConfirmPasswordVisibility}
                    className="size-6 text-[#cfcece] mr-4 cursor-pointer"
                  />
                )}
              </div>
              {errors.confirmPassword && (
                <p className="text-red-500 mt-1">
                  {errors.confirmPassword.message}
                </p>
              )}

              {/* Submit Button */}
              <div
                className={`text-center ${
                  Object.keys(errors).length > 0
                    ? "mt-[4rem] md:mt-[4.5rem]"
                    : "md:mt-[7rem] mt-[6rem]"
                }`}
              >
                <button
                  type="submit"
                  className="bg-[#0D868C] text-white md:w-[16.5rem] w-[12rem] py-3 rounded-[65px] mt-4"
                >
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reset;
